@use 'lib/mixin';
@use 'lib/setting';
.blog {
  .bg-gray {
    background-color: #f5f5f5;
  }
}
.blogWrapper {
  padding: 45px 0 70px;
  max-width: 930px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.blogLeft {
  width: 700px;
}
.blogArchive {
  margin-bottom: 70px;
}
.blogArchiveList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.blogArchiveList__item {
  width: 340px;
  margin-bottom: 20px;
  background-color: #fff;
}
.blogArchiveList__item a {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  color: #333333;
  text-decoration: none;
}
.blogArchiveList__item a:hover {
  opacity: 0.8;
}
.blogArchiveList__thumb {
  width: 108px;
  height: 96px;
}
.blogArchiveList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.blogArchiveList__info {
  width: calc(100% - 128px);
}
.blogArchiveList__cat {
  display: inline-block;
  padding: 2px 14px;
  font-size: 13px;
  background-color: #f5f5f5;
  margin-bottom: 12px
}
.blogArchiveList__date {
  font-size: 13px;
  color: #9A9D9F;
  margin-bottom: 5px;
  line-height: 1;
}
.blogArchiveList__title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.blogArchiveList__excerpt {
  font-size: 13px;
}
.blogArchiveBack {
  margin-top: 30px;
  text-align: center;
}
.blogArchiveBack a {
  display: inline-block;
  width: 218px;
  padding: 10px;
  border: 1px solid #333333;
  color: #333333;
  font-size: 15px;
  text-decoration: none;
}
.blogArchiveBack a:hover {
  opacity: 0.8;
}
.blogRight {
  width: 174px;
}
.blogRightBlock:not(:last-child) {
  margin-bottom: 60px;
}
.blogRightBlock__h2 {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid #333;
  margin-bottom: 15px;
  line-height: 1.2;
}
.blogRightBlockList {
  font-size: 14px;
}
.blogRightBlockList li {
  margin-bottom: 5px;
}
.blogRightBlockList li a {
  color: #333;
  text-decoration: none;
}
.blogRightBlockList li a:hover {
  text-decoration: underline;
}
.archive-open:hover {
  cursor: pointer;
  text-decoration: underline;
}
.archive-hidden {
  display: none;
}
.blogSingleLeft {
  width: 758px;
  margin-left: -38px;
}
.blogSingleArticle {
  background-color: #fff;
  padding: 78px 78px 60px;
}
.blogSingleDate {
  margin-bottom: 20px;
  font-size: 13px;
  color: #9A9D9F;
}
.blogSingleTitle {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
}
.blogSinglePost > *:last-child {
  margin-bottom: 0;
}
.blogSingleText {
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 2;
}
.blogSingleText p {
}
.blogSingleImg {
  margin-bottom: 50px;
}
.blogSingleCat {
  text-align: right;
  margin-top: 6px;
  font-size: 13px;
}
@media screen and (max-width: 750px){
  .blogWrapper {
    display: block;
    padding: 35px 22px 56px;
  }
  .blogSingleLeft {
    width: 100%;
    margin-left: 0;
  }
  .blogSingleArticle {
    padding: 32px 20px 40px;
  }
  .blogSingleTitle {
    margin-bottom: 25px;
    font-size: 15px;
  }
  .blogSingleText {
    margin-bottom: 30px;
  }
  .blogSingleImg {
    margin-bottom: 30px;
  }
  .nextPostBg_blog {
    width: 100%;
  }

  .blogRight {
    width: 100%;
    margin-top: 90px;
  }

  .nextPostBg_blog {
    font-size: 13px;
  }
  .nextPostBg_blog .center a {
    width: 168px;
    padding: 8px;
  }

  .blogLeft {
    width: 100%;
  }
  .blogArchiveList {
    display: block;
  }
  .blogArchiveList__item {
    width: 100%;
  }
  .blogArchiveList__date {
    font-size: 12px;
  }
  .blogArchiveList__title {
    font-size: 13px;
  }
  .blogArchiveList__excerpt {
    font-size: 12px;
  }
}