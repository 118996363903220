@use 'lib/mixin';
@use 'lib/setting';
/* ----------------------------------------------------------------------
 animation
---------------------------------------------------------------------- */
.ani-fade-up {
  opacity: 0;
  transform: translate(0, 60px);
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
}
.ani-start-scroll {
  &.is-active {
    .ani-fade-up,
    &.ani-fade-up {
      transform: translate(0,0);
      opacity: 1;
    }
  }
}
.ani-delay-1 {
  transition-delay: .2s;
  animation-delay: .2s;
}
.ani-delay-2 {
  transition-delay: .4s;
  animation-delay: .4s;
}
.ani-delay-3 {
  transition-delay: .6s;
  animation-delay: .6s;
}
.ani-delay-4 {
  transition-delay: .8s;
  animation-delay: .8s;
}
.ani-delay-5 {
  transition-delay: 1s;
  animation-delay: 1s;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translate(0, -60px);
  }
  80% {
    opacity: 1;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes loop {
  0% {
    transform: translateX(200%);
  }
  to {
    transform: translateX(-100%);
  }
}
 
@keyframes loop2 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-200%);
  }
}
 
@keyframes loop3 {
  0% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-300%);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1); /* 拡大率 */
  }
}