@use 'lib/mixin';
@use 'lib/setting';
.staffHero {
  padding: 140px 120px 0;
  @include mixin.break2 {
    padding: 28px 28px 0px;
  }
  &__bg {
    position: relative;
  }
  &__title {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    .en {
      font-size: 36px;
      font-family: setting.$serif;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 30px;
      }
    }
    .ja {
      font-size: 16px;
      font-weight: 500;
      padding-top: 20px;
      margin-top: 15px;
      position: relative;
      @include mixin.break2 {
        font-size: 14px;
      }
      &:before {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 0px;
        left: calc(50% - 10px);
      }
    }
  }
  &__text {
    &-01 {
      margin-top: 36px;
      text-align: center;
      font-size: 17px;
      line-height: 2;
      font-family: setting.$serif;
      @include mixin.break2 {
        margin-top: 30px;
        font-size: 16px;
        text-align: left;
      }
    }
    &-02 {
      margin-top: 100px;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      font-family: setting.$serif;
      @include mixin.break2 {
        margin-top: 50px;
        font-size: 20px;
      }
    }
    &-03 {
      margin-top: 50px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      @include mixin.break2 {
        margin-top: 30px;
        font-size: 18px;
      }
    }
    &-04 {
      margin: 30px auto 0;
      font-size: 16px;
      line-height: 2;
      font-weight: 500;
      max-width: 720px;
      @include mixin.break2 {
        font-size: 15px;
        margin-top: 30px;
      }
    }
  }
}
.staffContent {
  padding-top: 150px;
  overflow: hidden;
  @include mixin.break2 {
    margin-top: 70px;
    padding: 0 28px;
  }
  &__block {
    margin: 120px auto 0;
    max-width: 1120px;
    display: flex;
    flex-direction: row-reverse;
    @include mixin.break2 {
      margin-top: 75px;
      position: relative;
      padding-top: 40px;
      flex-direction: column-reverse;
    }
    &:first-child {
      margin-top: 0;
    }
    @include mixin.break2_up {
      &:nth-child(2n) {
        flex-direction: row;
        .staffContent__thumb {
          padding-right: 0;
          padding-left: 30px;
          justify-content: flex-start;
        }
        .staffContent__info {
          padding-right: 30px;
          padding-left: 60px;
        }
        .staffContent__line {
          left: auto;
          padding-left: 0px;
          padding-right: 356px;
          right: -175px;
          &:before {
            left: auto;
            right: 0px;
          }
        }
      }
    }
  }
  &__info {
    width: 50%;
    padding: 60px 60px 0 30px;
    position: relative;
    @include mixin.break2 {
      position: static;
      width: 100%;
      padding: 30px 0 0;
    }
  }
  &__line {
    position: absolute;
    left: -160px;
    top: -13px;
    padding-left: 285px;
    font-size: 15px;
    font-family: setting.$serif;
    letter-spacing: 0.1em;
    @include mixin.break2 {
      top: 0px;
      left: 0px;
      display: flex;
      font-size: 14px;
      align-items: center;
      padding: 0;
    }
    &:before {
      content: "";
      width: 268px;
      height: 1px;
      background-color: setting.$color;
      position: absolute;
      top: 50%;
      left: 0px;
      @include mixin.break2 {
        position: static;
        flex: 1;
        margin-right: 16px;
      }
    }
  }
  &__thumb {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    position: relative;
    padding-right: 30px;
    @include mixin.break2 {
      padding: 0;
      width: 100%;
      display: block;
    }
    img {
      max-width: initial;
      @include mixin.break2 {
        max-width: 100%;
      }
    }
  }
  &__message {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.02em;
    @include mixin.break2 {
      font-size: 16px;
    }
  }
  &__text {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8;
    @include mixin.break2 {
      font-size: 15px;
    }
  }
  &__btn {
    margin-top: 30px;
    @include mixin.break2 {
      text-align: center;
    }
  }
  &__name {
    position: absolute;
    background-color: #fff;
    padding: 40px 15px 20px;
    display: flex;
    flex-direction: row-reverse;
    font-family: setting.$serif;
    font-weight: 500;
    @include mixin.break2 {
      padding: 12px 0;
      width: 76px;
      justify-content: center;
    }
    .job {
      writing-mode: vertical-rl;
      font-size: 18px;
      letter-spacing: 0.03em;
      @include mixin.break2 {
        font-size: 15px;
      }
    }
    .name {
      writing-mode: vertical-rl;
      font-size: 30px;
      letter-spacing: 0.03em;
      @include mixin.break2 {
        font-size: 25px;
        line-height: 1.2;
      }
    }
    .-block01 & {
      top: 0px;
      right: 62px;
      @include mixin.break2 {
        right: auto;
        left: 15px;
      }
    }
    .-block02 & {
      top: 0px;
      left: 80px;
      @include mixin.break2 {
        left: auto;
        right: 15px;
      }
    }
    .-block03 & {
      top: 0px;
      right: 462px;
      @include mixin.break2 {
        left: 15px;
        right: auto;
      }
    }
  }
}
.staffOffice {
  margin-top: 136px;
  height: 886px;
  background: url(../img/staff/bg_wave.png) no-repeat center bottom/cover;
  padding-top: 90px;
  position: relative;
  @include mixin.break2 {
    background: url(../img/staff/bg_wave_sp.svg) no-repeat center bottom/cover;
    margin-top: 70px;
    height: auto;
    padding: 50px 28px 60px;
  }
  &:before {
    content: "";
    width: 230px;
    height: 230px;
    background: url(../img/staff/accent_01.svg) no-repeat center/contain;
    position: absolute;
    top: -70px;
    right: calc(50% - 530px);
    @include mixin.break2 {
      width: 184px;
      height: 184px;
      top: -48px;
      right: 14px;
    }
  }
  &:after {
    content: "";
    width: 226px;
    height: 226px;
    background: url(../img/staff/accent_02.svg) no-repeat center/contain;
    position: absolute;
    bottom: -44px;
    left: calc(50% - 574px);
    @include mixin.break2 {
      width: 180px;
      height: 180px;
      bottom: -40px;
      left: 14px;
      z-index: 1;
    }
  }
  &__title {
    font-size: 24px;
    text-align: center;
    font-family: setting.$serif;
    @include mixin.break2 {
      font-size: 20px;
    }
  }
  &List {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    @include mixin.break2 {
      margin-top: 50px;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 2;
    }
    &__item {
      margin: 0 34px;
      width: 280px;
      @include mixin.break2 {
        margin: 60px 0 0;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    &__thumb {
      position: relative;
    }
    &__info {
      background-color: #fff;
      width: 64px;
      height: 152px;
      padding-top: 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      font-family: setting.$serif;
      position: absolute;
      top: 0px;
      left: 16px;
      @include mixin.break2 {
        height: 140px;
        padding-top: 16px;
      }
    }
    &__job {
      writing-mode: vertical-rl;
      font-size: 16px;
      font-weight: 500;
      @include mixin.break2 {
        font-size: 15px;
      }
    }
    &__name {
      writing-mode: vertical-rl;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.2;
      @include mixin.break2 {
        font-size: 25px;
      }
    }
    &__text {
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;
    }
  }
}