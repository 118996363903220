@use 'lib/mixin';
@use 'lib/setting';
.header {
  position: relative;
  @include mixin.break2 {
    height: 54px;
  }
  &__h1 {
    @include mixin.break2_up {
      display: none;
    }
    font-size: 10px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 24px 0 30px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    @include mixin.break2 {
      height: 54px;
      padding: 0 12px 0 15px;
    }
  }
  .logo {
    img {
      @include mixin.break2 {
        width: 126px;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
  }
  .menu {
    margin-left: 58px;
    position: relative;
    height: 16px;
    width: 46px;
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
    @include mixin.break2 {
      width: 32px;
      height: 12px;
    }
    span {
      display: block;
      position: absolute;
      background-color: #808080;
      height: 1px;
      width: 100%;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        bottom: 0px;
      }
    }
  }
}
.gnavi {
  display: flex;
  @include mixin.break2 {
    display: none;
  }
  li {
   margin-left: 24px;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.01em;
    &:hover {
      text-decoration: underline;
    }
  }
}

.mega {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  display: none;
  &__inner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    @include mixin.break2 {
      display: none;
    }
  }
  &__left {
    width: 43.5%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .inner {
      position: relative;
    }
  }
  &__logo {
    margin-bottom: 50px;
  }
  &Menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: 470px;
    margin: 0 auto;
    &__list {
      li {
        margin-top: 25px;
        &:first-child {
          margin-top: 0px;
        }
      }
      a {
        text-decoration: none;
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
      .en {
        font-size: 13px;
        display: block;
      }
      .ja {
        font-size: 17px;
        display: block;
      }
    }
  }
  &__privacy {
    font-size: 14px;
    margin-top: 30px;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__instagram {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  &__close {
    position: absolute;
    top: 34px;
    right: 34px;
    cursor: pointer;
    @include mixin.break2 {
      width: 23px;
      top: 20px;
      right: 16px;
    }
  }
  &sp {
    @include mixin.break2_up {
      display: none;
    }
    height: 100%;
    width: 100%;
    overflow: auto;
    &__logo {
      padding: 18px 0 0 30px;
    }
    &__inner {
      max-width: 300px;
      margin: 50px auto 0;
    }
    &Row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &__item {
        width: 135px;
      }
      + & {
        margin-top: 40px;
      }
      &__title {
        margin-bottom: 25px;
        font-size: 14px;
      }
    }
    &__navi {
      margin-bottom: 25px;
      a {
        text-decoration: none;
        color: inherit;
      }
      .en {
        font-size: 13px;
        display: block;
      }
      .ja {
        font-size: 15px;
        display: block;
      }
    }
    &__thumb {
      
    }
    &__text {
      font-size: 14px;
      margin-top: 3px;
      margin-bottom: 20px;
      white-space: nowrap;
    }
    &Tel {
      text-align: center;
      margin-top: 30px;
      &__text {
        font-size: 16px;
        font-weight: 500;
      }
      &__tel {
        display: inline-block;
        border-top: 1px solid setting.$color;
        font-size: 19px;
        a {
          font-size: 27px;
          text-decoration: none;
          color: inherit;
        }
      }
    }
    &__instagram {
      margin-top: 50px;
    }
    &__privacy {
      font-size: 14px;
      margin-top: 25px;
      padding-bottom: 50px;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

.subMenu {
  position: fixed;
  top: 90px;
  left: 0px;
  width: 100%;
  z-index: 101;
  display: none;
  padding-top: 38px;
  margin-top: -38px;
  &.-home {
    top: 110px;
  }
  &__wrapper {
    padding: 34px 0;
    background-color: #f5f5f5;
  }
  &__inner {
    max-width: 810px;
    margin: 0 auto;
  }
  &List {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__item {
      width: 170px;
      margin: 25px 15px 0;
    }
    &__title {
      text-align: center;
      font-size: 14px;
      margin-top: 8px;
    }
  }
}