@use 'lib/mixin';
@use 'lib/setting';
.pageHeader {
  margin-top: 90px;
  @include mixin.break2 {
    margin-top: 0px;
  }
  .breadcrumbs {
    margin-bottom: 95px;
    @include mixin.break2 {
      margin-bottom: 19px;
    }
  }
}