@use 'lib/mixin';
@use 'lib/setting';
.voiceArchive {
  padding-top: 30px;
  padding-bottom: 36px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding: 40px 15px;
  }
  &__inner {
    max-width: 1020px;
    margin: 0 auto;
  }
  &List {
    margin: 0 -23px;
    display: flex;
    flex-wrap: wrap;
    @include mixin.break2 {
      margin: 0;
      justify-content: space-between;
    }
    &__item {
      width: calc(33.33% - 46px);
      margin: 0 23px 40px;
      @include mixin.break2 {
        width: 48.5%;
        margin: 0 0 25px;
      }
    }
    &__thumb {
      border-radius: 20px 0 20px 0;
      overflow: hidden;
      width: 100%;
      height: 208px;
      @include mixin.break2 {
        height: mixin.svw(127);
        border-radius: 12px 0 12px 0;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      font-size: 13px;
      letter-spacing: 0.1em;
      margin-top: 12px;
      @include mixin.break2 {
        margin-top: 10px;
      }
    }
    &__title {
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-top: 12px;
      @include mixin.break2 {
        margin-top: 10px;
      }
    }
  }
}
.voiceSingleHeader {
  margin-top: 90px;
  background-color: #f5f5f5;
  text-align: center;
  .breadcrumbs {
    margin-bottom: 40px;
    @include mixin.break2 {
      margin-bottom: 9px;
    }
  }
  @include mixin.break2 {
    padding: 0px 28px 0;
    margin-top: 0;
  }
  &__subtitle {
    display: inline-block;
    padding-bottom: 18px;
    width: 214px;
    background: url(../img/voice/balloon.svg) no-repeat center bottom/contain;
    font-size: 24px;
    letter-spacing: 0.03em;
    font-family: setting.$serif;
  }
  &__name {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
    @include mixin.break2 {
      font-size: 14px;
      color: #666666;
      margin-top: 20px;
    }
  }
  &__title {
    font-size: 23px;
    font-weight: bold;
    margin-top: 10px;
    @include mixin.break2 {
      font-size: 18px;
      margin-top: 16px;
    }
  }
  &__thumb {
    display: inline-block;
    overflow: hidden;
    width: 46.8vw;
    border-radius: 40px 0 40px 0;
    margin-top: 32px;
    @include mixin.break2 {
      width: auto;
      border-radius: 30px 0 30px 0;
    }
  }
}
.voiceSingleContent {
  padding-top: 96px;
  padding-bottom: 120px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding: 50px 28px 70px;
  }
  &__title {
    text-align: center;
    font-size: 26px;
    font-family: setting.$serif;
    letter-spacing: 0.03em;
    margin-bottom: 60px;
    @include mixin.break2 {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  &Block {
    width: 100%;
    max-width: 1028px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    @include mixin.break2 {
      display: block;
    }
    + .voiceSingleContentBlock {
      margin-top: 90px;
      @include mixin.break2 {
        margin-top: 48px;
      }
    }
    @include mixin.break2_up {
      &:nth-child(2n) {
        flex-direction: row-reverse;
        .voiceSingleContentBlock__left {
          padding-right: 0;
          padding-left: 48px;
        }
      }
    }
    &__left {
      flex: 1;
      padding-right: 48px;
      @include mixin.break2 {
        padding: 0;
      }
    }
    &__right {
      width: 510px;
      @include mixin.break2 {
        width: 100%;
        margin-top: 40px;
      }
    }
    &__title {
      font-size: 23px;
      font-weight: 600;
      font-family: setting.$serif;
      @include mixin.break2 {
        font-size: 18px;
      }
    }
    &__q {
      font-size: 19px;
      font-weight: 600;
      margin-top: 20px;
      font-family: setting.$serif;
      @include mixin.break2 {
        font-size: 16px;
      }
    }
    &__a {
      font-size: 14px;
      font-weight: 500;
      line-height: 2;
      margin-top: 22px;
      @include mixin.break2 {
        font-size: 14px;
      }
    }
  }
  &Gallery {
    margin-top: 140px;
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      margin-top: 15px;
      justify-content: space-between;
    }
    &__item {
      width: 350px;
      margin: 0 20px;
      @include mixin.break2 {
        width: 48.5%;
        margin: 0;
      }
    }
  }
  &Works {
    margin-top: 150px;
    @include mixin.break2 {
      margin-top: 60px;
    }
    a {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 740px;
      margin: 0 auto;
      text-decoration: none;
      color: inherit;
    }
    &__left {
      width: 50%;
      background-color: #8e8a8f;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      color: #fff;
      @include mixin.break2 {
        width: 45%;
      }
      &:after {
        content: "";
        margin-top: 24px;
        width: 11px;
        height: 10px;
        background: url(../img/arrow_white.svg) no-repeat center/contain;
        display: block;
        @include mixin.break2 {
          margin-top: 16px;
        }
      }
    }
    &__large {
      font-size: 36px;
      font-family: setting.$serif;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 18px;
      }
    }
    &__medium {
      font-size: 16px;
      font-family: setting.$serif;
      letter-spacing: 0.03em;
      margin-top: 24px;
      @include mixin.break2 {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    &__right {
      width: 50%;
      height: 264px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include mixin.break2 {
        width: 55%;
        height: 162px;
      }
    }
  }
}
.voiceRecent {
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding: 0 15px 0;
  }
  &__inner {
    max-width: 1040px;
    margin: 0 auto;
  }
  .voiceArchiveList {
    margin-top: 30px;
  }
}