/* --------------------------------------------------------------------------------
 * web fonts
-------------------------------------------------------------------------------- */
// Noto系については、全部読み込むと重いので、使う太さだけ抜粋する
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap&subset=japanese');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@100;300;400;500;700;900&display=swap&subset=japanese');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap&subset=japanese');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Zen+Kaku+Gothic+New:wght@400;500;700&family=Zen+Old+Mincho:wght@400;500;600;700&display=swap');

// Add Original Font

// Setting Font Awesome
@mixin fontAwesome( $code, $weight:$black ) {
  font-family: "Font Awesome 5 Pro";
  content: $code;
  font-weight: $weight;
}
@mixin fa-mail-bold {
  @include fontAwesome('\f0e0');
}