
@use 'lib/mixin';
@use 'lib/setting';
.link-arrow {
  font-size: 15px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: inherit;
  &:after {
    content: "";
    display: inline-block;
    width: 11px;
    height: 10px;
    background: url(../img/arrow_black.svg) no-repeat center/contain;
    margin-left: 16px;
  }
  @media (hover : hover) {
    transition: opacity .3s;
    &:hover {
      opacity: 0.8;
    }
  }
}