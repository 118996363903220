
@use 'lib/mixin';
@use 'lib/setting';
.btn-primary {
  display: inline-block;
  width: 270px;
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 1.2;
  padding: 15px 0px 15px 20px;
  color: #fff;
  background-color: #666666;
  text-decoration: none;
  text-align: center;
  border-radius: 50vh;
  &:after {
    content: "→";
    // background: url(../img/arrow_white.svg) no-repeat center/contain;
    // width: 11px;
    // height: 12px;
    display: inline-block;
    margin-left: 20px;
  }
  @media (hover: hover) {
    &:after {
      transition: transform .3s ease-in-out;
    }
    &:hover {
      &:after {
        transform: translateX(5px);
      }
    }
  }
}
.btn-arrow {
  display: inline-block;
  background-color: #fff;
  width: 100%;
  text-align: center;
  border: 1px solid setting.$color;
  font-size: 15px;
  position: relative;
  text-decoration: none;
  color: inherit;
  padding: 10px 0;
  border-radius: 2px;
  &:after {
    content: "→";
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (hover: hover) {
    transition: background-color .3s;
    &:hover {
      background-color: setting.$color;
      color: #fff;
    }
  }
  &.-medium {
    width: 310px;
    padding: 16px 0;
  }
  &.-noarrow {
    &:after {
      display: none;
    }
  }
}
.btn-arrow-2 {
  display: inline-block;
  width: 300px;
  padding: 15px 1em 15px 0;
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 1.2;
  position: relative;
  border: 1px solid #666666;
  border-radius: 4px;
  text-align: center;
  color: inherit;
  text-decoration: none;
  &:after {
    content: "→";
    // background: url(../img/arrow_black.svg) no-repeat center/contain;
    display: inline-block;
    position: absolute;
    right: 30px;
    top: calc(50% - 9px);
  }
  @media (hover: hover) {
    transition: opacity .3s;
    &:hover {
      opacity: 0.8;
    }
  }
}