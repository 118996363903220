@use "sass:math";
@use 'setting';
// break point
@mixin break-custom( $min:1366px, $max:1920px ) {
  @media only screen and (min-width: $min + 1) and (max-width: $max) {
    @content;
  }
}
@mixin break1 {
  @media only screen and (max-width: setting.$breakPoint1) {
	  @content;
  }
}
@mixin break1_up {
  @media only screen and (min-width: setting.$breakPoint1 + 1) {
	  @content;
  }
}
@mixin break2 {
  @media only screen and (max-width: setting.$breakPoint2) {
	  @content;
  }
}
@mixin break2_up {
  @media only screen and (min-width: setting.$breakPoint2 + 1) {
	  @content;
  }
}

// other mixins
@mixin ieHack {
  @media all and (-ms-high-contrast:none) {
    @content;
  }
}
@mixin serif {
  font-family: $serif;
}

@mixin line-height( $font-size:16, $line-height:32 ) {
  line-height: $line-height/$font-size;
}

@function vw( $size: 16 ) {
  $vw: $size * math.div(100, setting.$pcDesignSize) + vw;
  @return $vw;
}
@function svw( $size: 16 ) {
  $svw: $size * math.div(100, setting.$spDesignSize) + vw;
  @return $svw;
}

@mixin hoverLink() {
  transition: opacity .3s;
  &:hover {
    opacity: 0.8;
  }
}

@mixin hover {
	@media (hover: none) {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&:active {
			@content;
		}
	}

	@media (-ms-high-contrast: none), (-ms-high-contrast: active), (-moz-touch-enabled: 0), (hover: hover) {
		&:hover {
			@content;
		}
	}
}

/**
* 1366pxから1920pxにかけて、徐々に拡大していく計算
* width: calculateY(1000, 0.1) + px;
* のように使う。第二引数は省略可。デフォルトは0.1
*/
@function calculateY($size, $per: 0.1) {
  $baseValue: calc($size + ($per * $size * (100vw - 1366px)));
  @return $baseValue;
}