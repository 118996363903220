
@use 'lib/mixin';
@use 'lib/setting';
.title-primary {
  text-align: center;
  .en {
    display: block;
    font-family: setting.$serif;
    font-size: 36px;
    letter-spacing: 0.1em;
    @include mixin.break2 {
      font-size: 30px;
    }
    .cross {
      font-size: 25px;
    }
  }
  .ja {
    font-size: 16px;
    font-weight: 500;
    display: block;
    position: relative;
    padding-top: 20px;
    margin-top: 6px;
    @include mixin.break2 {
      padding-top: 12px;
    }
    &:before {
      content: "";
      width: 20px;
      height: 1px;
      position: absolute;
      top: 0px;
      left: calc(50% - 10px);
      background-color: setting.$color;
    }
  }
}