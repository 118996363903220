@use 'lib/mixin';
@use 'lib/setting';
.linkBanners {
  margin-top: 130px;
  @include mixin.break2 {
    margin-top: 62px;
  }
  &.-gray {
    padding-top: 98px;
    padding-bottom: 98px;
    background-color: #f5f5f5;
    @include mixin.break2 {
      padding-top: 72px;
      padding-bottom: 72px;
    }
  }
  &List {
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__item {
      width: 160px;
      margin: 8px 5px;
    }
    &__thumb {
      position: relative;
      span {
        position: absolute;
        top: 12px;
        left: 10px;
        display: block;
        writing-mode: vertical-rl;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.1em;
        font-family: setting.$serif;
        &.-white {
          color: #fff;
        }
      }
    }
    &__title {
      font-size: 14px;
      letter-spacing: 0.1em;
      text-align: center;
      margin-top: 6px;
    }
  }
}