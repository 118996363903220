@use 'lib/mixin';
@use 'lib/setting';
.conceptHero {
  @include mixin.break2 {
    padding: 0 28px;
  }
  &__bg {
    height: 56.2vw;
    width: 100%;
    max-height: 80vh;
    position: relative;
    @include mixin.break2 {
      height: auto;
      max-height: initial;
      margin: 0 -28px;
      width: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: setting.$serif;
    padding-top: 90px;
    @include mixin.break2 {
      padding-top: 0;
    }
    .en {
      display: block;
      font-size: 40px;
      letter-spacing: 0.1em;
    }
    .ja {
      display: block;
      font-size: 18px;
      letter-spacing: 0.1em;
    }
  }
  &__subtitle {
    font-size: 22px;
    line-height: 2;
    font-family: setting.$serif;
    margin-top: 60px;
    text-align: center;
    @include mixin.break2 {
      font-size: 20px;
      margin-top: 40px;
      text-align: left;
    }
  }
  &__text {
    max-width: 660px;
    margin: 60px auto 0;
    font-family: setting.$serif;
    line-height: 2;
    @include mixin.break2 {
      margin-top: 36px;
    }
  }
}
.conceptPolicy {
  background-color: #f5f5f5;
  padding: 100px 0 130px;
  margin-top: 110px;
  @include mixin.break2 {
    margin-top: 40px;
    padding: 30px 28px 40px;
  }
  &__inner {
    max-width: 1080px;
    margin: 0 auto;
  }
  &Top {
    display: flex;
    justify-content: space-between;
    @include mixin.break2 {
      display: block;
    }
    &__left {
      width: 530px;
      position: relative;
      @include mixin.break2 {
        width: auto;
        margin: 0 -28px;
      }
    }
    &__right {
      width: 490px;
      padding-top: 10px;
      @include mixin.break2 {
        width: auto;
        padding-top: 24px;
      }
    }
    &__title {
      position: relative;
      padding-left: 22px;
      font-family: setting.$serif;
      .en {
        font-size: 16px;
        letter-spacing: 0.1em;
        display: block;
        padding-left: 15px;
        @include mixin.break2 {
          font: 14px;
        }
      }
      .ja {
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0.03em;
        display: block;
        @include mixin.break2 {
          white-space: nowrap;
          font-size: 22px;
        }
      }
      .num {
        font-size: 30px;
        position: absolute;
        bottom: -3px;
        left: 0px;
      }
    }
    &__subtitle {
      font-size: 20px;
      margin-top: 25px;
      font-family: setting.$serif;
    }
    &__text {
      font-size: 16px;
      line-height: 1.8;
      margin-top: 20px;
      font-family: setting.$serif;
    }
  }
  &Point {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      padding-top: 5px;
      display: block;
    }
    li {
      border-right: 1px solid setting.$color;
      width: 270px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 18px;
      font-family: setting.$serif;
      @include mixin.break2_up {
        &:first-child {
          border-left: 1px solid setting.$color;
        }
      }
      @include mixin.break2 {
        border-left: 1px solid setting.$color;
        margin: 45px auto 0;
      }
    }
  }
  &__btn {
    text-align: center;
    margin-top: 40px;
  }
}
.conceptPolicy1 {
  padding-top: 100px;
  @include mixin.break2 {
    padding-top: 50px;
  }
  &Gallery {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    @include mixin.break2 {
      margin-top: 50px;
      display: block;
    }
    &__left {
      width: 500px;
      background-color: #f5f5f5;
      img {
        mix-blend-mode: multiply;
      }
      @include mixin.break2 {
        width: auto;
      }
    }
    &__right {
      width: 486px;
      display: flex;
      justify-content: space-between;
      @include mixin.break2 {
        width: auto;
        margin: 27px -5px 0;
        .item {
          margin: 0 5px;
        }
      }
    }
  }
}
.conceptPolicy2 {
  padding-top: 160px;
  @include mixin.break2 {
    padding-top: 80px;
  }
  &Gallery {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @include mixin.break2 {
      margin: 36px -19px 0;
      flex-wrap: wrap;
    }
    &__item {
      width: 250px;
      @include mixin.break2 {
        width: calc(50% - 10px);
        margin: 5px;
      }
    }
  }
  &__copy {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 54px 10px 24px 0;
    writing-mode: vertical-rl;
    font-size: 18px;
    font-family: setting.$serif;
    border-right: 1px solid setting.$color;
    color: #fff;
  }
}
.conceptPolicy3 {
  padding-top: 180px;
  @include mixin.break2 {
    padding-top: 80px;
  }
  &Gallery {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    @include mixin.break2 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }
    &__item {
      width: 254px;
      @include mixin.break2 {
        margin-top: 25px;
      }
    }
    &__title {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      background-color: #1a1a1a;
      width: 148px;
      padding: 1px 0 1px 8px;
    }
    &__thumb {
      margin-top: 8px;
    }
    &__text {
      font-size: 13px;
      margin-top: 8px;
    }
  }
}
.conceptPolicy4 {
  padding-top: 120px;
  @include mixin.break2 {
    padding-top: 80px;
  }
  &Gallery {
    display: flex;
    justify-content: center;
    margin-top: 72px;
    @include mixin.break2 {
      margin-top: 45px;
      flex-direction: column;
      align-items: center;
    }
    &__item {
      width: 228px;
      height: 153px;
      margin: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      @include mixin.break2 {
        margin: 12px 0 0;
        display: block;
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        font-size: 18px;
        font-family: setting.$serif;
        text-align: center;
        @include mixin.break2 {
          padding: 20px 0;
        }
      }
    }
  }
  &__copy {
    position: absolute;
    bottom: -130px;
    left: 55px;
    padding: 54px 10px 24px 0;
    writing-mode: vertical-rl;
    font-size: 18px;
    font-family: setting.$serif;
    border-right: 1px solid setting.$color;
    @include mixin.break2 {
      top: -17px;
      right: mixin.svw(90);
      left: auto;
      bottom: auto;
      color: #fff;
      border-color: #fff;
      padding-top: 28px;
      padding-bottom: 0;
    }
  }
}
.conceptCost {
  padding-top: 90px;
  @include mixin.break2 {
    padding: 50px 28px 0;
  }
  &__inner {
    max-width: 780px;
    margin: 0 auto;
  }
  &__subtitle {
    margin-top: 48px;
    font-size: 23px;
    font-family: setting.$serif;
    text-align: center;
    @include mixin.break2 {
      font-size: 20px;
      margin-top: 36px;
    }
  }
  &__thumb {
    text-align: center;
    margin-top: 35px;
    @include mixin.break2 {
      margin-top: 20px;
    }
  }
  &__read {
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 1.8;
    @include mixin.break2 {
      margin-top: 30px;
      text-align: left;
      font-size: 18px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.8;
    margin-top: 50px;
    @include mixin.break2 {
      margin-top: 24px;
    }
    + .conceptCost__text {
      margin-top: 1em;
    }
  }
}