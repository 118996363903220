@use 'lib/mixin';
@use 'lib/setting';
/* ----------------------------------------------------------------------
 contact
---------------------------------------------------------------------- */
.contactH1Area {
  text-align: center;
  color: #fff;
  padding: 60px 0 60px;
  background-color: #333;
  position: relative;
  margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
  .contactH1Area {
    padding: 45px 0;
    margin-bottom: 35px;
  }
}

.contactH1Area__title {
  font-size: 26px;
}

@media only screen and (max-width: 750px) {
  .contactH1Area__title {
    font-size: 20px;
  }
}

.contactH1Area__text {
  font-size: 17px;
  margin-top: 8px;
}

@media only screen and (max-width: 750px) {
  .contactH1Area__text {
    font-size: 13px;
  }
}

.contactH1Area:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51, 51, 51, 0);
  border-top-color: #333;
  border-width: 27px;
  margin-left: -27px;
}

.contactContainer {
  max-width: 692px;
  margin: 0 auto;
}

.contactHead {
  margin-bottom: 140px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .contactHead {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 40px;
  }
}

.contactHead__title {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .contactHead__title {
    font-size: 17px;
  }
}

.contactHead__text {
  text-align: left;
  font-size: 17px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 750px) {
  .contactHead__text {
    font-size: 14px;
  }
}

.contactHead__img {
  display: inline-block;
  position: relative;
}

.contactHead__free {
  position: absolute;
  top: 12px;
  right: 17px;
  background-color: #FF3333;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  font-size: 23px;
  padding-top: 27px;
  text-align: center;
  line-height: 1;
  font-weight: bold;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .contactHead__free {
    width: 48px;
    height: 48px;
    font-size: 14px;
    padding-top: 17px;
  }
}

.contactFormBlock {
  border: 10px solid #505050;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 34px;
  margin-bottom: 130px;
}

.contactFormBlock__h2 {
  background-color: #505050;
  color: #fff;
  text-align: center;
  width: calc(100% + 36px);
  margin-left: -18px;
  font-size: 17px;
  font-weight: bold;
  padding: 6px 0 14px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 750px) {
  .contactFormBlock__h2 {
    font-size: 17px;
  }
}

.contactFormTable {
  margin-bottom: 15px;
}

.contactFormTable:last-child {
  margin-bottom: 30px;
}

.contactFormTable dt {
  font-size: 15px;
  margin-top: 3px;
  margin-bottom: 5px;
  font-weight: 500;
}

.contactFormTable dt .hissu {
  background-color: #FF3333;
  color: #fff;
  display: inline-block;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 10px;
}

.contactFormTable input[type="text"],
.contactFormTable input[type="email"],
.contactFormTable input[type="tel"] {
  background-color: #FAFAFA;
  height: 34px;
  border-radius: 0px;
  width: 100%;
}

.contactFormTable textarea {
  background-color: #FAFAFA;
  border-radius: 0px;
  width: 100%;
}

.contactFormTable input.zip {
  width: 240px;
  margin-right: 0.3em;
}

.contactFormBtn {
  margin-top: 33px;
  margin-bottom: 45px;
  text-align: center;
}

.contactFormBtn input {
  -webkit-appearance: none;
  display: block;
  width: 324px;
  height: 52px;
  border-style: none;
  background: #333333;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
  margin: 0 auto 5px;
}

.contactFormBtn input:hover {
  opacity: 0.8;
  cursor: pointer;
}

.contactFormBtn input.backBtn {
  background-color: #eee;
  color: #000;
}

@media only screen and (max-width: 750px) {
  .contactFormBtn input {
    width: 100%;
  }
}

.contactFomLast {
  text-align: center;
}

.contactFomLast__text {
  font-size: 16px;
  font-weight: bold;
}

.contactFomLast__tel {
  font-size: 29px;
  font-weight: bold;
}

.contactFomLast img {
  margin-right: 0.3em;
}