@use 'lib/mixin';
@use 'lib/setting';
.archiveHeader {
  margin-top: 90px;
  background-color: #f5f5f5;
  .breadcrumbs {
    margin-bottom: 50px;
    @include mixin.break2 {
      margin-bottom: 9px;
    }
  }
  @include mixin.break2 {
    margin-top: 0;
  }
}