@use 'lib/mixin';
@use 'lib/setting';
.sideButtons {
  flex-direction: column;
  position: fixed;
  display: none;
  bottom: 0px;
  right: 0px;
  z-index: 10;
  font-family: setting.$serif;
  @include mixin.break2 {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .button-01 {
    a {
      height: 190px;
      width: 64px;
      text-decoration: none;
      color: #fff;
      background-color: #404040;
      font-size: 14px;
      writing-mode: vertical-rl;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 14px 0 0 14px;
      @include mixin.break2 {
        width: 190px;
        height: 50px;
        writing-mode: horizontal-tb;
        border-radius: 14px 14px 0 0;
      }
    }
  }
  .button-02 {
    margin-top: 8px;
    @include mixin.break2 {
      margin-top: 0;
    }
    a {
      height: 54px;
      width: 64px;
      text-decoration: none;
      border: 1px solid #404040;
      color: inherit;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 14px 0 0 14px;
      background-color: #fff;
      line-height: 1.28;
      @include mixin.break2 {
        width: 64px;
        height: 50px;
        writing-mode: horizontal-tb;
        border-radius: 14px 14px 0 0;
      }
    }
  }
  .gotop {
    margin-top: 48px;
    text-align: right;
    @include mixin.break2 {
      margin-top: 0;
    }
  }
}
.footer {
  background-color: #f7f7f7;
  padding: 25px 20px 20px;
  margin-top: 90px;
  position: relative;
  @include mixin.break2 {
    margin-top: 50px;
    padding: 35px 35px 70px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 1216px;
    margin: 0 auto;
    @include mixin.break2 {
      flex-direction: column;
      position: relative;
      margin-bottom: 20px;
    }
  }
  &__left {
    display: flex;
    @include mixin.break2 {
      display: block;
    }
  }
  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    @include mixin.break2 {
      display: block;
      img {
        display: none;
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    span {
      display: block;
      font-size: 27px;
      letter-spacing: 0.05em;
      margin-top: 20px;
      writing-mode: vertical-rl;
      @include mixin.break2 {
        writing-mode: horizontal-tb;
        font-size: 18px;
        margin-top: 0;
      }
    }
  }
  &Map {
    margin-right: 20px;
    @include mixin.break2 {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0px;
    }
    &__map {
      width: 323px;
      @include mixin.break2 {
        width: 100%;
        margin: 30px auto 0;
      }
      iframe {
        width: 100%;
        height: 197px;
        @include mixin.break2 {
          height: 202px;
        }
      }
    }
    &__bottom {
      display: flex;
      margin-top: 32px;
      @include mixin.break2 {
        margin-top: 0;
        position: relative;
      }
    }
    &__instagram {
      @include mixin.break2 {
        position: absolute;
        bottom: 0px;
        right: 0px;
        text-align: center;
      }
    }
    &__address {
      margin-left: 30px;
      font-size: 14px;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        margin: 12px 0 0;
      }
    }
  }
  &Navi {
    display: flex;
    @include mixin.break2 {
      margin-top: 30px;
      justify-content: space-between;
    }
    .spwrap {
      @include mixin.break2_up {
        display: contents;
      }
      width: 56%;
      &:nth-child(2) {
        width: 40%;
      }
    }
    &__clm {
      margin-left: 40px;
      @include mixin.break2 {
        margin: 0;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
      .item {
        margin-bottom: 10px;
        padding-left: 16px;
        position: relative;
        @include mixin.break2 {
          font-size: 13px;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 10px;
          background: url(../img/arrow_dot.svg) no-repeat center/contain;
          margin-right: 8px;
          position: absolute;
          top: 8px;
          left: 0px;
          @include mixin.break2 {
            top: 4px;
          }
        }
        &.-noarrow:before {
          display: none;
        }
        +.sub {
          margin-top: -4px;
        }
      }
      .sub {
        font-size: 14px;
        margin-bottom: 6px;
        margin-left: 1em;
        @include mixin.break2 {
          font-size: 13px;
        }
        &:before {
          content: "− ";
        }
        + .item {
          margin-top: 10px;
        }
      }
    }
  }
  .copyright {
    font-size: 12px;
    text-align: center;
    margin-top: 40px;
    @include mixin.break2 {
      margin-top: 0;
    }
  }
}