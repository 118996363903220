@use 'lib/mixin';
@use 'lib/setting';
.companyHero {
  &__bg {
    height: 48.9vw;
    width: 100%;
    max-height: 90vh;
    position: relative;
    @include mixin.break2 {
      height: auto;
      max-height: initial;
      width: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: setting.$serif;
    padding-top: 90px;
    @include mixin.break2 {
      padding-top: 0;
    }
    .en {
      display: block;
      font-size: 40px;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 32px;
      }
    }
    .ja {
      display: block;
      font-size: 18px;
      letter-spacing: 0.1em;
    }
  }
  &__read {
    font-size: 22px;
    margin-top: 22px;
    text-align: center;
    font-family: setting.$serif;
    .small {
      font-size: 16px;
    }
  }
}
.companyProfile {
  margin-top: 50px;
  padding: 90px 0 120px;
  background-color: #f5f5f5;
  overflow: hidden;
  @include mixin.break2 {
    padding: 40px 0 100px;
  }
  &__table {
    width: 100%;
    max-width: 682px;
    margin: 50px auto 0;
    line-height: 2;
    @include mixin.break2 {
      margin: 30px 15px 0;
      width: auto;
    }
    th {
      background-color: #fff;
      vertical-align: middle;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      font-family: setting.$serif;
      border-top: 1px solid #808080;
      border-bottom: 1px solid #808080;
      @include mixin.break2 {
        padding: 15px 12px;
        text-align: left;
        font-size: 14px;
      }
    }
    td {
      padding: 18px 18px 18px 48px;
      font-size: 15px;
      font-weight: 500;
      border-top: 1px solid #808080;
      border-bottom: 1px solid #808080;
      @include mixin.break2 {
        padding: 15px 12px;
        font-size: 14px;
      }
    }
  }
  &Gallery {
    display: flex;
    margin-top: 100px;
    @include mixin.break2 {
      margin-top: 80px;
    }
    &List {
      display: flex;
      &:first-child {
        animation: loop 180s linear infinite;
      }
      &:nth-child(2) {
        animation: loop2 180s -120s linear infinite;
      }
      &:last-child {
        animation: loop3 180s -60s linear infinite;
      }
    }
    &__item {
      img {
        max-width: initial;
        width: auto;
        height: 500px;
        @include mixin.break2 {
          height: 275px;
        }
      }
    }
  }
}
.companyAccess {
  background-color: #f5f5f5;
  &__title {
    font-size: 28px;
    letter-spacing: 0.1em;
    font-family: setting.$serif;
    padding: 0 20px 12px;
    @include mixin.break2 {
      text-align: center;
    }
  }
  iframe {
    width: 100%;
    height: 434px;
    @include mixin.break2 {
      height: 300px;
    }
  }
}
.companyIntro {
  padding: 120px 0 95px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding: 40px 28px 50px;
  }
  &__inner {
    max-width: 1038px;
    margin: 0 auto;
  }
  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mixin.break2 {
      display: block;
    }
    &__left {
      width: 616px;
      @include mixin.break2 {
        width: auto;
        margin: 0 -28px;
      }
    }
    &__right {
      flex: 1;
      padding-left: 54px;
      @include mixin.break2 {
        padding: 28px 0 0;
      }
    }
    &__title {
      font-size: 22px;
      font-weight: 500;
      @include mixin.break2 {
        font-size: 18px;
      }
    }
    &__text {
      font-size: 15px;
      line-height: 1.8;
      letter-spacing: 0.02em;
      margin-top: 28px;
    }
  }
  &List {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @include mixin.break2 {
      margin-top: 38px;
      display: block;
    }
    &__item {
      width: 246px;
      @include mixin.break2 {
        width: 100%;
        margin: 20px auto 0;
      }
    }
  }
}