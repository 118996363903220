@charset "UTF-8";
/* --------------------------------------------------------------------------------
 * web fonts
-------------------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Zen+Kaku+Gothic+New:wght@400;500;700&family=Zen+Old+Mincho:wght@400;500;600;700&display=swap");
/**
* 1366pxから1920pxにかけて、徐々に拡大していく計算
* width: calculateY(1000, 0.1) + px;
* のように使う。第二引数は省略可。デフォルトは0.1
*/
/* ----------------------------------------------------------------------
 reset (exculding 'sup')
---------------------------------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

input, textarea {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  text-align: left;
}

img {
  vertical-align: middle;
}

/* ----------------------------------------------------------------------
 basic setting
---------------------------------------------------------------------- */
body {
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: #212529;
  line-height: 1.5;
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

body * {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

input, button, textarea, select {
  color: #212529;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
}

textarea {
  vertical-align: top;
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  backface-visibility: hidden;
}

/* ----------------------------------------------------------------------
 common class
---------------------------------------------------------------------- */
@media only screen and (min-width: 751px) {
  .pc-non {
    display: none !important;
  }
}
@media only screen and (max-width: 750px) {
  .sp-non {
    display: none !important;
  }
}
.mb0 {
  margin-bottom: 0;
}

.tCenter {
  text-align: center;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.telLink {
  color: inherit;
  text-decoration: none;
}
@media only screen and (min-width: 751px) {
  .telLink {
    pointer-events: none;
  }
}

.wrapper {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .wrapper {
    width: auto;
  }
}

.hoverImg {
  transition: 0.3s;
}
@media only screen and (min-width: 751px) {
  .hoverImg:hover {
    opacity: 0.7;
  }
}

.hoverTxt {
  text-decoration: none;
  color: inherit;
}
.hoverTxt:hover {
  text-decoration: underline;
}

.hoverScale {
  display: block;
  width: 100%;
  height: 100%;
}
.hoverScale figure {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  vertical-align: bottom;
}
@media (hover: hover) {
  .hoverScale img {
    transition: 0.5s;
  }
  .hoverScale img:hover {
    transform: scale(1.08);
  }
}

.btn-primary {
  display: inline-block;
  width: 270px;
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 1.2;
  padding: 15px 0px 15px 20px;
  color: #fff;
  background-color: #666666;
  text-decoration: none;
  text-align: center;
  border-radius: 50vh;
}
.btn-primary:after {
  content: "→";
  display: inline-block;
  margin-left: 20px;
}
@media (hover: hover) {
  .btn-primary:after {
    transition: transform 0.3s ease-in-out;
  }
  .btn-primary:hover:after {
    transform: translateX(5px);
  }
}

.btn-arrow {
  display: inline-block;
  background-color: #fff;
  width: 100%;
  text-align: center;
  border: 1px solid #212529;
  font-size: 15px;
  position: relative;
  text-decoration: none;
  color: inherit;
  padding: 10px 0;
  border-radius: 2px;
}
.btn-arrow:after {
  content: "→";
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}
@media (hover: hover) {
  .btn-arrow {
    transition: background-color 0.3s;
  }
  .btn-arrow:hover {
    background-color: #212529;
    color: #fff;
  }
}
.btn-arrow.-medium {
  width: 310px;
  padding: 16px 0;
}
.btn-arrow.-noarrow:after {
  display: none;
}

.btn-arrow-2 {
  display: inline-block;
  width: 300px;
  padding: 15px 1em 15px 0;
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 1.2;
  position: relative;
  border: 1px solid #666666;
  border-radius: 4px;
  text-align: center;
  color: inherit;
  text-decoration: none;
}
.btn-arrow-2:after {
  content: "→";
  display: inline-block;
  position: absolute;
  right: 30px;
  top: calc(50% - 9px);
}
@media (hover: hover) {
  .btn-arrow-2 {
    transition: opacity 0.3s;
  }
  .btn-arrow-2:hover {
    opacity: 0.8;
  }
}

.color-red {
  color: #c1272d;
}

.form-text {
  width: 300px;
  height: 28px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  padding: 0 0.25em;
}
.form-text::placeholder {
  color: #999999;
}
.form-text.-zip {
  width: 160px;
}
@media only screen and (max-width: 750px) {
  .form-text {
    width: 100%;
  }
}

.form-select {
  width: 250px;
  height: 28px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  padding: 0 0.25em;
}
.form-select + .form-select {
  margin-left: 2px;
}
.form-select.-fit {
  width: auto;
}

.form-textarea {
  width: 100%;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  padding: 0.25em;
}

.form-btn-send {
  -webkit-appearance: none;
  border-style: none;
  width: 300px;
  height: 67px;
  background-color: #666666;
  border-radius: 3px;
  color: #fff;
  position: relative;
  font-size: 15px;
  font-weight: bold;
}
.form-btn-send:after {
  content: "";
  background: url(../img/angle_right_white.svg) no-repeat center/contain;
  width: 5px;
  height: 10px;
  position: absolute;
  right: 30px;
  top: calc(50% - 5px);
}
@media (hover: hover) {
  .form-btn-send:hover {
    cursor: pointer;
  }
}

.link-arrow {
  font-size: 15px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: inherit;
}
.link-arrow:after {
  content: "";
  display: inline-block;
  width: 11px;
  height: 10px;
  background: url(../img/arrow_black.svg) no-repeat center/contain;
  margin-left: 16px;
}
@media (hover: hover) {
  .link-arrow {
    transition: opacity 0.3s;
  }
  .link-arrow:hover {
    opacity: 0.8;
  }
}

.title-primary {
  text-align: center;
}
.title-primary .en {
  display: block;
  font-family: "Zen Old Mincho", serif;
  font-size: 36px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .title-primary .en {
    font-size: 30px;
  }
}
.title-primary .en .cross {
  font-size: 25px;
}
.title-primary .ja {
  font-size: 16px;
  font-weight: 500;
  display: block;
  position: relative;
  padding-top: 20px;
  margin-top: 6px;
}
@media only screen and (max-width: 750px) {
  .title-primary .ja {
    padding-top: 12px;
  }
}
.title-primary .ja:before {
  content: "";
  width: 20px;
  height: 1px;
  position: absolute;
  top: 0px;
  left: calc(50% - 10px);
  background-color: #212529;
}

.header {
  position: relative;
}
@media only screen and (max-width: 750px) {
  .header {
    height: 54px;
  }
}
.header__h1 {
  font-size: 10px;
}
@media only screen and (min-width: 751px) {
  .header__h1 {
    display: none;
  }
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 24px 0 30px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}
@media only screen and (max-width: 750px) {
  .header__inner {
    height: 54px;
    padding: 0 12px 0 15px;
  }
}
@media only screen and (max-width: 750px) {
  .header .logo img {
    width: 126px;
  }
}
.header__left {
  display: flex;
  align-items: center;
}
.header .menu {
  margin-left: 58px;
  position: relative;
  height: 16px;
  width: 46px;
}
@media (hover: hover) {
  .header .menu:hover {
    cursor: pointer;
  }
}
@media only screen and (max-width: 750px) {
  .header .menu {
    width: 32px;
    height: 12px;
  }
}
.header .menu span {
  display: block;
  position: absolute;
  background-color: #808080;
  height: 1px;
  width: 100%;
}
.header .menu span:nth-child(1) {
  top: 0px;
}
.header .menu span:nth-child(2) {
  bottom: 0px;
}

.gnavi {
  display: flex;
}
@media only screen and (max-width: 750px) {
  .gnavi {
    display: none;
  }
}
.gnavi li {
  margin-left: 24px;
}
.gnavi a {
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.gnavi a:hover {
  text-decoration: underline;
}

.mega {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  display: none;
}
.mega__inner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
}
@media only screen and (max-width: 750px) {
  .mega__inner {
    display: none;
  }
}
.mega__left {
  width: 43.5%;
  height: 100%;
}
.mega__left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mega__right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.mega__right .inner {
  position: relative;
}
.mega__logo {
  margin-bottom: 50px;
}
.megaMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  width: 470px;
  margin: 0 auto;
}
.megaMenu__list li {
  margin-top: 25px;
}
.megaMenu__list li:first-child {
  margin-top: 0px;
}
.megaMenu__list a {
  text-decoration: none;
  color: inherit;
}
.megaMenu__list a:hover {
  text-decoration: underline;
}
.megaMenu__list .en {
  font-size: 13px;
  display: block;
}
.megaMenu__list .ja {
  font-size: 17px;
  display: block;
}
.mega__privacy {
  font-size: 14px;
  margin-top: 30px;
}
.mega__privacy a {
  text-decoration: none;
  color: inherit;
}
.mega__privacy a:hover {
  text-decoration: underline;
}
.mega__instagram {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.mega__close {
  position: absolute;
  top: 34px;
  right: 34px;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .mega__close {
    width: 23px;
    top: 20px;
    right: 16px;
  }
}
.megasp {
  height: 100%;
  width: 100%;
  overflow: auto;
}
@media only screen and (min-width: 751px) {
  .megasp {
    display: none;
  }
}
.megasp__logo {
  padding: 18px 0 0 30px;
}
.megasp__inner {
  max-width: 300px;
  margin: 50px auto 0;
}
.megaspRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.megaspRow__item {
  width: 135px;
}
+ .megaspRow {
  margin-top: 40px;
}
.megaspRow__title {
  margin-bottom: 25px;
  font-size: 14px;
}
.megasp__navi {
  margin-bottom: 25px;
}
.megasp__navi a {
  text-decoration: none;
  color: inherit;
}
.megasp__navi .en {
  font-size: 13px;
  display: block;
}
.megasp__navi .ja {
  font-size: 15px;
  display: block;
}
.megasp__text {
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 20px;
  white-space: nowrap;
}
.megaspTel {
  text-align: center;
  margin-top: 30px;
}
.megaspTel__text {
  font-size: 16px;
  font-weight: 500;
}
.megaspTel__tel {
  display: inline-block;
  border-top: 1px solid #212529;
  font-size: 19px;
}
.megaspTel__tel a {
  font-size: 27px;
  text-decoration: none;
  color: inherit;
}
.megasp__instagram {
  margin-top: 50px;
}
.megasp__privacy {
  font-size: 14px;
  margin-top: 25px;
  padding-bottom: 50px;
}
.megasp__privacy a {
  text-decoration: none;
  color: inherit;
}

.subMenu {
  position: fixed;
  top: 90px;
  left: 0px;
  width: 100%;
  z-index: 101;
  display: none;
  padding-top: 38px;
  margin-top: -38px;
}
.subMenu.-home {
  top: 110px;
}
.subMenu__wrapper {
  padding: 34px 0;
  background-color: #f5f5f5;
}
.subMenu__inner {
  max-width: 810px;
  margin: 0 auto;
}
.subMenuList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.subMenuList__item {
  width: 170px;
  margin: 25px 15px 0;
}
.subMenuList__title {
  text-align: center;
  font-size: 14px;
  margin-top: 8px;
}

.breadcrumbs {
  padding: 12px 30px;
  font-size: 14px;
  text-align: left;
}
@media only screen and (max-width: 750px) {
  .breadcrumbs {
    padding: 8px 15px;
  }
}
.breadcrumbs a {
  color: inherit;
}

.linkBanners {
  margin-top: 130px;
}
@media only screen and (max-width: 750px) {
  .linkBanners {
    margin-top: 62px;
  }
}
.linkBanners.-gray {
  padding-top: 98px;
  padding-bottom: 98px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .linkBanners.-gray {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
.linkBannersList {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .linkBannersList {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.linkBannersList__item {
  width: 160px;
  margin: 8px 5px;
}
.linkBannersList__thumb {
  position: relative;
}
.linkBannersList__thumb span {
  position: absolute;
  top: 12px;
  left: 10px;
  display: block;
  writing-mode: vertical-rl;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.1em;
  font-family: "Zen Old Mincho", serif;
}
.linkBannersList__thumb span.-white {
  color: #fff;
}
.linkBannersList__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  text-align: center;
  margin-top: 6px;
}

/* ----------------------------------------------------------------------
 Main
---------------------------------------------------------------------- */
.sideButtons {
  flex-direction: column;
  position: fixed;
  display: none;
  bottom: 0px;
  right: 0px;
  z-index: 10;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .sideButtons {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.sideButtons .button-01 a {
  height: 190px;
  width: 64px;
  text-decoration: none;
  color: #fff;
  background-color: #404040;
  font-size: 14px;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px 0 0 14px;
}
@media only screen and (max-width: 750px) {
  .sideButtons .button-01 a {
    width: 190px;
    height: 50px;
    writing-mode: horizontal-tb;
    border-radius: 14px 14px 0 0;
  }
}
.sideButtons .button-02 {
  margin-top: 8px;
}
@media only screen and (max-width: 750px) {
  .sideButtons .button-02 {
    margin-top: 0;
  }
}
.sideButtons .button-02 a {
  height: 54px;
  width: 64px;
  text-decoration: none;
  border: 1px solid #404040;
  color: inherit;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 14px 0 0 14px;
  background-color: #fff;
  line-height: 1.28;
}
@media only screen and (max-width: 750px) {
  .sideButtons .button-02 a {
    width: 64px;
    height: 50px;
    writing-mode: horizontal-tb;
    border-radius: 14px 14px 0 0;
  }
}
.sideButtons .gotop {
  margin-top: 48px;
  text-align: right;
}
@media only screen and (max-width: 750px) {
  .sideButtons .gotop {
    margin-top: 0;
  }
}

.footer {
  background-color: #f7f7f7;
  padding: 25px 20px 20px;
  margin-top: 90px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .footer {
    margin-top: 50px;
    padding: 35px 35px 70px;
  }
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  max-width: 1216px;
  margin: 0 auto;
}
@media only screen and (max-width: 750px) {
  .footer__inner {
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
  }
}
.footer__left {
  display: flex;
}
@media only screen and (max-width: 750px) {
  .footer__left {
    display: block;
  }
}
.footer__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
}
@media only screen and (max-width: 750px) {
  .footer__logo {
    display: block;
  }
  .footer__logo img {
    display: none;
  }
}
.footer__logo a {
  color: inherit;
  text-decoration: none;
}
.footer__logo span {
  display: block;
  font-size: 27px;
  letter-spacing: 0.05em;
  margin-top: 20px;
  writing-mode: vertical-rl;
}
@media only screen and (max-width: 750px) {
  .footer__logo span {
    writing-mode: horizontal-tb;
    font-size: 18px;
    margin-top: 0;
  }
}
.footerMap {
  margin-right: 20px;
}
@media only screen and (max-width: 750px) {
  .footerMap {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 0px;
  }
}
.footerMap__map {
  width: 323px;
}
@media only screen and (max-width: 750px) {
  .footerMap__map {
    width: 100%;
    margin: 30px auto 0;
  }
}
.footerMap__map iframe {
  width: 100%;
  height: 197px;
}
@media only screen and (max-width: 750px) {
  .footerMap__map iframe {
    height: 202px;
  }
}
.footerMap__bottom {
  display: flex;
  margin-top: 32px;
}
@media only screen and (max-width: 750px) {
  .footerMap__bottom {
    margin-top: 0;
    position: relative;
  }
}
@media only screen and (max-width: 750px) {
  .footerMap__instagram {
    position: absolute;
    bottom: 0px;
    right: 0px;
    text-align: center;
  }
}
.footerMap__address {
  margin-left: 30px;
  font-size: 14px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .footerMap__address {
    margin: 12px 0 0;
  }
}
.footerNavi {
  display: flex;
}
@media only screen and (max-width: 750px) {
  .footerNavi {
    margin-top: 30px;
    justify-content: space-between;
  }
}
.footerNavi .spwrap {
  width: 56%;
}
@media only screen and (min-width: 751px) {
  .footerNavi .spwrap {
    display: contents;
  }
}
.footerNavi .spwrap:nth-child(2) {
  width: 40%;
}
.footerNavi__clm {
  margin-left: 40px;
}
@media only screen and (max-width: 750px) {
  .footerNavi__clm {
    margin: 0;
  }
}
.footerNavi__clm a {
  text-decoration: none;
  color: inherit;
}
.footerNavi__clm a:hover {
  text-decoration: underline;
}
.footerNavi__clm .item {
  margin-bottom: 10px;
  padding-left: 16px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .footerNavi__clm .item {
    font-size: 13px;
  }
}
.footerNavi__clm .item:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 10px;
  background: url(../img/arrow_dot.svg) no-repeat center/contain;
  margin-right: 8px;
  position: absolute;
  top: 8px;
  left: 0px;
}
@media only screen and (max-width: 750px) {
  .footerNavi__clm .item:before {
    top: 4px;
  }
}
.footerNavi__clm .item.-noarrow:before {
  display: none;
}
.footerNavi__clm .item + .sub {
  margin-top: -4px;
}
.footerNavi__clm .sub {
  font-size: 14px;
  margin-bottom: 6px;
  margin-left: 1em;
}
@media only screen and (max-width: 750px) {
  .footerNavi__clm .sub {
    font-size: 13px;
  }
}
.footerNavi__clm .sub:before {
  content: "− ";
}
.footerNavi__clm .sub + .item {
  margin-top: 10px;
}
.footer .copyright {
  font-size: 12px;
  text-align: center;
  margin-top: 40px;
}
@media only screen and (max-width: 750px) {
  .footer .copyright {
    margin-top: 0;
  }
}

.archiveHeader {
  margin-top: 90px;
  background-color: #f5f5f5;
}
.archiveHeader .breadcrumbs {
  margin-bottom: 50px;
}
@media only screen and (max-width: 750px) {
  .archiveHeader .breadcrumbs {
    margin-bottom: 9px;
  }
}
@media only screen and (max-width: 750px) {
  .archiveHeader {
    margin-top: 0;
  }
}

@media only screen and (min-width: 751px) {
  .home .header__inner {
    display: none;
  }
}

.homeHero {
  display: flex;
}
.homeHero__h1 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 10px;
  left: 0px;
  font-size: 13px;
}
@media only screen and (max-width: 750px) {
  .homeHero__h1 {
    display: none;
  }
}
.homeHero__left {
  width: 16.67%;
  padding: 50px 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .homeHero__left {
    display: none;
  }
}
.homeHero__left .menu {
  margin-top: 37.4vh;
  position: relative;
  height: 16px;
  width: 46px;
}
@media (hover: hover) {
  .homeHero__left .menu:hover {
    cursor: pointer;
  }
}
.homeHero__left .menu span {
  display: block;
  position: absolute;
  background-color: #808080;
  height: 1px;
  width: 100%;
}
.homeHero__left .menu span:nth-child(1) {
  top: 0px;
}
.homeHero__left .menu span:nth-child(2) {
  bottom: 0px;
}
.homeHero__sns {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
}
.homeHero__right {
  width: 83.33%;
  height: 94vh;
  position: relative;
  opacity: 0;
}
@media only screen and (max-width: 750px) {
  .homeHero__right {
    height: calc(100vh - 54px);
    width: 100%;
    padding-left: 15px;
  }
}
.homeHero__right.is-active {
  opacity: 1;
  transition: opacity 1s;
}
.homeHero__bg {
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 50px;
  overflow: hidden;
}
.homeHero__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeHero__bg .item {
  height: 94vh;
}
.homeHero__bg .add-animation img {
  animation: zoomOut 10s linear 0s normal both;
}
.homeHero__navi {
  position: absolute;
  display: flex;
  top: 50px;
  right: 90px;
}
@media only screen and (max-width: 750px) {
  .homeHero__navi {
    display: none;
  }
}
.homeHero__navi li {
  margin-left: 24px;
}
.homeHero__navi li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media (hover: hover) {
  .homeHero__navi li a:hover {
    text-decoration: underline;
  }
}
.homeHero__catch {
  color: #fff;
  position: absolute;
  bottom: 60px;
  right: 210px;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .homeHero__catch {
    bottom: 36px;
    right: 25px;
  }
}
.homeHero__catch .large {
  font-size: 32px;
  letter-spacing: 0.1em;
  display: block;
}
.homeHero__catch .small {
  font-size: 15px;
  display: block;
  line-height: 2;
  letter-spacing: 0.1em;
}

.homeConcept {
  margin-top: 96px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .homeConcept {
    margin-top: 60px;
    padding: 0 28px;
  }
}
.homeConcept__title {
  position: absolute;
  left: 46px;
  top: 90px;
  font-size: 28px;
  letter-spacing: 0.01em;
  transform: rotate(90deg);
  transform-origin: center left;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .homeConcept__title {
    left: 60px;
    top: 0px;
  }
}
.homeConcept__inner {
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  padding-left: 88px;
  padding-top: 120px;
}
@media only screen and (max-width: 750px) {
  .homeConcept__inner {
    padding: 0;
  }
}
.homeConcept__subtitle {
  font-size: 23px;
  font-family: "Zen Old Mincho", serif;
  line-height: 2;
  letter-spacing: 0.1em;
  padding-left: 112px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .homeConcept__subtitle {
    margin-top: 55px;
    padding-left: 60px;
    font-size: 20px;
  }
}
.homeConcept__subtitle:before {
  content: "";
  width: 88px;
  height: 1px;
  background-color: #212529;
  position: absolute;
  top: 24px;
  left: 0px;
}
@media only screen and (max-width: 750px) {
  .homeConcept__subtitle:before {
    width: 40px;
    top: 19px;
  }
}
.homeConcept__text {
  margin-top: 350px;
  margin-left: 506px;
  max-width: 450px;
  font-size: 15px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
  line-height: 2;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .homeConcept__text {
    margin-left: 0;
    margin-top: 40px;
    line-height: 1.5;
    letter-spacing: 0.03em;
  }
}
.homeConcept__text:before {
  content: "";
  width: 226px;
  height: 226px;
  background: url(../img/home/deco_concept_02.svg) no-repeat center/contain;
  position: absolute;
  z-index: -1;
  bottom: -100px;
  right: -18px;
}
@media only screen and (max-width: 750px) {
  .homeConcept__text:before {
    bottom: -27px;
    right: -13px;
    width: 180px;
    height: 180px;
  }
}
.homeConcept__img-01 {
  position: absolute;
  top: 0px;
  right: 0px;
}
@media only screen and (max-width: 750px) {
  .homeConcept__img-01 {
    position: relative;
    width: 72%;
    margin-left: auto;
  }
  .homeConcept__img-01:before {
    content: "";
    width: 146px;
    height: 146px;
    background: url(../img/home/deco_concept_01.svg) no-repeat center/contain;
    position: absolute;
    z-index: -1;
    bottom: -34px;
    left: -100px;
  }
}
.homeConcept__img-02 {
  position: absolute;
  top: 340px;
  right: 583px;
}
@media only screen and (max-width: 750px) {
  .homeConcept__img-02 {
    position: static;
    margin-top: 60px;
  }
}
.homeConcept__img-02:before {
  content: "";
  width: 230px;
  height: 230px;
  background: url(../img/home/deco_concept_01.svg) no-repeat center/contain;
  position: absolute;
  z-index: -1;
  top: -58px;
  right: -154px;
}
@media only screen and (max-width: 750px) {
  .homeConcept__img-02:before {
    display: none;
  }
}
.homeConcept__btn {
  margin-top: 160px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homeConcept__btn {
    margin-top: 50px;
  }
}

.homePolicy {
  margin-top: 200px;
}
@media only screen and (max-width: 750px) {
  .homePolicy {
    padding: 40px 0 60px;
    background-color: #f5f5f5;
    margin-top: 50px;
  }
}
.homePolicy__content {
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 64px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .homePolicy__content {
    margin-top: 40px;
    padding: 0;
  }
}
.homePolicy__text {
  text-align: center;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .homePolicy__text {
    padding: 0 35px;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.03em;
  }
}
.homePolicyList {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .homePolicyList {
    display: block;
  }
}
.homePolicyList__item {
  width: 240px;
  margin: 0 15px;
}
@media only screen and (max-width: 750px) {
  .homePolicyList__item {
    margin: 50px auto 0;
  }
}
.homePolicyList__num {
  font-size: 30px;
  font-family: "Zen Old Mincho", serif;
  font-weight: 500;
  text-align: center;
}
.homePolicyList__title {
  font-size: 20px;
  font-family: "Zen Old Mincho", serif;
  font-weight: 500;
  text-align: center;
}
.homePolicyList__thumb {
  position: relative;
  margin-top: 15px;
  text-align: center;
}
.homePolicyList__thumb figure {
  border-radius: 50%;
  overflow: hidden;
}
.homePolicyList__thumb .accent {
  position: absolute;
}
.homePolicyList__thumb .accent.accent-01 {
  top: 56px;
  left: -36px;
}
.homePolicyList__thumb .accent.accent-02 {
  bottom: -28px;
  left: -38px;
}
.homePolicyList__thumb .accent.accent-03 {
  top: 12px;
  left: -8px;
}
.homePolicyList__thumb .accent.accent-04 {
  top: 163px;
  left: -30px;
}
.homePolicy__btn {
  margin-top: 72px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homePolicy__btn {
    margin-top: 64px;
  }
}

.homeNews {
  padding: 120px 0 0px;
}
@media only screen and (max-width: 750px) {
  .homeNews {
    padding: 50px 30px 0px;
  }
}
.homeNewsList {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
@media only screen and (max-width: 750px) {
  .homeNewsList {
    display: block;
    margin-top: 0;
  }
}
.homeNewsList__item {
  width: 318px;
  margin: 0 22px;
}
@media only screen and (max-width: 750px) {
  .homeNewsList__item {
    width: 100%;
    max-width: 318px;
    margin: 36px auto 0;
  }
}
.homeNewsList__thumb {
  position: relative;
  width: 100%;
  height: 212px;
}
.homeNewsList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeNewsList__info {
  margin-top: 10px;
  position: relative;
}
.homeNewsList__tags {
  display: flex;
}
.homeNewsList__tags li {
  font-size: 13px;
  padding: 0 10px;
  color: #fff;
  letter-spacing: 0.12em;
  background-color: #212529;
}
.homeNewsList__tags li.-gray {
  background-color: #808080;
}
.homeNewsList__tags li + li {
  margin-left: 10px;
}
.homeNewsList .new {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: #fff;
  display: block;
  width: 60px;
  padding: 2px 0 3px 0.06em;
  text-align: center;
  background-color: #e83a37;
  border-radius: 50vh;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}
@media only screen and (max-width: 750px) {
  .homeNewsList .new {
    right: 0px;
  }
}
.homeNewsList__date {
  margin-top: 12px;
  font-size: 13px;
  letter-spacing: 0.1em;
}
.homeNewsList__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 15px;
}
.homeNews__btn {
  margin-top: 60px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homeNews__btn {
    margin-top: 40px;
  }
}

.homeWorks {
  margin-top: 85px;
  padding: 90px 0 65px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .homeWorks {
    padding: 50px 15px 60px;
    margin-top: 60px;
  }
}
.homeWorksList {
  margin-top: 45px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .homeWorksList {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
    padding-top: 10px;
  }
}
.homeWorksList__thumb {
  width: 100%;
  height: 156px;
}
@media only screen and (max-width: 750px) {
  .homeWorksList__thumb {
    height: 33.8666666667vw;
  }
}
.homeWorksList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeWorksList__item {
  width: 240px;
  margin: 0 14px;
}
@media only screen and (max-width: 750px) {
  .homeWorksList__item {
    width: 48.5%;
    margin: 30px 0 0;
  }
}
.homeWorksList__info {
  position: relative;
  margin-top: 10px;
}
@media only screen and (max-width: 750px) {
  .homeWorksList__info {
    margin-top: 0;
    padding-top: 30px;
  }
}
.homeWorksList .new {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: #fff;
  display: block;
  width: 60px;
  padding: 2px 0 3px 0.06em;
  text-align: center;
  background-color: #e83a37;
  border-radius: 50vh;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}
@media only screen and (max-width: 750px) {
  .homeWorksList .new {
    top: 5px;
    right: 0px;
  }
}
.homeWorksList__area {
  padding-right: 80px;
  font-size: 13px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .homeWorksList__area {
    padding-right: 0;
  }
}
.homeWorksList__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 10px;
}
.homeWorks__btn {
  margin-top: 60px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homeWorks__btn {
    margin-top: 50px;
  }
}

.homeHouse {
  padding: 100px 0 0px;
  overflow: hidden;
}
@media only screen and (max-width: 750px) {
  .homeHouse {
    padding: 50px 0 0px;
  }
}
.homeHouseGallery {
  display: flex;
  margin-top: 24px;
}
@media only screen and (max-width: 750px) {
  .homeHouseGallery {
    margin-top: 40px;
  }
}
.homeHouseGalleryList {
  display: flex;
}
.homeHouseGalleryList:first-child {
  animation: loop 180s linear infinite;
}
.homeHouseGalleryList:nth-child(2) {
  animation: loop2 180s -120s linear infinite;
}
.homeHouseGalleryList:last-child {
  animation: loop3 180s -60s linear infinite;
}
.homeHouseGallery__item img {
  max-width: initial;
  width: auto;
  height: 400px;
}
@media only screen and (max-width: 750px) {
  .homeHouseGallery__item img {
    height: 245px;
  }
}
.homeHouse__info {
  max-width: 1128px;
  margin: 10px auto 0;
  position: relative;
  padding-left: 450px;
}
@media only screen and (max-width: 750px) {
  .homeHouse__info {
    padding: 0 30px;
    margin-top: -26px;
  }
}
.homeHouse__catch {
  font-family: "Zen Old Mincho", serif;
  font-size: 63px;
  letter-spacing: 0.05em;
  line-height: 1.14;
  position: absolute;
  left: 50px;
  top: -48px;
  color: #666666;
}
@media only screen and (max-width: 750px) {
  .homeHouse__catch {
    position: static;
    font-size: 44px;
  }
}
.homeHouse__text {
  font-size: 16px;
  line-height: 1.8;
}
@media only screen and (max-width: 750px) {
  .homeHouse__text {
    margin-top: 20px;
  }
}
.homeHouse__btn {
  margin-top: 60px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homeHouse__btn {
    margin-top: 45px;
  }
}

.homeVoice {
  margin-top: 80px;
  padding: 90px 0 54px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .homeVoice {
    margin-top: 60px;
    padding: 50px 15px 60px;
  }
}
.homeVoiceList {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
@media only screen and (max-width: 750px) {
  .homeVoiceList {
    margin-top: 0;
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.homeVoiceList__item {
  width: 318px;
  margin: 0 22px;
}
@media only screen and (min-width: 751px) {
  .homeVoiceList__item:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 750px) {
  .homeVoiceList__item {
    width: 48.5%;
    margin: 20px 0 0;
  }
}
.homeVoiceList__thumb {
  overflow: hidden;
  border-radius: 20px 0 20px 0;
  width: 100%;
  height: 212px;
}
@media only screen and (max-width: 750px) {
  .homeVoiceList__thumb {
    height: 33.8666666667vw;
    border-radius: 12px 0 12px 0;
  }
}
.homeVoiceList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeVoiceList__area {
  margin-top: 10px;
  font-size: 13px;
  letter-spacing: 0.1em;
}
.homeVoiceList__title {
  font-size: 14px;
  margin-top: 15px;
  letter-spacing: 0.1em;
}
.homeVoice__btn {
  margin-top: 50px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homeVoice__btn {
    margin-top: 45px;
  }
}

.homeBlog {
  padding: 90px 0 80px;
}
@media only screen and (max-width: 750px) {
  .homeBlog {
    padding: 45px 25px 50px;
  }
}
.homeBlogList {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .homeBlogList {
    display: block;
    margin-top: 0;
  }
}
.homeBlogList__item {
  width: 328px;
  margin: 0 6px;
}
@media only screen and (max-width: 750px) {
  .homeBlogList__item {
    width: 100%;
    max-width: 328px;
    margin: 20px auto 0;
  }
}
.homeBlogList__item a {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  text-decoration: none;
  color: inherit;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}
@media (hover: hover) {
  .homeBlogList__item a {
    transition: opacity 0.3s;
  }
  .homeBlogList__item a:hover {
    opacity: 0.8;
  }
}
.homeBlogList__thumb {
  width: 107px;
}
.homeBlogList__info {
  width: calc(100% - 132px);
}
.homeBlogList__cat {
  display: inline-block;
  padding: 2px 14px;
  font-size: 13px;
  background-color: #fff;
  margin-bottom: 12px;
}
.homeBlogList__date .date {
  font-size: 12px;
  letter-spacing: 0.12em;
  font-family: "Montserrat", sans-serif;
}
.homeBlogList__date .cat {
  font-size: 13px;
  letter-spacing: 0.1em;
  margin-left: 1em;
}
.homeBlogList__title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
@media only screen and (max-width: 750px) {
  .homeBlogList__title {
    font-size: 13px;
  }
}
.homeBlogList__excerpt {
  font-size: 13px;
}
@media only screen and (max-width: 750px) {
  .homeBlogList__excerpt {
    font-size: 12px;
  }
}
.homeBlog__btn {
  margin-top: 70px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .homeBlog__btn {
    margin-top: 45px;
  }
}

.homeAbout {
  padding: 115px 0 115px;
  background: url(../img/home/deco_about.svg) no-repeat -60px -60px/384px auto;
  background-color: #f5f5f5;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .homeAbout {
    padding: 50px 0;
    background: url(../img/home/deco_about.svg) no-repeat -88px 20px/280px auto;
    background-color: #f5f5f5;
  }
}
.homeAbout__inner {
  width: 100%;
  max-width: 1010px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .homeAbout__inner {
    flex-direction: column;
    justify-content: center;
  }
}
.homeAbout__title {
  position: absolute;
  left: 46px;
  top: 226px;
  font-size: 28px;
  letter-spacing: 0.01em;
  transform: rotate(90deg);
  transform-origin: center left;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .homeAbout__title {
    left: calc(50% - 70px);
    top: 50px;
  }
}
.homeAbout__subtitle {
  writing-mode: vertical-rl;
  font-size: 36px;
  letter-spacing: 0.1em;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .homeAbout__subtitle {
    font-size: 30px;
    order: 1;
  }
}
.homeAboutText {
  width: 186px;
  margin-left: 40px;
}
@media only screen and (max-width: 750px) {
  .homeAboutText {
    width: 100%;
    margin: 0;
    padding: 30px 0 0 28px;
    order: 3;
  }
}
.homeAboutText .item {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 4px 0;
}
@media (hover: hover) {
  .homeAboutText .item:hover {
    cursor: pointer;
  }
}
.homeAboutText .item.is-current {
  color: #999999;
}
.homeAboutGallery {
  position: relative;
  width: 663px;
}
@media only screen and (max-width: 750px) {
  .homeAboutGallery {
    order: 2;
    width: 100%;
    padding-left: 28px;
    margin-top: 25px;
  }
}
.homeAboutGallery .slick-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2px;
  left: 0px;
  width: 100%;
}
@media only screen and (max-width: 750px) {
  .homeAboutGallery .slick-dots {
    width: auto;
    left: auto;
    right: 20px;
    bottom: -30px;
  }
}
.homeAboutGallery .slick-dots li {
  margin: 0 5px;
}
.homeAboutGallery .slick-dots li button {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  padding: 0;
  border-style: none;
  background-color: #fff;
  text-indent: -9999px;
  pointer-events: none;
  border-radius: 50%;
}
@media only screen and (max-width: 750px) {
  .homeAboutGallery .slick-dots li button {
    width: 42px;
    height: 8px;
    border-radius: 50vh;
  }
}
.homeAboutGallery .slick-dots li.slick-active button {
  background-color: #cccccc;
}

.homeInstagram {
  margin-top: 110px;
}
@media only screen and (max-width: 750px) {
  .homeInstagram {
    margin: 75px 15px 0;
  }
}
.homeInstagram__inner {
  max-width: 1010px;
  margin: 0 auto;
  padding: 20px 0 100px;
  position: relative;
  border-top: 1px solid #808080;
  border-bottom: 1px solid #808080;
}
@media only screen and (max-width: 750px) {
  .homeInstagram__inner {
    padding: 0 0 40px;
    margin: 0;
    border-top-style: none;
  }
}
.homeInstagram__title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.homeInstagram__title img {
  margin-right: 12px;
}
@media only screen and (max-width: 750px) {
  .homeInstagram__title {
    text-align: center;
  }
}
.homeInstagram__link {
  position: absolute;
  right: 8px;
  top: 20px;
}
@media only screen and (max-width: 750px) {
  .homeInstagram__link {
    display: none;
  }
}
.homeInstagram__link-sp {
  margin-top: 36px;
  text-align: center;
}
@media only screen and (min-width: 751px) {
  .homeInstagram__link-sp {
    display: none;
  }
}
.homeInstagram__link-sp a {
  display: inline-block;
  padding: 10px 22px;
  border: 1px solid #212529;
  color: inherit;
  text-align: center;
  font-size: 13px;
  text-decoration: none;
  position: relative;
}
.homeInstagram__link-sp a:after {
  content: "";
  background: url(../img/arrow_black.svg) no-repeat center/contain;
  width: 11px;
  height: 10px;
  position: absolute;
  right: 7px;
  bottom: 14px;
}
@media only screen and (max-width: 750px) {
  .homeInstagram__link-sp a:after {
    display: none;
  }
}
.homeInstagramList {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
@media only screen and (max-width: 750px) {
  .homeInstagramList {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }
}
.homeInstagramList .item {
  width: 160px;
}
@media only screen and (max-width: 750px) {
  .homeInstagramList .item {
    margin: 5px 5px;
  }
}
.homeInstagramList .item img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.homeContact {
  padding-top: 120px;
}
@media only screen and (max-width: 750px) {
  .homeContact {
    padding-top: 45px;
  }
}
.homeContact__text {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 2;
  margin-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .homeContact__text {
    margin-bottom: 28px;
  }
}
.homeContactTel {
  text-align: center;
  height: 96px;
  background: url(../img/line_tel.svg) no-repeat center/contain;
  margin-top: 40px;
  padding-top: 8px;
}
@media only screen and (max-width: 750px) {
  .homeContactTel {
    width: 320px;
    height: 98px;
    background: url(../img/line_tel_sp.svg) no-repeat center/contain;
    margin: 28px auto 0;
    padding-top: 12px;
  }
}
.homeContactTel__text {
  font-size: 18px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .homeContactTel__text {
    font-size: 14px;
  }
}
.homeContactTel__tel {
  font-size: 36px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .homeContactTel__tel {
    font-size: 29px;
  }
}
.homeContactBanners {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .homeContactBanners {
    margin-top: 0;
    display: block;
  }
}
.homeContactBanners__item {
  width: 460px;
  margin: 0 10px;
  border: 1px solid #212529;
}
@media only screen and (max-width: 750px) {
  .homeContactBanners__item {
    margin: 30px auto 0;
    width: 320px;
  }
}
.homeContactBanners__item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 128px;
  text-decoration: none;
  color: inherit;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (min-width: 751px) {
  .homeContactBanners__item.-right .homeContactBanners__right {
    margin-left: 27px;
  }
}
.homeContactBanners__thumb {
  width: 166px;
}
@media only screen and (max-width: 750px) {
  .homeContactBanners__thumb {
    width: 106px;
  }
}
.homeContactBanners__right {
  margin-left: 16px;
}
.homeContactBanners__text {
  font-size: 14px;
  letter-spacing: 0.05em;
}
.homeContactBanners__title {
  font-size: 24px;
  letter-spacing: 0.05em;
  margin-top: 8px;
  font-weight: 500;
  position: relative;
  left: -0.4em;
}
@media only screen and (max-width: 750px) {
  .homeContactBanners__title {
    font-size: 18px;
  }
}
.homeContactBanners .img02 img {
  max-width: initial;
  width: 190px;
  position: relative;
  left: -10px;
  top: 1px;
}
@media only screen and (max-width: 750px) {
  .homeContactBanners .img02 img {
    width: 146px;
    left: -34px;
  }
}

.blog .bg-gray {
  background-color: #f5f5f5;
}

.blogWrapper {
  padding: 45px 0 70px;
  max-width: 930px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.blogLeft {
  width: 700px;
}

.blogArchive {
  margin-bottom: 70px;
}

.blogArchiveList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blogArchiveList__item {
  width: 340px;
  margin-bottom: 20px;
  background-color: #fff;
}

.blogArchiveList__item a {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  color: #333333;
  text-decoration: none;
}

.blogArchiveList__item a:hover {
  opacity: 0.8;
}

.blogArchiveList__thumb {
  width: 108px;
  height: 96px;
}

.blogArchiveList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.blogArchiveList__info {
  width: calc(100% - 128px);
}

.blogArchiveList__cat {
  display: inline-block;
  padding: 2px 14px;
  font-size: 13px;
  background-color: #f5f5f5;
  margin-bottom: 12px;
}

.blogArchiveList__date {
  font-size: 13px;
  color: #9A9D9F;
  margin-bottom: 5px;
  line-height: 1;
}

.blogArchiveList__title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.blogArchiveList__excerpt {
  font-size: 13px;
}

.blogArchiveBack {
  margin-top: 30px;
  text-align: center;
}

.blogArchiveBack a {
  display: inline-block;
  width: 218px;
  padding: 10px;
  border: 1px solid #333333;
  color: #333333;
  font-size: 15px;
  text-decoration: none;
}

.blogArchiveBack a:hover {
  opacity: 0.8;
}

.blogRight {
  width: 174px;
}

.blogRightBlock:not(:last-child) {
  margin-bottom: 60px;
}

.blogRightBlock__h2 {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid #333;
  margin-bottom: 15px;
  line-height: 1.2;
}

.blogRightBlockList {
  font-size: 14px;
}

.blogRightBlockList li {
  margin-bottom: 5px;
}

.blogRightBlockList li a {
  color: #333;
  text-decoration: none;
}

.blogRightBlockList li a:hover {
  text-decoration: underline;
}

.archive-open:hover {
  cursor: pointer;
  text-decoration: underline;
}

.archive-hidden {
  display: none;
}

.blogSingleLeft {
  width: 758px;
  margin-left: -38px;
}

.blogSingleArticle {
  background-color: #fff;
  padding: 78px 78px 60px;
}

.blogSingleDate {
  margin-bottom: 20px;
  font-size: 13px;
  color: #9A9D9F;
}

.blogSingleTitle {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
}

.blogSinglePost > *:last-child {
  margin-bottom: 0;
}

.blogSingleText {
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 2;
}

.blogSingleImg {
  margin-bottom: 50px;
}

.blogSingleCat {
  text-align: right;
  margin-top: 6px;
  font-size: 13px;
}

@media screen and (max-width: 750px) {
  .blogWrapper {
    display: block;
    padding: 35px 22px 56px;
  }
  .blogSingleLeft {
    width: 100%;
    margin-left: 0;
  }
  .blogSingleArticle {
    padding: 32px 20px 40px;
  }
  .blogSingleTitle {
    margin-bottom: 25px;
    font-size: 15px;
  }
  .blogSingleText {
    margin-bottom: 30px;
  }
  .blogSingleImg {
    margin-bottom: 30px;
  }
  .nextPostBg_blog {
    width: 100%;
  }
  .blogRight {
    width: 100%;
    margin-top: 90px;
  }
  .nextPostBg_blog {
    font-size: 13px;
  }
  .nextPostBg_blog .center a {
    width: 168px;
    padding: 8px;
  }
  .blogLeft {
    width: 100%;
  }
  .blogArchiveList {
    display: block;
  }
  .blogArchiveList__item {
    width: 100%;
  }
  .blogArchiveList__date {
    font-size: 12px;
  }
  .blogArchiveList__title {
    font-size: 13px;
  }
  .blogArchiveList__excerpt {
    font-size: 12px;
  }
}
.companyHero__bg {
  height: 48.9vw;
  width: 100%;
  max-height: 90vh;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .companyHero__bg {
    height: auto;
    max-height: initial;
    width: auto;
  }
}
.companyHero__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.companyHero__title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Zen Old Mincho", serif;
  padding-top: 90px;
}
@media only screen and (max-width: 750px) {
  .companyHero__title {
    padding-top: 0;
  }
}
.companyHero__title .en {
  display: block;
  font-size: 40px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .companyHero__title .en {
    font-size: 32px;
  }
}
.companyHero__title .ja {
  display: block;
  font-size: 18px;
  letter-spacing: 0.1em;
}
.companyHero__read {
  font-size: 22px;
  margin-top: 22px;
  text-align: center;
  font-family: "Zen Old Mincho", serif;
}
.companyHero__read .small {
  font-size: 16px;
}

.companyProfile {
  margin-top: 50px;
  padding: 90px 0 120px;
  background-color: #f5f5f5;
  overflow: hidden;
}
@media only screen and (max-width: 750px) {
  .companyProfile {
    padding: 40px 0 100px;
  }
}
.companyProfile__table {
  width: 100%;
  max-width: 682px;
  margin: 50px auto 0;
  line-height: 2;
}
@media only screen and (max-width: 750px) {
  .companyProfile__table {
    margin: 30px 15px 0;
    width: auto;
  }
}
.companyProfile__table th {
  background-color: #fff;
  vertical-align: middle;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-family: "Zen Old Mincho", serif;
  border-top: 1px solid #808080;
  border-bottom: 1px solid #808080;
}
@media only screen and (max-width: 750px) {
  .companyProfile__table th {
    padding: 15px 12px;
    text-align: left;
    font-size: 14px;
  }
}
.companyProfile__table td {
  padding: 18px 18px 18px 48px;
  font-size: 15px;
  font-weight: 500;
  border-top: 1px solid #808080;
  border-bottom: 1px solid #808080;
}
@media only screen and (max-width: 750px) {
  .companyProfile__table td {
    padding: 15px 12px;
    font-size: 14px;
  }
}
.companyProfileGallery {
  display: flex;
  margin-top: 100px;
}
@media only screen and (max-width: 750px) {
  .companyProfileGallery {
    margin-top: 80px;
  }
}
.companyProfileGalleryList {
  display: flex;
}
.companyProfileGalleryList:first-child {
  animation: loop 180s linear infinite;
}
.companyProfileGalleryList:nth-child(2) {
  animation: loop2 180s -120s linear infinite;
}
.companyProfileGalleryList:last-child {
  animation: loop3 180s -60s linear infinite;
}
.companyProfileGallery__item img {
  max-width: initial;
  width: auto;
  height: 500px;
}
@media only screen and (max-width: 750px) {
  .companyProfileGallery__item img {
    height: 275px;
  }
}

.companyAccess {
  background-color: #f5f5f5;
}
.companyAccess__title {
  font-size: 28px;
  letter-spacing: 0.1em;
  font-family: "Zen Old Mincho", serif;
  padding: 0 20px 12px;
}
@media only screen and (max-width: 750px) {
  .companyAccess__title {
    text-align: center;
  }
}
.companyAccess iframe {
  width: 100%;
  height: 434px;
}
@media only screen and (max-width: 750px) {
  .companyAccess iframe {
    height: 300px;
  }
}

.companyIntro {
  padding: 120px 0 95px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .companyIntro {
    padding: 40px 28px 50px;
  }
}
.companyIntro__inner {
  max-width: 1038px;
  margin: 0 auto;
}
.companyIntroRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .companyIntroRow {
    display: block;
  }
}
.companyIntroRow__left {
  width: 616px;
}
@media only screen and (max-width: 750px) {
  .companyIntroRow__left {
    width: auto;
    margin: 0 -28px;
  }
}
.companyIntroRow__right {
  flex: 1;
  padding-left: 54px;
}
@media only screen and (max-width: 750px) {
  .companyIntroRow__right {
    padding: 28px 0 0;
  }
}
.companyIntroRow__title {
  font-size: 22px;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .companyIntroRow__title {
    font-size: 18px;
  }
}
.companyIntroRow__text {
  font-size: 15px;
  line-height: 1.8;
  letter-spacing: 0.02em;
  margin-top: 28px;
}
.companyIntroList {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .companyIntroList {
    margin-top: 38px;
    display: block;
  }
}
.companyIntroList__item {
  width: 246px;
}
@media only screen and (max-width: 750px) {
  .companyIntroList__item {
    width: 100%;
    margin: 20px auto 0;
  }
}

@media only screen and (max-width: 750px) {
  .conceptHero {
    padding: 0 28px;
  }
}
.conceptHero__bg {
  height: 56.2vw;
  width: 100%;
  max-height: 80vh;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .conceptHero__bg {
    height: auto;
    max-height: initial;
    margin: 0 -28px;
    width: auto;
  }
}
.conceptHero__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.conceptHero__title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Zen Old Mincho", serif;
  padding-top: 90px;
}
@media only screen and (max-width: 750px) {
  .conceptHero__title {
    padding-top: 0;
  }
}
.conceptHero__title .en {
  display: block;
  font-size: 40px;
  letter-spacing: 0.1em;
}
.conceptHero__title .ja {
  display: block;
  font-size: 18px;
  letter-spacing: 0.1em;
}
.conceptHero__subtitle {
  font-size: 22px;
  line-height: 2;
  font-family: "Zen Old Mincho", serif;
  margin-top: 60px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .conceptHero__subtitle {
    font-size: 20px;
    margin-top: 40px;
    text-align: left;
  }
}
.conceptHero__text {
  max-width: 660px;
  margin: 60px auto 0;
  font-family: "Zen Old Mincho", serif;
  line-height: 2;
}
@media only screen and (max-width: 750px) {
  .conceptHero__text {
    margin-top: 36px;
  }
}

.conceptPolicy {
  background-color: #f5f5f5;
  padding: 100px 0 130px;
  margin-top: 110px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy {
    margin-top: 40px;
    padding: 30px 28px 40px;
  }
}
.conceptPolicy__inner {
  max-width: 1080px;
  margin: 0 auto;
}
.conceptPolicyTop {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .conceptPolicyTop {
    display: block;
  }
}
.conceptPolicyTop__left {
  width: 530px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .conceptPolicyTop__left {
    width: auto;
    margin: 0 -28px;
  }
}
.conceptPolicyTop__right {
  width: 490px;
  padding-top: 10px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicyTop__right {
    width: auto;
    padding-top: 24px;
  }
}
.conceptPolicyTop__title {
  position: relative;
  padding-left: 22px;
  font-family: "Zen Old Mincho", serif;
}
.conceptPolicyTop__title .en {
  font-size: 16px;
  letter-spacing: 0.1em;
  display: block;
  padding-left: 15px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicyTop__title .en {
    font: 14px;
  }
}
.conceptPolicyTop__title .ja {
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  display: block;
}
@media only screen and (max-width: 750px) {
  .conceptPolicyTop__title .ja {
    white-space: nowrap;
    font-size: 22px;
  }
}
.conceptPolicyTop__title .num {
  font-size: 30px;
  position: absolute;
  bottom: -3px;
  left: 0px;
}
.conceptPolicyTop__subtitle {
  font-size: 20px;
  margin-top: 25px;
  font-family: "Zen Old Mincho", serif;
}
.conceptPolicyTop__text {
  font-size: 16px;
  line-height: 1.8;
  margin-top: 20px;
  font-family: "Zen Old Mincho", serif;
}
.conceptPolicyPoint {
  padding-top: 80px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .conceptPolicyPoint {
    padding-top: 5px;
    display: block;
  }
}
.conceptPolicyPoint li {
  border-right: 1px solid #212529;
  width: 270px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (min-width: 751px) {
  .conceptPolicyPoint li:first-child {
    border-left: 1px solid #212529;
  }
}
@media only screen and (max-width: 750px) {
  .conceptPolicyPoint li {
    border-left: 1px solid #212529;
    margin: 45px auto 0;
  }
}
.conceptPolicy__btn {
  text-align: center;
  margin-top: 40px;
}

.conceptPolicy1 {
  padding-top: 100px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy1 {
    padding-top: 50px;
  }
}
.conceptPolicy1Gallery {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy1Gallery {
    margin-top: 50px;
    display: block;
  }
}
.conceptPolicy1Gallery__left {
  width: 500px;
  background-color: #f5f5f5;
}
.conceptPolicy1Gallery__left img {
  mix-blend-mode: multiply;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy1Gallery__left {
    width: auto;
  }
}
.conceptPolicy1Gallery__right {
  width: 486px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy1Gallery__right {
    width: auto;
    margin: 27px -5px 0;
  }
  .conceptPolicy1Gallery__right .item {
    margin: 0 5px;
  }
}

.conceptPolicy2 {
  padding-top: 160px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy2 {
    padding-top: 80px;
  }
}
.conceptPolicy2Gallery {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy2Gallery {
    margin: 36px -19px 0;
    flex-wrap: wrap;
  }
}
.conceptPolicy2Gallery__item {
  width: 250px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy2Gallery__item {
    width: calc(50% - 10px);
    margin: 5px;
  }
}
.conceptPolicy2__copy {
  position: absolute;
  top: -20px;
  left: 20px;
  padding: 54px 10px 24px 0;
  writing-mode: vertical-rl;
  font-size: 18px;
  font-family: "Zen Old Mincho", serif;
  border-right: 1px solid #212529;
  color: #fff;
}

.conceptPolicy3 {
  padding-top: 180px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy3 {
    padding-top: 80px;
  }
}
.conceptPolicy3Gallery {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy3Gallery {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}
.conceptPolicy3Gallery__item {
  width: 254px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy3Gallery__item {
    margin-top: 25px;
  }
}
.conceptPolicy3Gallery__title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #1a1a1a;
  width: 148px;
  padding: 1px 0 1px 8px;
}
.conceptPolicy3Gallery__thumb {
  margin-top: 8px;
}
.conceptPolicy3Gallery__text {
  font-size: 13px;
  margin-top: 8px;
}

.conceptPolicy4 {
  padding-top: 120px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy4 {
    padding-top: 80px;
  }
}
.conceptPolicy4Gallery {
  display: flex;
  justify-content: center;
  margin-top: 72px;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy4Gallery {
    margin-top: 45px;
    flex-direction: column;
    align-items: center;
  }
}
.conceptPolicy4Gallery__item {
  width: 228px;
  height: 153px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy4Gallery__item {
    margin: 12px 0 0;
    display: block;
    height: auto;
  }
}
.conceptPolicy4Gallery__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.conceptPolicy4Gallery__item p {
  font-size: 18px;
  font-family: "Zen Old Mincho", serif;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy4Gallery__item p {
    padding: 20px 0;
  }
}
.conceptPolicy4__copy {
  position: absolute;
  bottom: -130px;
  left: 55px;
  padding: 54px 10px 24px 0;
  writing-mode: vertical-rl;
  font-size: 18px;
  font-family: "Zen Old Mincho", serif;
  border-right: 1px solid #212529;
}
@media only screen and (max-width: 750px) {
  .conceptPolicy4__copy {
    top: -17px;
    right: 24vw;
    left: auto;
    bottom: auto;
    color: #fff;
    border-color: #fff;
    padding-top: 28px;
    padding-bottom: 0;
  }
}

.conceptCost {
  padding-top: 90px;
}
@media only screen and (max-width: 750px) {
  .conceptCost {
    padding: 50px 28px 0;
  }
}
.conceptCost__inner {
  max-width: 780px;
  margin: 0 auto;
}
.conceptCost__subtitle {
  margin-top: 48px;
  font-size: 23px;
  font-family: "Zen Old Mincho", serif;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .conceptCost__subtitle {
    font-size: 20px;
    margin-top: 36px;
  }
}
.conceptCost__thumb {
  text-align: center;
  margin-top: 35px;
}
@media only screen and (max-width: 750px) {
  .conceptCost__thumb {
    margin-top: 20px;
  }
}
.conceptCost__read {
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 1.8;
}
@media only screen and (max-width: 750px) {
  .conceptCost__read {
    margin-top: 30px;
    text-align: left;
    font-size: 18px;
  }
}
.conceptCost__text {
  font-size: 16px;
  line-height: 1.8;
  margin-top: 50px;
}
@media only screen and (max-width: 750px) {
  .conceptCost__text {
    margin-top: 24px;
  }
}
.conceptCost__text + .conceptCost__text {
  margin-top: 1em;
}

/* ----------------------------------------------------------------------
 contact
---------------------------------------------------------------------- */
.contactH1Area {
  text-align: center;
  color: #fff;
  padding: 60px 0 60px;
  background-color: #333;
  position: relative;
  margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
  .contactH1Area {
    padding: 45px 0;
    margin-bottom: 35px;
  }
}
.contactH1Area__title {
  font-size: 26px;
}

@media only screen and (max-width: 750px) {
  .contactH1Area__title {
    font-size: 20px;
  }
}
.contactH1Area__text {
  font-size: 17px;
  margin-top: 8px;
}

@media only screen and (max-width: 750px) {
  .contactH1Area__text {
    font-size: 13px;
  }
}
.contactH1Area:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51, 51, 51, 0);
  border-top-color: #333;
  border-width: 27px;
  margin-left: -27px;
}

.contactContainer {
  max-width: 692px;
  margin: 0 auto;
}

.contactHead {
  margin-bottom: 140px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .contactHead {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 40px;
  }
}
.contactHead__title {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .contactHead__title {
    font-size: 17px;
  }
}
.contactHead__text {
  text-align: left;
  font-size: 17px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 750px) {
  .contactHead__text {
    font-size: 14px;
  }
}
.contactHead__img {
  display: inline-block;
  position: relative;
}

.contactHead__free {
  position: absolute;
  top: 12px;
  right: 17px;
  background-color: #FF3333;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  font-size: 23px;
  padding-top: 27px;
  text-align: center;
  line-height: 1;
  font-weight: bold;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .contactHead__free {
    width: 48px;
    height: 48px;
    font-size: 14px;
    padding-top: 17px;
  }
}
.contactFormBlock {
  border: 10px solid #505050;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 34px;
  margin-bottom: 130px;
}

.contactFormBlock__h2 {
  background-color: #505050;
  color: #fff;
  text-align: center;
  width: calc(100% + 36px);
  margin-left: -18px;
  font-size: 17px;
  font-weight: bold;
  padding: 6px 0 14px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 750px) {
  .contactFormBlock__h2 {
    font-size: 17px;
  }
}
.contactFormTable {
  margin-bottom: 15px;
}

.contactFormTable:last-child {
  margin-bottom: 30px;
}

.contactFormTable dt {
  font-size: 15px;
  margin-top: 3px;
  margin-bottom: 5px;
  font-weight: 500;
}

.contactFormTable dt .hissu {
  background-color: #FF3333;
  color: #fff;
  display: inline-block;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 10px;
}

.contactFormTable input[type=text],
.contactFormTable input[type=email],
.contactFormTable input[type=tel] {
  background-color: #FAFAFA;
  height: 34px;
  border-radius: 0px;
  width: 100%;
}

.contactFormTable textarea {
  background-color: #FAFAFA;
  border-radius: 0px;
  width: 100%;
}

.contactFormTable input.zip {
  width: 240px;
  margin-right: 0.3em;
}

.contactFormBtn {
  margin-top: 33px;
  margin-bottom: 45px;
  text-align: center;
}

.contactFormBtn input {
  -webkit-appearance: none;
  display: block;
  width: 324px;
  height: 52px;
  border-style: none;
  background: #333333;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
  margin: 0 auto 5px;
}

.contactFormBtn input:hover {
  opacity: 0.8;
  cursor: pointer;
}

.contactFormBtn input.backBtn {
  background-color: #eee;
  color: #000;
}

@media only screen and (max-width: 750px) {
  .contactFormBtn input {
    width: 100%;
  }
}
.contactFomLast {
  text-align: center;
}

.contactFomLast__text {
  font-size: 16px;
  font-weight: bold;
}

.contactFomLast__tel {
  font-size: 29px;
  font-weight: bold;
}

.contactFomLast img {
  margin-right: 0.3em;
}

.flowHero {
  padding: 140px 120px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
@media only screen and (max-width: 750px) {
  .flowHero {
    padding: 28px 28px 60px;
    height: auto;
    margin-bottom: 40px;
  }
}
.flowHero__bg {
  position: relative;
  border-radius: 0 33px 0 33px;
  overflow: hidden;
}
@media only screen and (max-width: 750px) {
  .flowHero__bg {
    border-radius: 0px;
  }
}
.flowHero__title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .flowHero__title {
    color: #fff;
  }
}
.flowHero__title .en {
  font-size: 36px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .flowHero__title .en {
    font-size: 30px;
  }
}
.flowHero__title .ja {
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
  margin-top: 15px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .flowHero__title .ja {
    font-size: 14px;
  }
}
.flowHero__title .ja:before {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #212529;
  position: absolute;
  top: 0px;
  left: calc(50% - 10px);
}
@media only screen and (max-width: 750px) {
  .flowHero__title .ja:before {
    background-color: #fff;
  }
}
.flowHero__read {
  font-size: 17px;
  line-height: 2;
  font-family: "Zen Old Mincho", serif;
  text-align: center;
  margin-top: 40px;
}
@media only screen and (max-width: 750px) {
  .flowHero__read {
    display: block;
    text-align: left;
    font-size: 15px;
  }
}

.flowContent {
  padding: 80px 0;
}
@media only screen and (max-width: 750px) {
  .flowContent {
    padding: 40px 20px 50px;
  }
}
.flowContent__inner {
  max-width: 1120px;
  margin: 0 auto;
}
.flowContent__num {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Zen Old Mincho", serif;
  position: relative;
  left: -27px;
}
@media only screen and (max-width: 750px) {
  .flowContent__num {
    left: -22px;
  }
}
.flowContent__num .text {
  font-size: 16px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .flowContent__num .text {
    font-size: 13px;
  }
}
.flowContent__num .num {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.05em;
  height: 96px;
  width: 44px;
  background: url(../img/flow/shape_title.svg) no-repeat left center/contain;
  padding: 19px 0 0 16px;
  margin-left: 4px;
}
@media only screen and (max-width: 750px) {
  .flowContent__num .num {
    font-size: 32px;
    width: 34px;
    height: 78px;
    padding: 14px 0 0 12px;
  }
}
.flowContent__h2 {
  margin-top: 40px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .flowContent__h2 {
    margin-top: 32px;
    font-size: 18px;
  }
}
.flowContent__read {
  margin-top: 15px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .flowContent__read {
    text-align: left;
  }
}

.flowContent1 {
  background-color: #f5f5f5;
}
.flowContent1List {
  margin: 50px -25px -65px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 750px) {
  .flowContent1List {
    display: block;
    margin: 0;
  }
}
.flowContent1List__item {
  width: calc(25% - 50px);
  margin: 0 25px 65px;
}
@media only screen and (max-width: 750px) {
  .flowContent1List__item {
    width: auto;
    padding: 36px calc((100% - 242px) / 2) 0;
    border-top: 1px solid #212529;
    margin: 36px 0 0;
  }
  .flowContent1List__item:first-child {
    border-top: none;
    margin-top: 0;
  }
}
.flowContent1List__thumb {
  height: 157px;
}
@media only screen and (max-width: 750px) {
  .flowContent1List__thumb {
    height: auto;
  }
}
.flowContent1List__thumb .img05 {
  max-width: initial;
  width: 256px;
  position: relative;
  left: -7px;
  top: -7px;
}
.flowContent1List__title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-top: 10px;
}
.flowContent1List__text {
  font-size: 14px;
  margin-top: 10px;
  height: 150px;
}
@media only screen and (max-width: 750px) {
  .flowContent1List__text {
    margin-bottom: 30px;
    height: auto;
  }
}
.flowContent1List__btn + .flowContent1List__btn {
  margin-top: 6px;
}

.flowContent2Row {
  display: flex;
  margin-top: 60px;
}
@media only screen and (max-width: 750px) {
  .flowContent2Row {
    margin-top: 20px;
    display: block;
  }
}
.flowContent2Row__left {
  position: relative;
  width: 553px;
}
@media only screen and (max-width: 750px) {
  .flowContent2Row__left {
    width: auto;
  }
}
.flowContent2Row__left .accent {
  position: absolute;
  top: 265px;
  right: -127px;
}
@media only screen and (max-width: 750px) {
  .flowContent2Row__left .accent {
    bottom: -54px;
    left: 22px;
    top: auto;
    right: auto;
  }
}
.flowContent2Row__right {
  font-family: "Zen Old Mincho", serif;
  width: 420px;
  margin-left: 55px;
  padding-top: 50px;
  line-height: 2;
}
@media only screen and (max-width: 750px) {
  .flowContent2Row__right {
    width: auto;
    margin-left: 0;
    padding-top: 60px;
    font-size: 15px;
  }
}

.flowContent3 {
  background-color: #f5f5f5;
}
.flowContent3Row {
  display: flex;
  margin-top: 65px;
}
@media only screen and (max-width: 750px) {
  .flowContent3Row {
    margin-top: 30px;
    flex-direction: column-reverse;
  }
}
.flowContent3Row__left {
  width: 580px;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .flowContent3Row__left {
    width: auto;
    margin-top: 20px;
  }
}
.flowContent3Row__title {
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 8px 8px;
  border-bottom: 1px solid #212529;
}
.flowContent3Row__subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-top: 25px;
}
@media only screen and (max-width: 750px) {
  .flowContent3Row__subtitle {
    font-size: 18px;
  }
}
.flowContent3Row__text {
  padding-right: 30px;
  margin-top: 20px;
  line-height: 1.875;
}
@media only screen and (max-width: 750px) {
  .flowContent3Row__text {
    padding-right: 0;
    font-size: 15px;
  }
}

.flowContent4Row {
  margin-top: 80px;
  display: flex;
}
@media only screen and (max-width: 750px) {
  .flowContent4Row {
    margin-top: 30px;
    display: block;
  }
}
.flowContent4Row__right {
  margin-left: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .flowContent4Row__right {
    margin-left: 0;
    margin-top: 30px;
  }
}
.flowContent4Row__text {
  font-size: 16px;
  line-height: 1.68;
  font-family: "Zen Old Mincho", serif;
  width: 420px;
}
@media only screen and (max-width: 750px) {
  .flowContent4Row__text {
    font-size: 15px;
    width: auto;
  }
}
.flowContent4Row__gallery {
  display: flex;
}
@media only screen and (max-width: 750px) {
  .flowContent4Row__gallery {
    margin-top: 30px;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 751px) {
  .flowContent4Row__gallery .item + .item {
    margin-left: 13px;
  }
}
@media only screen and (max-width: 750px) {
  .flowContent4Row__gallery .item {
    width: 49%;
  }
}
.flowContent4List {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.flowContent4List__item {
  width: calc(25% - 28px);
  border: 1px solid #212529;
  height: 330px;
  margin: 65px 14px 0;
  padding: 20px 16px;
  font-family: "Zen Old Mincho", serif;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .flowContent4List__item {
    width: 258px;
    margin: 54px auto 0;
  }
}
.flowContent4List__item:after {
  content: "";
  width: 13px;
  height: 48px;
  background: url(../img/flow/arrow.svg) no-repeat center/contain;
  position: absolute;
  right: -23px;
  top: calc(50% - 24px);
}
@media only screen and (max-width: 750px) {
  .flowContent4List__item:after {
    transform: rotate(90deg);
    top: -48px;
    right: calc(50% - 6px);
  }
}
@media only screen and (min-width: 751px) {
  .flowContent4List__item:nth-child(4n):after {
    display: none;
  }
}
@media only screen and (max-width: 750px) {
  .flowContent4List__item:first-child:after {
    display: none;
  }
}
.flowContent4List__num {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-align: center;
}
.flowContent4List__title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 6px;
  height: 66px;
  text-align: center;
}
.flowContent5 {
  background-color: #f5f5f5;
}
.flowContent5List {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .flowContent5List {
    margin-top: 30px;
    display: block;
  }
}
.flowContent5List__item {
  width: 540px;
  background-color: #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
}
@media only screen and (max-width: 750px) {
  .flowContent5List__item {
    width: 100%;
  }
  .flowContent5List__item + .flowContent5List__item {
    margin-top: 25px;
  }
}
.flowContent5List__left {
  flex: 1;
  padding: 50px 34px 0 24px;
}
@media only screen and (max-width: 750px) {
  .flowContent5List__left {
    padding: 24px 12px 0;
  }
}
.flowContent5List__title {
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .flowContent5List__title {
    font-size: 16px;
  }
}
.flowContent5List__text {
  font-size: 16px;
  margin-top: 16px;
}
@media only screen and (max-width: 750px) {
  .flowContent5List__text {
    font-size: 13px;
  }
}
.flowContent5List__right {
  width: 146px;
}
.flowContent5Guarantee {
  width: 100%;
  max-width: 720px;
  margin: 56px auto 0;
  padding: 30px 0;
  text-align: center;
  border-top: 1px solid #212529;
  border-bottom: 1px solid #212529;
}
@media only screen and (max-width: 750px) {
  .flowContent5Guarantee {
    padding: 25px 15px;
  }
}
.flowContent5Guarantee__title {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  width: 390px;
  height: 44px;
  background-color: #666666;
  border-radius: 10px;
  padding-top: 9px;
}
@media only screen and (max-width: 750px) {
  .flowContent5Guarantee__title {
    width: 100%;
  }
}
.flowContent5Guarantee__list {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .flowContent5Guarantee__list {
    display: inline-block;
  }
}
.flowContent5Guarantee__list li {
  font-size: 18px;
  margin: 0 0.5em;
}
@media only screen and (max-width: 750px) {
  .flowContent5Guarantee__list li {
    text-align: left;
  }
}

.newsArchive {
  padding-top: 25px;
  padding-bottom: 80px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .newsArchive {
    padding: 36px 15px 40px;
  }
}
.newsArchive__inner {
  max-width: 1020px;
  margin: 0 auto;
}
.newsArchiveList {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -22px 0;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList {
    justify-content: space-between;
    margin: 0;
  }
}
.newsArchiveList__item {
  width: calc(33.33% - 44px);
  margin: 0 22px 45px;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList__item {
    width: 48.5%;
    margin: 0 0 20px;
    position: relative;
  }
}
.newsArchiveList__thumb {
  position: relative;
  width: 100%;
  height: 212px;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList__thumb {
    height: 33.8666666667vw;
  }
}
.newsArchiveList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newsArchiveList__end {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.newsArchiveList__end span {
  width: 154px;
  height: 58px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  padding-top: 10px;
}
.newsArchiveList__info {
  margin-top: 10px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList__info {
    position: static;
  }
}
.newsArchiveList__tags {
  display: flex;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList__tags {
    flex-direction: column;
    align-items: flex-start;
  }
}
.newsArchiveList__tags li {
  font-size: 13px;
  padding: 0 10px;
  color: #fff;
  letter-spacing: 0.12em;
  background-color: #212529;
}
.newsArchiveList__tags li.-gray {
  background-color: #808080;
}
.newsArchiveList__tags li + li {
  margin-left: 10px;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList__tags li + li {
    margin: 8px 0 0;
  }
}
.newsArchiveList .new {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: #fff;
  display: block;
  width: 60px;
  padding: 2px 0 3px 0.06em;
  text-align: center;
  background-color: #e83a37;
  border-radius: 50vh;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}
@media only screen and (max-width: 750px) {
  .newsArchiveList .new {
    right: 4px;
    top: 4px;
  }
}
.newsArchiveList__date {
  margin-top: 12px;
  font-size: 13px;
  letter-spacing: 0.1em;
}
.newsArchiveList__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 15px;
}

.newsSingle__tags {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.newsSingle__tags li {
  min-width: 150px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.12em;
  padding: 3px 0.2em;
  background-color: #000000;
  color: #fff;
}
.newsSingle__tags li.-gray {
  background-color: #808080;
}
.newsSingle__eyecatch {
  max-width: 910px;
  margin: 36px auto 0;
}
@media only screen and (max-width: 750px) {
  .newsSingle__eyecatch {
    margin: 50px 28px 0;
  }
}
.newsSingle__inner {
  max-width: 810px;
  margin: 36px auto 0;
}
@media only screen and (max-width: 750px) {
  .newsSingle__inner {
    margin: 45px 20px 0;
  }
}
.newsSingle__title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .newsSingle__title {
    font-size: 16px;
  }
}
.newsSingle__time {
  text-align: center;
  font-size: 18px;
  line-height: 2;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-top: 30px;
}
@media only screen and (max-width: 750px) {
  .newsSingle__time {
    font-size: 16px;
    margin-top: 40px;
  }
}
.newsSingle__caution {
  max-width: 650px;
  margin: 50px auto 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  padding: 24px 0 28px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .newsSingle__caution {
    margin-top: 46px;
    font-size: 15px;
  }
}
.newsSingleContent {
  margin-top: 64px;
  font-weight: 500;
  line-height: 2;
}
@media only screen and (max-width: 750px) {
  .newsSingleContent {
    margin-top: 40px;
  }
}
.newsSingleGallery {
  margin-top: 65px;
}
@media only screen and (max-width: 750px) {
  .newsSingleGallery {
    margin-top: 40px;
  }
}
.newsSingleGallery__block {
  margin-top: 50px;
}
@media only screen and (max-width: 750px) {
  .newsSingleGallery__block {
    margin-top: 40px;
  }
}
.newsSingleGallery__block:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 750px) {
  .newsSingleGallery__block:first-child {
    margin-top: 0;
  }
}
.newsSingleGallery__thumb {
  display: flex;
  margin: 0 -40px;
}
@media only screen and (max-width: 750px) {
  .newsSingleGallery__thumb {
    margin: 0 -5px;
  }
}
.newsSingleGallery__thumb .item {
  flex: 1;
  margin: 0 40px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .newsSingleGallery__thumb .item {
    margin: 0 5px;
  }
}
.newsSingleGallery__text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-top: 40px;
}
@media only screen and (max-width: 750px) {
  .newsSingleGallery__text {
    font-size: 14px;
    margin-top: 24px;
  }
}
.newsSingleMap {
  margin-top: 90px;
}
.newsSingleMap__title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.02em;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 750px) {
  .newsSingleMap__title {
    font-size: 16px;
  }
}
.newsSingleMap__title:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  bottom: 0px;
  left: calc(50% - 10px);
  background-color: #404040;
}
@media only screen and (max-width: 750px) {
  .newsSingleMap__map {
    margin: 0 -20px;
  }
}
.newsSingleMap__map iframe {
  width: 100%;
  height: 434px;
}
@media only screen and (max-width: 750px) {
  .newsSingleMap__map iframe {
    height: 325px;
  }
}
.newsSingleContact {
  padding-top: 90px;
}
.newsSingleContact__message {
  padding-top: 27px;
}
.newsSingleContactTel {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../img/news/line_tel.svg) no-repeat center/contain;
}
@media only screen and (max-width: 750px) {
  .newsSingleContactTel {
    width: 320px;
    margin: 0 auto;
    background-size: 100% 100%;
  }
}
.newsSingleContactTel__text {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .newsSingleContactTel__text {
    font-size: 14px;
  }
}
.newsSingleContactTel__tel {
  font-size: 36px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .newsSingleContactTel__tel {
    font-size: 29px;
  }
}
.newsSingleContactTel__time {
  font-size: 14px;
  letter-spacing: 0.1em;
}
.newsSingleContact__message {
  margin: 27px auto 0;
  max-width: 350px;
  padding: 12px 0 12px;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-align: center;
  background-color: #f5f5f5;
}
.newsSingleContact__title {
  margin-top: 120px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.02em;
}
@media only screen and (max-width: 750px) {
  .newsSingleContact__title {
    margin-top: 100px;
  }
}
.newsSingleContact__title:before {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #212529;
  margin-right: 20px;
}
.newsSingleContact__title:after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #212529;
  margin-left: 20px;
}
.newsSingleContact__inner {
  max-width: 640px;
  margin: 50px auto 0;
}
.newsSingleContactBlock + .newsSingleContactBlock {
  margin-top: 20px;
}
.newsSingleContactBlock__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  padding-left: 1em;
  font-weight: 500;
  margin-bottom: 6px;
}
.newsSingleContactBlock__child {
  font-size: 15px;
  letter-spacing: 0.1em;
}
.newsSingleContact__btn {
  text-align: center;
  margin-top: 35px;
}

.nform-icon-required {
  font-size: 13px;
  letter-spacing: 0.12em;
  background-color: #e83a37;
  color: #fff;
  line-height: 1.69em;
  padding: 0 0.2em 0 0.32em;
  position: relative;
  left: 6px;
}

.nform-text {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #b3b3b3;
  height: 34px;
  padding: 0 0.25em;
}
.nform-text.-zip {
  width: 100px;
}

.nform-select {
  background-color: #fafafa;
  border: 1px solid #b3b3b3;
  height: 34px;
  padding: 0 0.25em;
  width: 140px;
}
.nform-select.-full {
  width: 100%;
}

.nform-textarea {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #b3b3b3;
  padding: 0.25em;
}

.nform-btn-send {
  -webkit-appearance: none;
  width: 320px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12em;
  background-color: #404040;
  cursor: pointer;
}
@media (hover: hover) {
  .nform-btn-send {
    transition: opacity 0.3s ease-in-out;
  }
  .nform-btn-send:hover {
    opacity: 0.8;
  }
}

.policyContent {
  max-width: 900px;
  margin: 70px auto 0;
}
@media only screen and (max-width: 750px) {
  .policyContent {
    margin-top: 40px;
    padding: 0 20px;
  }
}
.policyContent__title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin: 90px 0 20px;
}
@media only screen and (max-width: 750px) {
  .policyContent__title {
    margin-top: 45px;
    font-size: 18px;
  }
}
.policyContent__text {
  font-size: 16px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .policyContent__text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .soudanHero {
    padding: 0 28px;
  }
}
.soudanHero__bg {
  height: 48.9vw;
  width: 100%;
  max-height: 90vh;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .soudanHero__bg {
    height: auto;
    max-height: initial;
    margin: 0 -28px;
    width: auto;
  }
}
.soudanHero__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.soudanHero__title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Zen Old Mincho", serif;
  padding-top: 90px;
}
@media only screen and (max-width: 750px) {
  .soudanHero__title {
    padding-top: 0;
  }
}
.soudanHero__title .en {
  display: block;
  font-size: 26px;
  letter-spacing: 0.1em;
}
.soudanHero__title .ja {
  display: block;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .soudanHero__title .ja {
    font-size: 29px;
  }
}
.soudanHero__subtitle {
  font-size: 27px;
  font-family: "Zen Old Mincho", serif;
  margin-top: 36px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .soudanHero__subtitle {
    font-size: 18px;
  }
}
.soudanHero__read {
  font-size: 20px;
  line-height: 2;
  letter-spacing: 0.05em;
  margin-top: 70px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .soudanHero__read {
    font-size: 16px;
    margin-top: 20px;
  }
}
.soudanHero__medium {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
}
@media only screen and (max-width: 750px) {
  .soudanHero__medium {
    font-size: 18px;
    margin-top: 20px;
  }
}
.soudanHero__medium .large {
  font-size: 30px;
}
@media only screen and (max-width: 750px) {
  .soudanHero__medium .large {
    font-size: 27px;
  }
}
.soudanHero__text {
  margin-top: 50px;
  font-family: "Zen Old Mincho", serif;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .soudanHero__text {
    font-size: 15px;
    margin-top: 25px;
    text-align: left;
  }
}

.soudanIntro {
  margin-top: 60px;
  padding: 50px 0 75px;
  background-color: #f7f7f7;
}
@media only screen and (max-width: 750px) {
  .soudanIntro {
    margin-top: 40px;
    padding: 28px 28px 50px;
  }
}
.soudanIntro__inner {
  max-width: 1032px;
  margin: 0 auto;
}
.soudanIntroList {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .soudanIntroList {
    flex-wrap: wrap;
  }
}
.soudanIntroList__item {
  width: 210px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #212529;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin: 0 4px;
}
@media only screen and (max-width: 750px) {
  .soudanIntroList__item {
    width: 144px;
    height: 40px;
    margin: 0 5px 10px;
    font-size: 16px;
  }
}
.soudanIntroRow {
  margin-top: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .soudanIntroRow {
    flex-direction: column-reverse;
    margin-top: 35px;
  }
}
.soudanIntroRow__left {
  line-height: 2;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .soudanIntroRow__left {
    font-size: 15px;
    margin-top: 40px;
  }
  .soudanIntroRow__left br {
    display: none;
  }
}
@media only screen and (max-width: 750px) {
  .soudanIntroRow__right {
    margin: 0 -28px 0;
  }
}
.soudanIntro__message {
  margin-top: 60px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 750px) {
  .soudanIntro__message {
    font-size: 18px;
    margin-top: 40px;
  }
}
.soudanIntro__btn {
  margin-top: 50px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .soudanIntro__btn {
    margin-top: 35px;
  }
}

.soudanConcept {
  padding-top: 110px;
  overflow: hidden;
}
@media only screen and (max-width: 750px) {
  .soudanConcept {
    padding: 60px 28px 0;
  }
}
.soudanConcept__text {
  margin-top: 54px;
  line-height: 2;
  font-family: "Zen Old Mincho", serif;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .soudanConcept__text {
    margin-top: 40px;
    font-size: 15px;
    text-align: left;
  }
}
.soudanConceptGallery {
  display: flex;
  margin-top: 56px;
}
.soudanConceptGalleryList {
  display: flex;
}
.soudanConceptGalleryList:first-child {
  animation: loop 180s linear infinite;
}
.soudanConceptGalleryList:nth-child(2) {
  animation: loop2 180s -120s linear infinite;
}
.soudanConceptGalleryList:last-child {
  animation: loop3 180s -60s linear infinite;
}
.soudanConceptGallery__item img {
  max-width: initial;
  width: auto;
  height: 400px;
}
@media only screen and (max-width: 750px) {
  .soudanConceptGallery__item img {
    height: 245px;
  }
}
.soudanConcept__info {
  max-width: 1128px;
  margin: 10px auto 0;
  position: relative;
  padding-left: 450px;
}
@media only screen and (max-width: 750px) {
  .soudanConcept__info {
    padding: 0;
    margin-top: -21px;
  }
}
.soudanConcept__catch {
  font-family: "Zen Old Mincho", serif;
  font-size: 63px;
  letter-spacing: 0.05em;
  line-height: 1.14;
  position: absolute;
  left: 50px;
  top: -48px;
  color: #666666;
}
@media only screen and (max-width: 750px) {
  .soudanConcept__catch {
    position: static;
    font-size: 34px;
  }
}
.soudanConcept__btn {
  height: 60px;
  margin-top: 90px;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .soudanConcept__btn {
    margin-top: 0;
    height: 0;
  }
}

.soudanForm {
  margin-top: 90px;
  background-color: #f7f7f7;
  padding: 80px 0 90px;
}
@media only screen and (max-width: 750px) {
  .soudanForm {
    padding: 40px 15px 40px;
  }
}
.soudanForm__message {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .soudanForm__message {
    font-size: 18px;
    margin-bottom: 24px;
  }
}
.soudanForm__inner {
  max-width: 992px;
  margin: 0 auto 0;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px 65px 65px;
}
@media only screen and (max-width: 750px) {
  .soudanForm__inner {
    padding: 0 0 45px;
  }
}
.soudanForm__title {
  font-size: 31px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .soudanForm__title {
    font-size: 24px;
    background-color: #f7f7f7;
  }
}
.soudanForm__text {
  font-size: 14px;
  letter-spacing: 0.03em;
  padding-bottom: 30px;
}
@media only screen and (max-width: 750px) {
  .soudanForm__text {
    background-color: #f7f7f7;
  }
}
@media only screen and (max-width: 750px) {
  .soudanFormList {
    padding: 15px 15px 0;
    border-radius: 4px 4px 0 0;
  }
}
.soudanFormList__item {
  padding: 20px 0;
  border-bottom: 1px solid #808080;
  display: flex;
}
@media only screen and (max-width: 750px) {
  .soudanFormList__item {
    display: block;
    padding: 0 0 15px 0;
    border-style: none;
  }
}
.soudanFormList__left {
  width: 280px;
  padding-left: 60px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .soudanFormList__left {
    width: auto;
    padding-left: 45px;
    font-size: 14px;
  }
}
.soudanFormList__left .icon-required {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background-color: #a51527;
  width: 44px;
  height: 20px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  top: 4px;
  left: 0px;
  line-height: 1.3;
}
@media only screen and (max-width: 750px) {
  .soudanFormList__left .icon-required {
    width: 40px;
    height: 18px;
    font-size: 13px;
    top: 2px;
    line-height: 1.4;
  }
}
.soudanFormList__left .icon-any {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background-color: #999999;
  width: 44px;
  height: 20px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  top: 4px;
  left: 0px;
  line-height: 1.3;
}
@media only screen and (max-width: 750px) {
  .soudanFormList__left .icon-any {
    width: 40px;
    height: 18px;
    font-size: 13px;
    top: 2px;
    line-height: 1.4;
  }
}
.soudanFormList__right {
  padding-right: 20px;
  flex: 1;
}
@media only screen and (max-width: 750px) {
  .soudanFormList__right {
    padding: 6px 0 0 45px;
  }
}
.soudanFormList__message {
  margin-left: 1em;
  text-indent: -1em;
  font-size: 14px;
  margin-top: 20px;
}
@media only screen and (max-width: 750px) {
  .soudanFormList__message {
    margin-left: calc(1em - 45px);
  }
}
.soudanForm__btn {
  margin-top: 45px;
  text-align: center;
}

.soudanFormPrivacy {
  margin-top: 56px;
}
.soudanFormPrivacy .policyContent {
  margin-top: 0;
}
@media only screen and (max-width: 750px) {
  .soudanFormPrivacy .policyContent {
    margin-top: 0;
  }
}
@media only screen and (max-width: 750px) {
  .soudanFormPrivacy {
    margin-top: 50px;
  }
}
.soudanFormPrivacy__window {
  border: 1px solid #b1b1b1;
  background-color: #f9f9f9;
  padding: 20px;
  height: 250px;
  overflow: auto;
}
@media only screen and (max-width: 750px) {
  .soudanFormPrivacy__window {
    margin: 0 15px;
  }
}
.soudanFormPrivacy__check {
  margin-top: 30px;
  text-align: center;
}

.privacyContent__title {
  font-size: 20px;
  font-weight: 500;
}
.privacyContent p {
  margin: 1em 0 2em;
}
.privacyContent h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 1em;
}
.privacyContent ul {
  margin: 1em 0;
}
.privacyContent ul li {
  text-indent: -1em;
  margin-left: 1em;
}
.privacyContent ul li:before {
  content: "・";
}

.staffHero {
  padding: 140px 120px 0;
}
@media only screen and (max-width: 750px) {
  .staffHero {
    padding: 28px 28px 0px;
  }
}
.staffHero__bg {
  position: relative;
}
.staffHero__title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.staffHero__title .en {
  font-size: 36px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .staffHero__title .en {
    font-size: 30px;
  }
}
.staffHero__title .ja {
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
  margin-top: 15px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .staffHero__title .ja {
    font-size: 14px;
  }
}
.staffHero__title .ja:before {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: calc(50% - 10px);
}
.staffHero__text-01 {
  margin-top: 36px;
  text-align: center;
  font-size: 17px;
  line-height: 2;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .staffHero__text-01 {
    margin-top: 30px;
    font-size: 16px;
    text-align: left;
  }
}
.staffHero__text-02 {
  margin-top: 100px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .staffHero__text-02 {
    margin-top: 50px;
    font-size: 20px;
  }
}
.staffHero__text-03 {
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 750px) {
  .staffHero__text-03 {
    margin-top: 30px;
    font-size: 18px;
  }
}
.staffHero__text-04 {
  margin: 30px auto 0;
  font-size: 16px;
  line-height: 2;
  font-weight: 500;
  max-width: 720px;
}
@media only screen and (max-width: 750px) {
  .staffHero__text-04 {
    font-size: 15px;
    margin-top: 30px;
  }
}

.staffContent {
  padding-top: 150px;
  overflow: hidden;
}
@media only screen and (max-width: 750px) {
  .staffContent {
    margin-top: 70px;
    padding: 0 28px;
  }
}
.staffContent__block {
  margin: 120px auto 0;
  max-width: 1120px;
  display: flex;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 750px) {
  .staffContent__block {
    margin-top: 75px;
    position: relative;
    padding-top: 40px;
    flex-direction: column-reverse;
  }
}
.staffContent__block:first-child {
  margin-top: 0;
}
@media only screen and (min-width: 751px) {
  .staffContent__block:nth-child(2n) {
    flex-direction: row;
  }
  .staffContent__block:nth-child(2n) .staffContent__thumb {
    padding-right: 0;
    padding-left: 30px;
    justify-content: flex-start;
  }
  .staffContent__block:nth-child(2n) .staffContent__info {
    padding-right: 30px;
    padding-left: 60px;
  }
  .staffContent__block:nth-child(2n) .staffContent__line {
    left: auto;
    padding-left: 0px;
    padding-right: 356px;
    right: -175px;
  }
  .staffContent__block:nth-child(2n) .staffContent__line:before {
    left: auto;
    right: 0px;
  }
}
.staffContent__info {
  width: 50%;
  padding: 60px 60px 0 30px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .staffContent__info {
    position: static;
    width: 100%;
    padding: 30px 0 0;
  }
}
.staffContent__line {
  position: absolute;
  left: -160px;
  top: -13px;
  padding-left: 285px;
  font-size: 15px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .staffContent__line {
    top: 0px;
    left: 0px;
    display: flex;
    font-size: 14px;
    align-items: center;
    padding: 0;
  }
}
.staffContent__line:before {
  content: "";
  width: 268px;
  height: 1px;
  background-color: #212529;
  position: absolute;
  top: 50%;
  left: 0px;
}
@media only screen and (max-width: 750px) {
  .staffContent__line:before {
    position: static;
    flex: 1;
    margin-right: 16px;
  }
}
.staffContent__thumb {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  position: relative;
  padding-right: 30px;
}
@media only screen and (max-width: 750px) {
  .staffContent__thumb {
    padding: 0;
    width: 100%;
    display: block;
  }
}
.staffContent__thumb img {
  max-width: initial;
}
@media only screen and (max-width: 750px) {
  .staffContent__thumb img {
    max-width: 100%;
  }
}
.staffContent__message {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.02em;
}
@media only screen and (max-width: 750px) {
  .staffContent__message {
    font-size: 16px;
  }
}
.staffContent__text {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
}
@media only screen and (max-width: 750px) {
  .staffContent__text {
    font-size: 15px;
  }
}
.staffContent__btn {
  margin-top: 30px;
}
@media only screen and (max-width: 750px) {
  .staffContent__btn {
    text-align: center;
  }
}
.staffContent__name {
  position: absolute;
  background-color: #fff;
  padding: 40px 15px 20px;
  display: flex;
  flex-direction: row-reverse;
  font-family: "Zen Old Mincho", serif;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .staffContent__name {
    padding: 12px 0;
    width: 76px;
    justify-content: center;
  }
}
.staffContent__name .job {
  writing-mode: vertical-rl;
  font-size: 18px;
  letter-spacing: 0.03em;
}
@media only screen and (max-width: 750px) {
  .staffContent__name .job {
    font-size: 15px;
  }
}
.staffContent__name .name {
  writing-mode: vertical-rl;
  font-size: 30px;
  letter-spacing: 0.03em;
}
@media only screen and (max-width: 750px) {
  .staffContent__name .name {
    font-size: 25px;
    line-height: 1.2;
  }
}
.-block01 .staffContent__name {
  top: 0px;
  right: 62px;
}
@media only screen and (max-width: 750px) {
  .-block01 .staffContent__name {
    right: auto;
    left: 15px;
  }
}
.-block02 .staffContent__name {
  top: 0px;
  left: 80px;
}
@media only screen and (max-width: 750px) {
  .-block02 .staffContent__name {
    left: auto;
    right: 15px;
  }
}
.-block03 .staffContent__name {
  top: 0px;
  right: 462px;
}
@media only screen and (max-width: 750px) {
  .-block03 .staffContent__name {
    left: 15px;
    right: auto;
  }
}

.staffOffice {
  margin-top: 136px;
  height: 886px;
  background: url(../img/staff/bg_wave.png) no-repeat center bottom/cover;
  padding-top: 90px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .staffOffice {
    background: url(../img/staff/bg_wave_sp.svg) no-repeat center bottom/cover;
    margin-top: 70px;
    height: auto;
    padding: 50px 28px 60px;
  }
}
.staffOffice:before {
  content: "";
  width: 230px;
  height: 230px;
  background: url(../img/staff/accent_01.svg) no-repeat center/contain;
  position: absolute;
  top: -70px;
  right: calc(50% - 530px);
}
@media only screen and (max-width: 750px) {
  .staffOffice:before {
    width: 184px;
    height: 184px;
    top: -48px;
    right: 14px;
  }
}
.staffOffice:after {
  content: "";
  width: 226px;
  height: 226px;
  background: url(../img/staff/accent_02.svg) no-repeat center/contain;
  position: absolute;
  bottom: -44px;
  left: calc(50% - 574px);
}
@media only screen and (max-width: 750px) {
  .staffOffice:after {
    width: 180px;
    height: 180px;
    bottom: -40px;
    left: 14px;
    z-index: 1;
  }
}
.staffOffice__title {
  font-size: 24px;
  text-align: center;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .staffOffice__title {
    font-size: 20px;
  }
}
.staffOfficeList {
  display: flex;
  justify-content: center;
  margin-top: 75px;
}
@media only screen and (max-width: 750px) {
  .staffOfficeList {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
  }
}
.staffOfficeList__item {
  margin: 0 34px;
  width: 280px;
}
@media only screen and (max-width: 750px) {
  .staffOfficeList__item {
    margin: 60px 0 0;
  }
  .staffOfficeList__item:first-child {
    margin-top: 0;
  }
}
.staffOfficeList__thumb {
  position: relative;
}
.staffOfficeList__info {
  background-color: #fff;
  width: 64px;
  height: 152px;
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  font-family: "Zen Old Mincho", serif;
  position: absolute;
  top: 0px;
  left: 16px;
}
@media only screen and (max-width: 750px) {
  .staffOfficeList__info {
    height: 140px;
    padding-top: 16px;
  }
}
.staffOfficeList__job {
  writing-mode: vertical-rl;
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .staffOfficeList__job {
    font-size: 15px;
  }
}
.staffOfficeList__name {
  writing-mode: vertical-rl;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}
@media only screen and (max-width: 750px) {
  .staffOfficeList__name {
    font-size: 25px;
  }
}
.staffOfficeList__text {
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
}

.voiceArchive {
  padding-top: 30px;
  padding-bottom: 36px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .voiceArchive {
    padding: 40px 15px;
  }
}
.voiceArchive__inner {
  max-width: 1020px;
  margin: 0 auto;
}
.voiceArchiveList {
  margin: 0 -23px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 750px) {
  .voiceArchiveList {
    margin: 0;
    justify-content: space-between;
  }
}
.voiceArchiveList__item {
  width: calc(33.33% - 46px);
  margin: 0 23px 40px;
}
@media only screen and (max-width: 750px) {
  .voiceArchiveList__item {
    width: 48.5%;
    margin: 0 0 25px;
  }
}
.voiceArchiveList__thumb {
  border-radius: 20px 0 20px 0;
  overflow: hidden;
  width: 100%;
  height: 208px;
}
@media only screen and (max-width: 750px) {
  .voiceArchiveList__thumb {
    height: 33.8666666667vw;
    border-radius: 12px 0 12px 0;
  }
}
.voiceArchiveList__thumb a {
  display: block;
  width: 100%;
  height: 100%;
}
.voiceArchiveList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.voiceArchiveList__name {
  font-size: 13px;
  letter-spacing: 0.1em;
  margin-top: 12px;
}
@media only screen and (max-width: 750px) {
  .voiceArchiveList__name {
    margin-top: 10px;
  }
}
.voiceArchiveList__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 12px;
}
@media only screen and (max-width: 750px) {
  .voiceArchiveList__title {
    margin-top: 10px;
  }
}

.voiceSingleHeader {
  margin-top: 90px;
  background-color: #f5f5f5;
  text-align: center;
}
.voiceSingleHeader .breadcrumbs {
  margin-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleHeader .breadcrumbs {
    margin-bottom: 9px;
  }
}
@media only screen and (max-width: 750px) {
  .voiceSingleHeader {
    padding: 0px 28px 0;
    margin-top: 0;
  }
}
.voiceSingleHeader__subtitle {
  display: inline-block;
  padding-bottom: 18px;
  width: 214px;
  background: url(../img/voice/balloon.svg) no-repeat center bottom/contain;
  font-size: 24px;
  letter-spacing: 0.03em;
  font-family: "Zen Old Mincho", serif;
}
.voiceSingleHeader__name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 25px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleHeader__name {
    font-size: 14px;
    color: #666666;
    margin-top: 20px;
  }
}
.voiceSingleHeader__title {
  font-size: 23px;
  font-weight: bold;
  margin-top: 10px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleHeader__title {
    font-size: 18px;
    margin-top: 16px;
  }
}
.voiceSingleHeader__thumb {
  display: inline-block;
  overflow: hidden;
  width: 46.8vw;
  border-radius: 40px 0 40px 0;
  margin-top: 32px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleHeader__thumb {
    width: auto;
    border-radius: 30px 0 30px 0;
  }
}

.voiceSingleContent {
  padding-top: 96px;
  padding-bottom: 120px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContent {
    padding: 50px 28px 70px;
  }
}
.voiceSingleContent__title {
  text-align: center;
  font-size: 26px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.03em;
  margin-bottom: 60px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContent__title {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
.voiceSingleContentBlock {
  width: 100%;
  max-width: 1028px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock {
    display: block;
  }
}
.voiceSingleContentBlock + .voiceSingleContentBlock {
  margin-top: 90px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock + .voiceSingleContentBlock {
    margin-top: 48px;
  }
}
@media only screen and (min-width: 751px) {
  .voiceSingleContentBlock:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .voiceSingleContentBlock:nth-child(2n) .voiceSingleContentBlock__left {
    padding-right: 0;
    padding-left: 48px;
  }
}
.voiceSingleContentBlock__left {
  flex: 1;
  padding-right: 48px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock__left {
    padding: 0;
  }
}
.voiceSingleContentBlock__right {
  width: 510px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock__right {
    width: 100%;
    margin-top: 40px;
  }
}
.voiceSingleContentBlock__title {
  font-size: 23px;
  font-weight: 600;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock__title {
    font-size: 18px;
  }
}
.voiceSingleContentBlock__q {
  font-size: 19px;
  font-weight: 600;
  margin-top: 20px;
  font-family: "Zen Old Mincho", serif;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock__q {
    font-size: 16px;
  }
}
.voiceSingleContentBlock__a {
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  margin-top: 22px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentBlock__a {
    font-size: 14px;
  }
}
.voiceSingleContentGallery {
  margin-top: 140px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentGallery {
    margin-top: 15px;
    justify-content: space-between;
  }
}
.voiceSingleContentGallery__item {
  width: 350px;
  margin: 0 20px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentGallery__item {
    width: 48.5%;
    margin: 0;
  }
}
.voiceSingleContentWorks {
  margin-top: 150px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentWorks {
    margin-top: 60px;
  }
}
.voiceSingleContentWorks a {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  text-decoration: none;
  color: inherit;
}
.voiceSingleContentWorks__left {
  width: 50%;
  background-color: #8e8a8f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #fff;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentWorks__left {
    width: 45%;
  }
}
.voiceSingleContentWorks__left:after {
  content: "";
  margin-top: 24px;
  width: 11px;
  height: 10px;
  background: url(../img/arrow_white.svg) no-repeat center/contain;
  display: block;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentWorks__left:after {
    margin-top: 16px;
  }
}
.voiceSingleContentWorks__large {
  font-size: 36px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentWorks__large {
    font-size: 18px;
  }
}
.voiceSingleContentWorks__medium {
  font-size: 16px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.03em;
  margin-top: 24px;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentWorks__medium {
    font-size: 13px;
    margin-top: 10px;
  }
}
.voiceSingleContentWorks__right {
  width: 50%;
  height: 264px;
}
.voiceSingleContentWorks__right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 750px) {
  .voiceSingleContentWorks__right {
    width: 55%;
    height: 162px;
  }
}

.voiceRecent {
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .voiceRecent {
    padding: 0 15px 0;
  }
}
.voiceRecent__inner {
  max-width: 1040px;
  margin: 0 auto;
}
.voiceRecent .voiceArchiveList {
  margin-top: 30px;
}

.worksArchive {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 750px) {
  .worksArchive {
    padding: 40px 15px;
  }
}
.worksArchive__inner {
  max-width: 1040px;
  margin: 0 auto;
}
.worksArchiveList {
  margin: 0 -14px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 750px) {
  .worksArchiveList {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
  }
}
.worksArchiveList__item {
  width: calc(25% - 28px);
  margin: 0 14px 35px;
}
@media only screen and (max-width: 750px) {
  .worksArchiveList__item {
    width: 48.5%;
    margin: 30px 0 0;
  }
}
.worksArchiveList__info {
  position: relative;
  margin-top: 10px;
}
@media only screen and (max-width: 750px) {
  .worksArchiveList__info {
    margin-top: 0;
    padding-top: 4px;
  }
}
.worksArchiveList .new {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: #fff;
  display: block;
  width: 60px;
  padding: 2px 0 3px 0.06em;
  text-align: center;
  background-color: #e83a37;
  border-radius: 50vh;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}
@media only screen and (max-width: 750px) {
  .worksArchiveList .new {
    top: 5px;
    right: 0px;
  }
}
.worksArchiveList__thumb {
  width: 100%;
  height: 156px;
}
@media only screen and (max-width: 750px) {
  .worksArchiveList__thumb {
    height: 33.8666666667vw;
  }
}
.worksArchiveList__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.worksArchiveList__area {
  padding-right: 80px;
  font-size: 13px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .worksArchiveList__area {
    padding-right: 0;
  }
}
.worksArchiveList__title {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 10px;
}

.worksHeader {
  margin-top: 90px;
}
@media only screen and (max-width: 750px) {
  .worksHeader {
    margin-top: 0px;
  }
}
.worksHeader .breadcrumbs {
  margin-bottom: 95px;
}
@media only screen and (max-width: 750px) {
  .worksHeader .breadcrumbs {
    margin-bottom: 19px;
  }
}
.worksHeader__catch {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .worksHeader__catch {
    font-size: 18px;
    padding: 0 10px;
  }
}
.worksHeader__title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 20px;
}
@media only screen and (max-width: 750px) {
  .worksHeader__title {
    font-size: 14px;
    padding: 0 10px;
  }
}

.worksSingleSlider {
  max-width: 910px;
  margin: 45px auto 0;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .worksSingleSlider {
    margin-top: 30px;
    padding: 0 10px;
  }
}
.worksSingleSlider__main figure {
  display: flex;
  width: 100%;
  height: 607px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .worksSingleSlider__main figure {
    height: 62.4vw;
  }
}
.worksSingleSlider__main figure img {
  max-width: initial;
  width: auto;
  height: 100%;
}
.worksSingleSlider__main .prev {
  position: absolute;
  top: calc(50% - 33px);
  left: -50px;
  z-index: 10;
  background: url(../img/works/arrow_prev.svg) no-repeat center/contain;
  width: 33px;
  height: 66px;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .worksSingleSlider__main .prev {
    background: url(../img/works/arrow_prev_sp.svg) no-repeat center/contain;
    left: 8px;
    top: calc(50% - 10px);
    height: 20px;
    width: 10px;
  }
}
.worksSingleSlider__main .next {
  position: absolute;
  top: calc(50% - 33px);
  right: -50px;
  z-index: 10;
  background: url(../img/works/arrow_next.svg) no-repeat center/contain;
  width: 33px;
  height: 66px;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .worksSingleSlider__main .next {
    background: url(../img/works/arrow_next_sp.svg) no-repeat center/contain;
    right: 8px;
    top: calc(50% - 10px);
    height: 20px;
    width: 10px;
  }
}
.worksSingleSlider__sub {
  margin: 25px -4px 0;
}
@media only screen and (max-width: 750px) {
  .worksSingleSlider__sub {
    margin-top: 15px;
  }
}
.worksSingleSlider__sub figure {
  display: flex;
  margin: 0 4px;
  height: 95px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .worksSingleSlider__sub figure {
    height: 17.0666666667vw;
  }
}
.worksSingleSlider__sub figure img {
  max-width: initial;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.worksSingle__read {
  max-width: 740px;
  margin: 60px auto 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (max-width: 750px) {
  .worksSingle__read {
    margin: 45px 28px 0;
    font-size: 16px;
  }
}
.worksSingle__content {
  max-width: 740px;
  margin: 60px auto 0;
}
@media only screen and (max-width: 750px) {
  .worksSingle__content {
    margin: 60px 28px 0;
  }
}
.worksSingleData {
  max-width: 830px;
  margin: 90px auto 0;
}
@media only screen and (max-width: 750px) {
  .worksSingleData {
    margin: 45px 28px 0;
  }
}
.worksSingleData__inner {
  max-width: 430px;
  padding: 36px 40px 32px;
  border: 1px solid #b3b3b3;
}
.worksSingleData__title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
  padding-bottom: 14px;
  border-bottom: 1px solid #212529;
}
.worksSingleData__info {
  margin-top: 10px;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.05em;
}
.worksSingleVoice {
  margin-top: 110px;
}
@media only screen and (max-width: 750px) {
  .worksSingleVoice {
    margin: 50px 28px 0;
  }
}
.worksSingleVoice a {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  text-decoration: none;
  color: inherit;
}
.worksSingleVoice__left {
  width: 50%;
  background-color: #97a4b6;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #fff;
}
@media only screen and (max-width: 750px) {
  .worksSingleVoice__left {
    width: 45%;
  }
}
.worksSingleVoice__left:after {
  content: "";
  margin-top: 24px;
  width: 11px;
  height: 10px;
  background: url(../img/arrow_white.svg) no-repeat center/contain;
  display: block;
}
@media only screen and (max-width: 750px) {
  .worksSingleVoice__left:after {
    margin-top: 16px;
  }
}
.worksSingleVoice__large {
  font-size: 36px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 750px) {
  .worksSingleVoice__large {
    font-size: 18px;
  }
}
.worksSingleVoice__medium {
  font-size: 16px;
  font-family: "Zen Old Mincho", serif;
  letter-spacing: 0.03em;
  margin-top: 24px;
}
@media only screen and (max-width: 750px) {
  .worksSingleVoice__medium {
    font-size: 13px;
    margin-top: 10px;
  }
}
.worksSingleVoice__right {
  width: 50%;
  height: 264px;
}
.worksSingleVoice__right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 750px) {
  .worksSingleVoice__right {
    width: 55%;
    height: 162px;
  }
}
.worksSingle__back {
  text-align: center;
  margin-top: 100px;
}
.worksSingle__back a {
  display: inline-block;
  width: 320px;
  font-size: 18px;
  font-weight: 500;
  background-color: #97a4b6;
  color: #fff;
  padding: 13px 0;
  text-decoration: none;
}

.worksRecent {
  margin-top: 95px;
  padding: 60px 0 65px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 750px) {
  .worksRecent {
    padding-top: 50px;
    padding: 50px 15px;
  }
}
.worksRecent__inner {
  max-width: 1040px;
  margin: 0 auto;
}
.worksRecent .worksArchiveList {
  margin-top: 30px;
}

.pageHeader {
  margin-top: 90px;
}
@media only screen and (max-width: 750px) {
  .pageHeader {
    margin-top: 0px;
  }
}
.pageHeader .breadcrumbs {
  margin-bottom: 95px;
}
@media only screen and (max-width: 750px) {
  .pageHeader .breadcrumbs {
    margin-bottom: 19px;
  }
}

/* ----------------------------------------------------------------------
 animation
---------------------------------------------------------------------- */
.ani-fade-up {
  opacity: 0;
  transform: translate(0, 60px);
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.ani-start-scroll.is-active .ani-fade-up, .ani-start-scroll.is-active.ani-fade-up {
  transform: translate(0, 0);
  opacity: 1;
}

.ani-delay-1 {
  transition-delay: 0.2s;
  animation-delay: 0.2s;
}

.ani-delay-2 {
  transition-delay: 0.4s;
  animation-delay: 0.4s;
}

.ani-delay-3 {
  transition-delay: 0.6s;
  animation-delay: 0.6s;
}

.ani-delay-4 {
  transition-delay: 0.8s;
  animation-delay: 0.8s;
}

.ani-delay-5 {
  transition-delay: 1s;
  animation-delay: 1s;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translate(0, -60px);
  }
  80% {
    opacity: 1;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes loop {
  0% {
    transform: translateX(200%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-200%);
  }
}
@keyframes loop3 {
  0% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-300%);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1); /* 拡大率 */
  }
}