@use 'lib/mixin';
@use 'lib/setting';
.soudanHero {
  @include mixin.break2 {
    padding: 0 28px;
  }
  &__bg {
    height: 48.9vw;
    width: 100%;
    max-height: 90vh;
    position: relative;
    @include mixin.break2 {
      height: auto;
      max-height: initial;
      margin: 0 -28px;
      width: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: setting.$serif;
    padding-top: 90px;
    @include mixin.break2 {
      padding-top: 0;
    }
    .en {
      display: block;
      font-size: 26px;
      letter-spacing: 0.1em;
    }
    .ja {
      display: block;
      font-size: 45px;
      font-weight: 500;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 29px;
      }
    }
  }
  &__subtitle {
    font-size: 27px;
    font-family: setting.$serif;
    margin-top: 36px;
    text-align: center;
    @include mixin.break2 {
      font-size: 18px;
    }
  }
  &__read {
    font-size: 20px;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-top: 70px;
    text-align: center;
    @include mixin.break2 {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  &__medium {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    @include mixin.break2 {
      font-size: 18px;
      margin-top: 20px;
    }
    .large {
      font-size: 30px;
      @include mixin.break2 {
        font-size: 27px;
      }
    }
  }
  &__text {
    margin-top: 50px;
    font-family: setting.$serif;
    text-align: center;
    @include mixin.break2 {
      font-size: 15px;
      margin-top: 25px;
      text-align: left;
    }
  }
}
.soudanIntro {
  margin-top: 60px;
  padding: 50px 0 75px;
  background-color: #f7f7f7;
  @include mixin.break2 {
    margin-top: 40px;
    padding: 28px 28px 50px;
  }
  &__inner {
    max-width: 1032px;
    margin: 0 auto;
  }
  &List {
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      flex-wrap: wrap;
    }
    &__item {
      width: 210px;
      height: 60px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 1px solid setting.$color;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.1em;
      margin: 0 4px;
      @include mixin.break2 {
        width: 144px;
        height: 40px;
        margin: 0 5px 10px;
        font-size: 16px;
      }
    }
  }
  &Row {
    margin-top: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mixin.break2 {
      flex-direction: column-reverse;
      margin-top: 35px;
    }
    &__left {
      line-height: 2;
      font-family: setting.$serif;
      @include mixin.break2 {
        font-size: 15px;
        margin-top: 40px;
        br {
          display: none;
        }
      }
    }
    &__right {
      @include mixin.break2 {
        margin: 0 -28px 0;
      }
    }
  }
  &__message {
    margin-top: 60px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    @include mixin.break2 {
      font-size: 18px;
      margin-top: 40px;
    }
  }
  &__btn {
    margin-top: 50px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 35px;
    }
  }
}
.soudanConcept {
  padding-top: 110px;
  overflow: hidden;
  @include mixin.break2 {
    padding: 60px 28px 0;
  }
  &__text {
    margin-top: 54px;
    line-height: 2;
    font-family: setting.$serif;
    text-align: center;
    @include mixin.break2 {
      margin-top: 40px;
      font-size: 15px;
      text-align: left;
    }
  }
  &Gallery {
    display: flex;
    margin-top: 56px;
    &List {
      display: flex;
      &:first-child {
        animation: loop 180s linear infinite;
      }
      &:nth-child(2) {
        animation: loop2 180s -120s linear infinite;
      }
      &:last-child {
        animation: loop3 180s -60s linear infinite;
      }
    }
    &__item {
      img {
        max-width: initial;
        width: auto;
        height: 400px;
        @include mixin.break2 {
          height: 245px;
        }
      }
    }
  }
  &__info {
    max-width: 1128px;
    margin: 10px auto 0;
    position: relative;
    padding-left: 450px;
    @include mixin.break2 {
      padding: 0;
      margin-top: -21px;
    }
  }
  &__catch {
    font-family: setting.$serif;
    font-size: 63px;
    letter-spacing: 0.05em;
    line-height: 1.14;
    position: absolute;
    left: 50px;
    top: -48px;
    color: #666666;
    @include mixin.break2 {
      position: static;
      font-size: 34px;
    }
  }
  &__btn {
    height: 60px;
    margin-top: 90px;
    text-align: center;
    @include mixin.break2 {
      // margin-top: 54px;
      margin-top: 0;
      height: 0;
    }
  }
}
.soudanForm {
  margin-top: 90px;
  background-color: #f7f7f7;
  padding: 80px 0 90px;
  @include mixin.break2 {
    padding: 40px 15px 40px;
  }
  &__message {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    @include mixin.break2 {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
  &__inner {
    max-width: 992px;
    margin: 0 auto 0;
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 65px 65px;
    @include mixin.break2 {
      padding: 0 0 45px;
    }
  }
  &__title {
    font-size: 31px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 40px;
    @include mixin.break2 {
      font-size: 24px;
      background-color: #f7f7f7;
    }
  }
  &__text {
    font-size: 14px;
    letter-spacing: 0.03em;
    padding-bottom: 30px;
    @include mixin.break2 {
      background-color: #f7f7f7;
    }
  }
  &List {
    @include mixin.break2 {
      padding: 15px 15px 0;
      border-radius: 4px 4px 0 0;
    }
    &__item {
      padding: 20px 0;
      border-bottom: 1px solid #808080;
      display: flex;
      @include mixin.break2 {
        display: block;
        padding: 0 0 15px 0;
        border-style: none;
      }
    }
    &__left {
      width: 280px;
      padding-left: 60px;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      @include mixin.break2 {
        width: auto;
        padding-left: 45px;
        font-size: 14px;
      }
      .icon-required {
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        background-color: #a51527;
        width: 44px;
        height: 20px;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        top: 4px;
        left: 0px;
        line-height: 1.3;
        @include mixin.break2 {
          width: 40px;
          height: 18px;
          font-size: 13px;
          top: 2px;
          line-height: 1.4;
        }
      }
      .icon-any {
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        background-color: #999999;
        width: 44px;
        height: 20px;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        top: 4px;
        left: 0px;
        line-height: 1.3;
        @include mixin.break2 {
          width: 40px;
          height: 18px;
          font-size: 13px;
          top: 2px;
          line-height: 1.4;
        }
      }
    }
    &__right {
      padding-right: 20px;
      flex: 1;
      @include mixin.break2 {
        padding: 6px 0 0 45px;
      }
    }
    &__message {
      margin-left: 1em;
      text-indent: -1em;
      font-size: 14px;
      margin-top: 20px;
      @include mixin.break2 {
        margin-left: calc(1em - 45px);
      }
    }
  }
  &__btn {
    margin-top: 45px;
    text-align: center;
  }
}
.soudanFormPrivacy {
  .policyContent {
    margin-top: 0;
    @include mixin.break2 {
      margin-top: 0;
    }
  }
  margin-top: 56px;
  @include mixin.break2 {
    margin-top: 50px;
  }
  &__window {
    border: 1px solid #b1b1b1;
    background-color: #f9f9f9;
    padding: 20px;
    height: 250px;
    overflow: auto;
    @include mixin.break2 {
      margin: 0 15px;
    }
  }
  &__check {
    margin-top: 30px;
    text-align: center;
  }
}
.privacyContent {
  &__title {
    font-size: 20px;
    font-weight: 500;
  }
  p {
    margin: 1em 0 2em;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  ul {
    margin: 1em 0;
    li {
      text-indent: -1em;
      margin-left: 1em;
      &:before {
        content: "・";
      }
    }
  }
}