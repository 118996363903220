@use 'lib/mixin';
@use 'lib/setting';
.breadcrumbs {
  padding: 12px 30px;
  font-size: 14px;
  text-align: left;
  @include mixin.break2 {
    padding: 8px 15px;
  }
  a {
    color: inherit;
  }
}