@use 'lib/mixin';
@use 'lib/setting';
.policyContent {
  max-width: 900px;
  margin: 70px auto 0;
  @include mixin.break2 {
    margin-top: 40px;
    padding: 0 20px;
  }
  &__title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin: 90px 0 20px;
    @include mixin.break2 {
      margin-top: 45px;
      font-size: 18px;
    }
  }
  &__text {
    font-size: 16px;
    letter-spacing: 0.1em;
    @include mixin.break2 {
      font-size: 14px;
    }
  }
}