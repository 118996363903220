@use 'lib/mixin';
@use 'lib/setting';
.worksArchive {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 70px;
  @include mixin.break2 {
    padding: 40px 15px;
  }
  &__inner {
    max-width: 1040px;
    margin: 0 auto;
  }
  &List {
    margin: 0 -14px;
    display: flex;
    flex-wrap: wrap;
    @include mixin.break2 {
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
    }
    &__item {
      width: calc(25% - 28px);
      margin: 0 14px 35px;
      @include mixin.break2 {
        width: 48.5%;
        margin: 30px 0 0;
      }
    }
    &__info {
      position: relative;
      margin-top: 10px;
      @include mixin.break2 {
        margin-top: 0;
        padding-top: 4px;
      }
    }
    .new {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 13px;
      letter-spacing: 0.12em;
      color: #fff;
      display: block;
      width: 60px;
      padding: 2px 0 3px 0.06em;
      text-align: center;
      background-color: #e83a37;
      border-radius: 50vh;
      line-height: 1;
      font-family: setting.$monster;
      @include mixin.break2 {
        top: 5px;
        right: 0px;
      }
    }
    &__thumb {
      width: 100%;
      height: 156px;
      @include mixin.break2 {
        height: mixin.svw(127);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__area {
      padding-right: 80px;
      font-size: 13px;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        padding-right: 0;
      }
    }
    &__title {
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-top: 10px;
    }
  }
}

.worksHeader {
  margin-top: 90px;
  @include mixin.break2 {
    margin-top: 0px;
  }
  .breadcrumbs {
    margin-bottom: 95px;
    @include mixin.break2 {
      margin-bottom: 19px;
    }
  }
  &__catch {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-align: center;
    @include mixin.break2 {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 20px;
    @include mixin.break2 {
      font-size: 14px;
      padding: 0 10px;
    }
  }
}
.worksSingle {
  &Slider {
    max-width: 910px;
    margin: 45px auto 0;
    position: relative;
    @include mixin.break2 {
      margin-top: 30px;
      padding: 0 10px;
    }
    &__main {
      figure {
        display: flex;
        width: 100%;
        height: 607px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        @include mixin.break2 {
          height: mixin.svw(234);
        }
        img {
          max-width: initial;
          width: auto;
          height: 100%;
        }
      }
      .prev {
        position: absolute;
        top: calc(50% - 33px);
        left: -50px;
        z-index: 10;
        background: url(../img/works/arrow_prev.svg) no-repeat center/contain;
        width: 33px;
        height: 66px;
        cursor: pointer;
        @include mixin.break2 {
          background: url(../img/works/arrow_prev_sp.svg) no-repeat center/contain;
          left: 8px;
          top: calc(50% - 10px);
          height: 20px;
          width: 10px;
        }
      }
      .next {
        position: absolute;
        top: calc(50% - 33px);
        right: -50px;
        z-index: 10;
        background: url(../img/works/arrow_next.svg) no-repeat center/contain;
        width: 33px;
        height: 66px;
        cursor: pointer;
        @include mixin.break2 {
          background: url(../img/works/arrow_next_sp.svg) no-repeat center/contain;
          right: 8px;
          top: calc(50% - 10px);
          height: 20px;
          width: 10px;
        }
      }
    }
    &__sub {
      margin: 25px -4px 0;
      @include mixin.break2 {
        margin-top: 15px;
      }
      figure {
        display: flex;
        margin: 0 4px;
        height: 95px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        @include mixin.break2 {
          height: mixin.svw(64);
        }
        img {
          max-width: initial;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  &__read {
    max-width: 740px;
    margin: 60px auto 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    @include mixin.break2 {
      margin: 45px 28px 0;
      font-size: 16px;
    }
  }
  &__content {
    max-width: 740px;
    margin: 60px auto 0;
    @include mixin.break2 {
      margin: 60px 28px 0;
    }
  }
  &Data {
    max-width: 830px;
    margin: 90px auto 0;
    @include mixin.break2 {
      margin: 45px 28px 0;
    }
    &__inner {
      max-width: 430px;
      padding: 36px 40px 32px;
      border: 1px solid #b3b3b3;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.1em;
      padding-bottom: 14px;
      border-bottom: 1px solid setting.$color;
    }
    &__info {
      margin-top: 10px;
      font-size: 16px;
      line-height: 2;
      letter-spacing: 0.05em;
    }
  }
  &Voice {
    margin-top: 110px;
    @include mixin.break2 {
      margin: 50px 28px 0;
    }
    a {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 740px;
      margin: 0 auto;
      text-decoration: none;
      color: inherit;
    }
    &__left {
      width: 50%;
      background-color: #97a4b6;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      color: #fff;
      @include mixin.break2 {
        width: 45%;
      }
      &:after {
        content: "";
        margin-top: 24px;
        width: 11px;
        height: 10px;
        background: url(../img/arrow_white.svg) no-repeat center/contain;
        display: block;
        @include mixin.break2 {
          margin-top: 16px;
        }
      }
    }
    &__large {
      font-size: 36px;
      font-family: setting.$serif;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 18px;
      }
    }
    &__medium {
      font-size: 16px;
      font-family: setting.$serif;
      letter-spacing: 0.03em;
      margin-top: 24px;
      @include mixin.break2 {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    &__right {
      width: 50%;
      height: 264px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include mixin.break2 {
        width: 55%;
        height: 162px;
      }
    }
  }
  &__back {
    text-align: center;
    margin-top: 100px;
    a {
      display: inline-block;
      width: 320px;
      font-size: 18px;
      font-weight: 500;
      background-color: #97a4b6;
      color: #fff;
      padding: 13px 0;
      text-decoration: none;
    }
  }
}
.worksRecent {
  margin-top: 95px;
  padding: 60px 0 65px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding-top: 50px;
    padding: 50px 15px;
  }
  &__inner {
    max-width: 1040px;
    margin: 0 auto;
  }
  .worksArchiveList {
    margin-top: 30px;
  }
}