@use 'lib/mixin';
@use 'lib/setting';
.flowHero {
  padding: 140px 120px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @include mixin.break2 {
    padding: 28px 28px 60px;
    height: auto;
    margin-bottom: 40px;
  }
  &__bg {
    position: relative;
    border-radius: 0 33px 0 33px;
    overflow: hidden;
    @include mixin.break2 {
      border-radius: 0px;
    }
  }
  &__title {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mixin.break2 {
      color: #fff;
    }
    .en {
      font-size: 36px;
      font-family: setting.$serif;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 30px;
      }
    }
    .ja {
      font-size: 16px;
      font-weight: 500;
      padding-top: 20px;
      margin-top: 15px;
      position: relative;
      @include mixin.break2 {
        font-size: 14px;
      }
      &:before {
        content: "";
        width: 20px;
        height: 1px;
        background-color: setting.$color;
        position: absolute;
        top: 0px;
        left: calc(50% - 10px);
        @include mixin.break2 {
          background-color: #fff;
        }
      }
    }
  }
  &__read {
    font-size: 17px;
    line-height: 2;
    font-family: setting.$serif;
    text-align: center;
    margin-top: 40px;
    @include mixin.break2 {
      display: block;
      text-align: left;
      font-size: 15px;
    }
  }
}
.flowContent {
  padding: 80px 0;
  @include mixin.break2 {
    padding: 40px 20px 50px;
  }
  &__inner {
    max-width: 1120px;
    margin: 0 auto;
  }
  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: setting.$serif;
    position: relative;
    left: -27px;
    @include mixin.break2 {
      left: -22px;
    }
    .text {
      font-size: 16px;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 13px;
      }
    }
    .num {
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.05em;
      height: 96px;
      width: 44px;
      background: url(../img/flow/shape_title.svg) no-repeat left center/contain;
      padding: 19px 0 0 16px;
      margin-left: 4px;
      @include mixin.break2 {
        font-size: 32px;
        width: 34px;
        height: 78px;
        padding: 14px 0 0 12px;
      }
    }
  }
  &__h2 {
    margin-top: 40px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: setting.$serif;
    @include mixin.break2 {
      margin-top: 32px;
      font-size: 18px;
    }
  }
  &__read {
    margin-top: 15px;
    text-align: center;
    @include mixin.break2 {
      text-align: left;
    }
  }
}
.flowContent1 {
  background-color: #f5f5f5;
  &List {
    margin: 50px -25px -65px;
    display: flex;
    flex-wrap: wrap;
    @include mixin.break2 {
      display: block;
      margin: 0;
    }
    &__item {
      width: calc(25% - 50px);
      margin: 0 25px 65px;
      @include mixin.break2 {
        width: auto;
        padding: 36px calc((100% - 242px) / 2) 0;
        border-top: 1px solid setting.$color;
        margin: 36px 0 0;
        &:first-child {
          border-top: none;
          margin-top: 0;
        }
      }
    }
    &__thumb {
      height: 157px;
      @include mixin.break2 {
        height: auto;
      }
      .img05 {
        max-width: initial;
        width: 256px;
        position: relative;
        left: -7px;
        top: -7px;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1em;
      margin-top: 10px;
    }
    &__text {
      font-size: 14px;
      margin-top: 10px;
      height: 150px;
      @include mixin.break2 {
        margin-bottom: 30px;
        height: auto;
      }
    }
    &__btn {
      + .flowContent1List__btn {
        margin-top: 6px;
      }
    }
  }
}
.flowContent2 {
  &Row {
    display: flex;
    margin-top: 60px;
    @include mixin.break2 {
      margin-top: 20px;
      display: block;
    }
    &__left {
      position: relative;
      width: 553px;
      @include mixin.break2 {
        width: auto;
      }
      .accent {
        position: absolute;
        top: 265px;
        right: -127px;
        @include mixin.break2 {
          bottom: -54px;
          left: 22px;
          top: auto;
          right: auto;
        }
      }
    }
    &__right {
      font-family: setting.$serif;
      width: 420px;
      margin-left: 55px;
      padding-top: 50px;
      line-height: 2;
      @include mixin.break2 {
        width: auto;
        margin-left: 0;
        padding-top: 60px;
        font-size: 15px;
      }
    }
  }
}
.flowContent3 {
  background-color: #f5f5f5;
  &Row {
    display: flex;
    margin-top: 65px;
    @include mixin.break2 {
      margin-top: 30px;
      flex-direction: column-reverse;
    }
    &__left {
      width: 580px;
      font-family: setting.$serif;
      @include mixin.break2 {
        width: auto;
        margin-top: 20px;
      }
    }
    &__title {
      font-size: 18px;
      font-weight: 500;
      padding: 0 0 8px 8px;
      border-bottom: 1px solid setting.$color;
    }
    &__subtitle {
      font-size: 20px;
      font-weight: 500;
      margin-top: 25px;
      @include mixin.break2 {
        font-size: 18px;
      }
    }
    &__text {
      padding-right: 30px;
      margin-top: 20px;
      line-height: 1.875;
      @include mixin.break2 {
        padding-right: 0;
        font-size: 15px;
      }
    }
  }
}
.flowContent4 {
  &Row {
    margin-top: 80px;
    display: flex;
    @include mixin.break2 {
      margin-top: 30px;
      display: block;
    }
    &__right {
      margin-left: 55px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mixin.break2 {
        margin-left: 0;
        margin-top: 30px;
      }
    }
    &__text {
      font-size: 16px;
      line-height: 1.68;
      font-family: setting.$serif;
      width: 420px;
      @include mixin.break2 {
        font-size: 15px;
        width: auto;
      }
    }
    &__gallery {
      display: flex;
      @include mixin.break2 {
        margin-top: 30px;
        justify-content: space-between;
      }
      .item {
        @include mixin.break2_up {
          + .item {
            margin-left: 13px;
          }
        }
        @include mixin.break2 {
          width: 49%;
        }
      }
    }
  }
  &List {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px;
    &__item {
      width: calc(25% - 28px);
      border: 1px solid setting.$color;
      height: 330px;
      margin: 65px 14px 0;
      padding: 20px 16px;
      font-family: setting.$serif;
      position: relative;
      @include mixin.break2 {
        width: 258px;
        margin: 54px auto 0;
      }
      &:after {
        content: "";
        width: 13px;
        height: 48px;
        background: url(../img/flow/arrow.svg) no-repeat center/contain;
        position: absolute;
        right: -23px;
        top: calc(50% - 24px);
        @include mixin.break2 {
          transform: rotate(90deg);
          top: -48px;
          right: calc(50% - 6px);
        }
      }
      @include mixin.break2_up {
        &:nth-child(4n) {
          &:after {
            display: none;
          }
        }
      }
      @include mixin.break2 {
        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }
    &__num {
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
    }
    &__title {
      font-size: 18px;
      font-weight: 500;
      margin-top: 6px;
      height: 66px;
      text-align: center;
    }
    &__text {
      
    }
  }
}
.flowContent5 {
  background-color: #f5f5f5;
  &List {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    @include mixin.break2 {
      margin-top: 30px;
      display: block;
    }
    &__item {
      width: 540px;
      background-color: #fff;
      box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
      display: flex;
      @include mixin.break2 {
        width: 100%;
        + .flowContent5List__item {
          margin-top: 25px;
        }
      }
    }
    &__left {
      flex: 1;
      padding: 50px 34px 0 24px;
      @include mixin.break2 {
        padding: 24px 12px 0;
      }
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      @include mixin.break2 {
        font-size: 16px;
      }
    }
    &__text {
      font-size: 16px;
      margin-top: 16px;
      @include mixin.break2 {
        font-size: 13px;
      }
    }
    &__right {
      width: 146px;
    }
  }
  &Guarantee {
    width: 100%;
    max-width: 720px;
    margin: 56px auto 0;
    padding: 30px 0;
    text-align: center;
    border-top: 1px solid setting.$color;
    border-bottom: 1px solid setting.$color;
    @include mixin.break2 {
      padding: 25px 15px;
    }
    &__title {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      width: 390px;
      height: 44px;
      background-color: #666666;
      border-radius: 10px;
      padding-top: 9px;
      @include mixin.break2 {
        width: 100%;
      }
    }
    &__list {
      margin-top: 18px;
      display: flex;
      justify-content: center;
      font-weight: 500;
      @include mixin.break2 {
        display: inline-block;
      }
      li {
        font-size: 18px;
        margin: 0 0.5em;
        @include mixin.break2 {
          text-align: left;
        }
      }
    }
  }
}