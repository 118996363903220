@use 'lib/font';
@use 'lib/setting';
/* ----------------------------------------------------------------------
 basic setting
---------------------------------------------------------------------- */
body {
  font-family: setting.$font;
	color: setting.$color;
	line-height: 1.5;
	/*
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	*/
}
body * {
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
}
input,button,textarea,select {
	color: setting.$color;
	font-family: setting.$font;
	font-size: 16px;
}
textarea {
	vertical-align: top;
}
*:focus {
	outline: none;
}
img {
  max-width: 100%;
  height: auto;
}
a:hover {
	backface-visibility: hidden;
}