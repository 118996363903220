@use 'lib/mixin';
@use 'lib/setting';
.home {
  .header__inner {
    @include mixin.break2_up {
      display: none;
    }
  }
}
.homeHero {
  display: flex;
  &__h1 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 10px;
    left: 0px;
    font-size: 13px;
    @include mixin.break2 {
      display: none;
    }
  }
  &__left {
    width: 16.67%;
    padding: 50px 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @include mixin.break2 {
      display: none;
    }
    .menu {
      margin-top: 37.4vh;
      position: relative;
      height: 16px;
      width: 46px;
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
      span {
        display: block;
        position: absolute;
        background-color: #808080;
        height: 1px;
        width: 100%;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          bottom: 0px;
        }
      }
    }
  }
  &__sns {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
  }
  &__right {
    width: 83.33%;
    height: 94vh;
    position: relative;
    opacity: 0;
    @include mixin.break2 {
      height: calc(100vh - 54px);
      width: 100%;
      padding-left: 15px;
    }
    &.is-active {
      opacity: 1;
      transition: opacity 1s;
    }
  }
  &__bg {
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 50px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .item {
      height: 94vh;
    }
    .add-animation {
      img {
        animation: zoomOut 10s linear 0s normal both;
      }
    }
  }
  &__navi {
    position: absolute;
    display: flex;
    top: 50px;
    right: 90px;
    @include mixin.break2 {
      display: none;
    }
    li {
      margin-left: 24px;
      a {
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__catch {
    color: #fff;
    position: absolute;
    bottom: 60px;
    right: 210px;
    font-family: setting.$serif;
    @include mixin.break2 {
      bottom: 36px;
      right: 25px;
    }
    .large {
      font-size: 32px;
      letter-spacing: 0.1em;
      display: block;
    }
    .small {
      font-size: 15px;
      display: block;
      line-height: 2;
      letter-spacing: 0.1em;
    }
  }
}
.homeConcept {
  margin-top: 96px;
  position: relative;
  @include mixin.break2 {
    margin-top: 60px;
    padding: 0 28px;
  }
  &__title {
    position: absolute;
    left: 46px;
    top: 90px;
    font-size: 28px;
    letter-spacing: 0.01em;
    transform: rotate(90deg);
    transform-origin: center left;
    font-family: setting.$serif;
    @include mixin.break2 {
      left: 60px;
      top: 0px;
    }
  }
  &__inner {
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    padding-left: 88px;
    padding-top: 120px;
    @include mixin.break2 {
      padding: 0;
    }
  }
  &__subtitle {
    font-size: 23px;
    font-family: setting.$serif;
    line-height: 2;
    letter-spacing: 0.1em;
    padding-left: 112px;
    position: relative;
    @include mixin.break2 {
      margin-top: 55px;
      padding-left: 60px;
      font-size: 20px;
    }
    &:before {
      content: '';
      width: 88px;
      height: 1px;
      background-color: setting.$color;
      position: absolute;
      top: 24px;
      left: 0px;
      @include mixin.break2 {
        width: 40px;
        top: 19px;
      }
    }
  }
  &__text {
    margin-top: 350px;
    margin-left: 506px;
    max-width: 450px;
    font-size: 15px;
    font-family: setting.$serif;
    letter-spacing: 0.1em;
    line-height: 2;
    position: relative;
    @include mixin.break2 {
      margin-left: 0;
      margin-top: 40px;
      line-height: 1.5;
      letter-spacing: 0.03em;
    }
    &:before {
      content: "";
      width: 226px;
      height: 226px;
      background: url(../img/home/deco_concept_02.svg) no-repeat center/contain;
      position: absolute;
      z-index: -1;
      bottom: -100px;
      right: -18px;
      @include mixin.break2 {
        bottom: -27px;
        right: -13px;
        width: 180px;
        height: 180px;
      }
    }
  }
  &__img-01 {
    position: absolute;
    top: 0px;
    right: 0px;
    @include mixin.break2 {
      position: relative;
      width: 72%;
      margin-left: auto;
      &:before {
        content: "";
        width: 146px;
        height: 146px;
        background: url(../img/home/deco_concept_01.svg) no-repeat center/contain;
        position: absolute;
        z-index: -1;
        bottom: -34px;
        left: -100px;
      }
    }
  }
  &__img-02 {
    position: absolute;
    top: 340px;
    right: 583px;
    @include mixin.break2 {
      position: static;
      margin-top: 60px;
    }
    &:before {
      content: "";
      width: 230px;
      height: 230px;
      background: url(../img/home/deco_concept_01.svg) no-repeat center/contain;
      position: absolute;
      z-index: -1;
      top: -58px;
      right: -154px;
      @include mixin.break2 {
        display: none;
      }
    }
  }
  &__btn {
    margin-top: 160px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 50px;
    }
  }
}
.homePolicy {
  margin-top: 200px;
  @include mixin.break2 {
    padding: 40px 0 60px;
    background-color: #f5f5f5;
    margin-top: 50px;
  }
  &__content {
    margin-top: 20px;
    padding-top: 30px;
    padding-bottom: 64px;
    background-color: #f5f5f5;
    @include mixin.break2 {
      margin-top: 40px;
      padding: 0;
    }
  }
  &__text {
    text-align: center;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.1em;
    font-family: setting.$serif;
    @include mixin.break2 {
      padding: 0 35px;
      text-align: left;
      font-size: 15px;
      letter-spacing: 0.03em;
    }
  }
  &List {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      display: block;
    }
    &__item {
      width: 240px;
      margin: 0 15px;
      @include mixin.break2 {
        margin: 50px auto 0;
      }
    }
    &__num {
      font-size: 30px;
      font-family: setting.$serif;
      font-weight: 500;
      text-align: center;
    }
    &__title {
      font-size: 20px;
      font-family: setting.$serif;
      font-weight: 500;
      text-align: center;
    }
    &__thumb {
      position: relative;
      margin-top: 15px;
      text-align: center;
      figure {
        border-radius: 50%;
        overflow: hidden;
      }
      .accent {
        position: absolute;
        &.accent-01 {
          top: 56px;
          left: -36px;
        }
        &.accent-02 {
          bottom: -28px;
          left: -38px;
        }
        &.accent-03 {
          top: 12px;
          left: -8px;
        }
        &.accent-04 {
          top: 163px;
          left: -30px;
        }
      }
    }
  }
  &__btn {
    margin-top: 72px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 64px;
    }
  }
}
.homeNews {
  padding: 120px 0 0px;
  @include mixin.break2 {
    padding: 50px 30px 0px;
  }
  &List {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @include mixin.break2 {
      display: block;
      margin-top: 0;
    }
    &__item {
      width: 318px;
      margin: 0 22px;
      @include mixin.break2 {
        width: 100%;
        max-width: 318px;
        margin: 36px auto 0;
      }
    }
    &__thumb {
      position: relative;
      width: 100%;
      height: 212px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__info {
      margin-top: 10px;
      position: relative;
    }
    &__tags {
      display: flex;
      li {
        font-size: 13px;
        padding: 0 10px;
        color: #fff;
        letter-spacing: 0.12em;
        background-color: setting.$color;
        &.-gray {
          background-color: #808080;
        }
        + li {
          margin-left: 10px;
        }
      }
    }
    .new {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 13px;
      letter-spacing: 0.12em;
      color: #fff;
      display: block;
      width: 60px;
      padding: 2px 0 3px 0.06em;
      text-align: center;
      background-color: #e83a37;
      border-radius: 50vh;
      line-height: 1;
      font-family: setting.$monster;
      @include mixin.break2 {
        right: 0px;
      }
    }
    &__date {
      margin-top: 12px;
      font-size: 13px;
      letter-spacing: 0.1em;
    }
    &__title {
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-top: 15px;
    }
  }
  &__btn {
    margin-top: 60px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 40px;
    }
  }
}

.homeWorks {
  margin-top: 85px;
  padding: 90px 0 65px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding: 50px 15px 60px;
    margin-top: 60px;
  }
  &List {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0;
      padding-top: 10px;
    }
    &__thumb {
      width: 100%;
      height: 156px;
      @include mixin.break2 {
        height: mixin.svw(127);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__item {
      width: 240px;
      margin: 0 14px;
      @include mixin.break2 {
        width: 48.5%;
        margin: 30px 0 0;
      }
    }
    &__info {
      position: relative;
      margin-top: 10px;
      @include mixin.break2 {
        margin-top: 0;
        padding-top: 30px;
      }
    }
    .new {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 13px;
      letter-spacing: 0.12em;
      color: #fff;
      display: block;
      width: 60px;
      padding: 2px 0 3px 0.06em;
      text-align: center;
      background-color: #e83a37;
      border-radius: 50vh;
      line-height: 1;
      font-family: setting.$monster;
      @include mixin.break2 {
        top: 5px;
        right: 0px;
      }
    }
    &__area {
      padding-right: 80px;
      font-size: 13px;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        padding-right: 0;
      }
    }
    &__title {
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-top: 10px;
    }
  }
  &__btn {
    margin-top: 60px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 50px;
    }
  }
}

.homeHouse {
  padding: 100px 0 0px;
  overflow: hidden;
  @include mixin.break2 {
    padding: 50px 0 0px;
  }
  &Gallery {
    display: flex;
    margin-top: 24px;
    @include mixin.break2 {
      margin-top: 40px;
    }
    &List {
      display: flex;
      &:first-child {
        animation: loop 180s linear infinite;
      }
      &:nth-child(2) {
        animation: loop2 180s -120s linear infinite;
      }
      &:last-child {
        animation: loop3 180s -60s linear infinite;
      }
    }
    &__item {
      img {
        max-width: initial;
        width: auto;
        height: 400px;
        @include mixin.break2 {
          height: 245px;
        }
      }
    }
  }
  &__info {
    max-width: 1128px;
    margin: 10px auto 0;
    position: relative;
    padding-left: 450px;
    @include mixin.break2 {
      padding: 0 30px;
      margin-top: -26px;
    }
  }
  &__catch {
    font-family: setting.$serif;
    font-size: 63px;
    letter-spacing: 0.05em;
    line-height: 1.14;
    position: absolute;
    left: 50px;
    top: -48px;
    color: #666666;
    @include mixin.break2 {
      position: static;
      font-size: 44px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.8;
    @include mixin.break2 {
      margin-top: 20px;
    }
  }
  &__btn {
    margin-top: 60px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 45px;
    }
  }
}

.homeVoice {
  margin-top: 80px;
  padding: 90px 0 54px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    margin-top: 60px;
    padding: 50px 15px 60px;
  }
  &List {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @include mixin.break2 {
      margin-top: 0;
      padding-top: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item {
      width: 318px;
      margin: 0 22px;
      @include mixin.break2_up {
        &:nth-child(4) {
          display: none;
        }
      }
      @include mixin.break2 {
        width: 48.5%;
        margin: 20px 0 0;
      }
    }
    &__thumb {
      overflow: hidden;
      border-radius: 20px 0 20px 0;
      width: 100%;
      height: 212px;
      @include mixin.break2 {
        height: mixin.svw(127);
        border-radius: 12px 0 12px 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__area {
      margin-top: 10px;
      font-size: 13px;
      letter-spacing: 0.1em;
    }
    &__title {
      font-size: 14px;
      margin-top: 15px;
      letter-spacing: 0.1em;
    }
  }
  &__btn {
    margin-top: 50px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 45px;
    }
  }
}

.homeBlog {
  padding: 90px 0 80px;
  @include mixin.break2 {
    padding: 45px 25px 50px;
  }
  &List {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      display: block;
      margin-top: 0;
    }
    &__item {
      width: 328px;
      margin: 0 6px;
      @include mixin.break2 {
        width: 100%;
        max-width: 328px;
        margin: 20px auto 0;
      }
      a {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        text-decoration: none;
        color: inherit;
        background-color: #f5f5f5;
        width: 100%;
        height: 100%;
        @media (hover: hover) {
          transition: opacity .3s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    &__thumb {
      width: 107px;
    }
    &__info {
      width: calc(100% - 132px);
    }
    &__cat {
      display: inline-block;
      padding: 2px 14px;
      font-size: 13px;
      background-color: #fff;
      margin-bottom: 12px;
    }
    &__date {
      .date {
        font-size: 12px;
        letter-spacing: 0.12em;
        font-family: setting.$monster;
      }
      .cat {
        font-size: 13px;
        letter-spacing: 0.1em;
        margin-left: 1em;
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
      @include mixin.break2 {
        font-size: 13px;
      }
    }
    &__excerpt {
      font-size: 13px;
      @include mixin.break2 {
        font-size: 12px;
      }
    }
  }
  &__btn {
    margin-top: 70px;
    text-align: center;
    @include mixin.break2 {
      margin-top: 45px;
    }
  }
}
.homeAbout {
  padding: 115px 0 115px;
  background: url(../img/home/deco_about.svg) no-repeat -60px -60px/384px auto;
  background-color: #f5f5f5;
  position: relative;
  @include mixin.break2 {
    padding: 50px 0;
    background: url(../img/home/deco_about.svg) no-repeat -88px 20px/280px auto;
    background-color: #f5f5f5;
  }
  &__inner {
    width: 100%;
    max-width: 1010px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mixin.break2 {
      flex-direction: column;
      justify-content: center;
    }
  }
  &__title {
    position: absolute;
    left: 46px;
    top: 226px;
    font-size: 28px;
    letter-spacing: 0.01em;
    transform: rotate(90deg);
    transform-origin: center left;
    font-family: setting.$serif;
    @include mixin.break2 {
      left: calc(50% - 70px);
      top: 50px;
    }
  }
  &__subtitle {
    writing-mode: vertical-rl;
    font-size: 36px;
    letter-spacing: 0.1em;
    font-family: setting.$serif;
    @include mixin.break2 {
      font-size: 30px;
      order: 1;
    }
  }
  &Text {
    width: 186px;
    margin-left: 40px;
    @include mixin.break2 {
      width: 100%;
      margin: 0;
      padding: 30px 0 0 28px;
      order: 3;
    }
    .item {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.1em;
      margin: 4px 0;
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
      &.is-current {
        color: #999999;
      }
    }
  }
  &Gallery {
    position: relative;
    width: 663px;
    @include mixin.break2 {
      order: 2;
      width: 100%;
      padding-left: 28px;
      margin-top: 25px;
    }
    .slick-dots {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 2px;
      left: 0px;
      width: 100%;
      @include mixin.break2 {
        width: auto;
        left: auto;
        right: 20px;
        bottom: -30px;
      }
      li {
        margin: 0 5px;
        button {
          -webkit-appearance: none;
          width: 8px;
          height: 8px;
          padding: 0;
          border-style: none;
          background-color: #fff;
          text-indent: -9999px;
          pointer-events: none;
          border-radius: 50%;
          @include mixin.break2 {
            width: 42px;
            height: 8px;
            border-radius: 50vh;
          }
        }
        &.slick-active {
          button {
            background-color: #cccccc;
          }
        }
      }
    }
  }
}

.homeInstagram {
  margin-top: 110px;
  @include mixin.break2 {
    margin: 75px 15px 0;
  }
  &__inner {
    max-width: 1010px;
    margin: 0 auto;
    padding: 20px 0 100px;
    position: relative;
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
    @include mixin.break2 {
      padding: 0 0 40px;
      margin: 0;
      border-top-style: none;

    }
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: .1em;
    img {
      margin-right: 12px;
    }
    @include mixin.break2 {
      text-align: center;
    }
  }
  &__link {
    position: absolute;
    right: 8px;
    top: 20px;
    @include mixin.break2 {
      display: none;
    }
    &-sp {
      @include mixin.break2_up {
        display: none;
      }
      margin-top: 36px;
      text-align: center;
      a {
        display: inline-block;
        padding: 10px 22px;
        border: 1px solid setting.$color;
        color: inherit;
        text-align: center;
        font-size: 13px;
        text-decoration: none;
        position: relative;
        &:after {
          content: "";
          background: url(../img/arrow_black.svg) no-repeat center/contain;
          width: 11px;
          height: 10px;
          position: absolute;
          right: 7px;
          bottom: 14px;
          @include mixin.break2 {
            display: none;
          }
        }
      }
    }
  }
  &List {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    @include mixin.break2 {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
    }
    .item {
      width: 160px;
      @include mixin.break2 {
        margin: 5px 5px;
      }
      img {
        width: 160px;
        height: 160px;
        object-fit: cover;
      }
    }
  }
}
.homeContact {
  padding-top: 120px;
  @include mixin.break2 {
    padding-top: 45px;
  }
  &__text {
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 2;
    margin-bottom: 40px;
    @include mixin.break2 {
      margin-bottom: 28px;
    }
  }
  &Tel {
    text-align: center;
    height: 96px;
    background: url(../img/line_tel.svg) no-repeat center/contain;
    margin-top: 40px;
    padding-top: 8px;
    @include mixin.break2 {
      width: 320px;
      height: 98px;
      background: url(../img/line_tel_sp.svg) no-repeat center/contain;
      margin: 28px auto 0;
      padding-top: 12px;
    }
    &__text {
      font-size: 18px;
      font-family: setting.$serif;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 14px;
      }
    }
    &__tel {
      font-size: 36px;
      letter-spacing: 0.1em;
      @include mixin.break2 {
        font-size: 29px;
      }
      a {

      }
    }
  }
  &Banners {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    @include mixin.break2 {
      margin-top: 0;
      display: block;
    }
    &__item {
      width: 460px;
      margin: 0 10px;
      border: 1px solid #212529;
      @include mixin.break2 {
        margin: 30px auto 0;
        width: 320px;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 128px;
        text-decoration: none;
        color: inherit;
        font-family: setting.$serif;
      }
      @include mixin.break2_up {
        &.-right {
          .homeContactBanners__right {
            margin-left: 27px;
          }
        }
      }
    }
    &__thumb {
      width: 166px;
      @include mixin.break2 {
        width: 106px;
      }
    }
    &__right {
      margin-left: 16px;
    }
    &__text {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
    &__title {
      font-size: 24px;
      letter-spacing: .05em;
      margin-top: 8px;
      font-weight: 500;
      position: relative;
      left: -0.4em;
      @include mixin.break2 {
        font-size: 18px;
      }
    }
    .img02 {
      img {
        max-width: initial;
        width: 190px;
        position: relative;
        left: -10px;
        top: 1px;
        @include mixin.break2 {
          width: 146px;
          left: -34px;
        }
      }
    }
  }
}