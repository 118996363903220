
@use 'lib/mixin';
@use 'lib/setting';
.form-text {
  width: 300px;
  height: 28px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  padding: 0 0.25em;
  &::placeholder {
    color: #999999;
  }
  &.-zip {
    width: 160px;
  }
  @include mixin.break2 {
    width: 100%;
  }
}
.form-select {
  width: 250px;
  height: 28px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  padding: 0 0.25em;
  + .form-select {
    margin-left: 2px;
  }
  &.-fit {
    width: auto;
  }
}
.form-textarea {
  width: 100%;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  padding: 0.25em;
}
.form-btn-send {
  -webkit-appearance: none;
  border-style: none;
  width: 300px;
  height: 67px;
  background-color: #666666;
  border-radius: 3px;
  color: #fff;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  &:after {
    content: "";
    background: url(../img/angle_right_white.svg) no-repeat center/contain;
    width: 5px;
    height: 10px;
    position: absolute;
    right: 30px;
    top: calc(50% - 5px);
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
}