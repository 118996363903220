@use 'lib/mixin';
@use 'lib/setting';
.newsArchive {
  padding-top: 25px;
  padding-bottom: 80px;
  background-color: #f5f5f5;
  @include mixin.break2 {
    padding: 36px 15px 40px;
  }
  &__inner {
    max-width: 1020px;
    margin: 0 auto;
  }
  &List {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -22px 0;
    @include mixin.break2 {
      justify-content: space-between;
      margin: 0;
    }
    &__item {
      width: calc(33.33% - 44px);
      margin: 0 22px 45px;
      @include mixin.break2 {
        width: 48.5%;
        margin: 0 0 20px;
        position: relative;
      }
    }
    &__thumb {
      position: relative;
      width: 100%;
      height: 212px;
      @include mixin.break2 {
        height: mixin.svw(127);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__end {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      span {
        width: 154px;
        height: 58px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        font-size: 14px;
        line-height: 1.3;
        padding-top: 10px;
      }
    }
    &__info {
      margin-top: 10px;
      position: relative;
      @include mixin.break2 {
        position: static;
      }
    }
    &__tags {
      display: flex;
      @include mixin.break2 {
        flex-direction: column;
        align-items: flex-start;
      }
      li {
        font-size: 13px;
        padding: 0 10px;
        color: #fff;
        letter-spacing: 0.12em;
        background-color: setting.$color;
        &.-gray {
          background-color: #808080;
        }
        + li {
          margin-left: 10px;
          @include mixin.break2 {
            margin: 8px 0 0;
          }
        }
      }
    }
    .new {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 13px;
      letter-spacing: 0.12em;
      color: #fff;
      display: block;
      width: 60px;
      padding: 2px 0 3px 0.06em;
      text-align: center;
      background-color: #e83a37;
      border-radius: 50vh;
      line-height: 1;
      font-family: setting.$monster;
      @include mixin.break2 {
        right: 4px;
        top: 4px;
      }
    }
    &__date {
      margin-top: 12px;
      font-size: 13px;
      letter-spacing: 0.1em;
    }
    &__title {
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-top: 15px;
    }
  }
}

.newsSingle {
  &__tags {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    li {
      min-width: 150px;
      text-align: center;
      font-size: 16px;
      letter-spacing: 0.12em;
      padding: 3px 0.2em;
      background-color: #000000;
      color: #fff;
      &.-gray {
        background-color: #808080;
      }
    }
  }
  &__eyecatch {
    max-width: 910px;
    margin: 36px auto 0;
    @include mixin.break2 {
      margin: 50px 28px 0;
    }
  }
  &__inner {
    max-width: 810px;
    margin: 36px auto 0;
    @include mixin.break2 {
      margin: 45px 20px 0;
    }
  }
  &__title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: .03em;
    text-align: center;
    @include mixin.break2 {
      font-size: 16px;
    }
  }
  &__time {
    text-align: center;
    font-size: 18px;
    line-height: 2;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-top: 30px;
    @include mixin.break2 {
      font-size: 16px;
      margin-top: 40px;
    }
  }
  &__caution {
    max-width: 650px;
    margin: 50px auto 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1em;
    padding: 24px 0 28px;
    background-color: #f5f5f5;
    @include mixin.break2 {
      margin-top: 46px;
      font-size: 15px;
    }
  }
  &Content {
    margin-top: 64px;
    font-weight: 500;
    line-height: 2;
    @include mixin.break2 {
      margin-top: 40px;
    }
  }
  &Gallery {
    margin-top: 65px;
    @include mixin.break2 {
      margin-top: 40px;
    }
    &__block {
      margin-top: 50px;
      @include mixin.break2 {
        margin-top: 40px;
      }
      &:first-child {
        margin-top: 0;
        @include mixin.break2 {
          margin-top: 0;
        }
      }
    }
    &__thumb {
      display: flex;
      margin: 0 -40px;
      @include mixin.break2 {
        margin: 0 -5px;
      }
      .item {
        flex: 1;
        margin: 0 40px;
        text-align: center;
        @include mixin.break2 {
          margin: 0 5px;
        }
      }
    }
    &__text {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.05em;
      margin-top: 40px;
      @include mixin.break2 {
        font-size: 14px;
        margin-top: 24px;
      }
    }
  }
  &Map {
    margin-top: 90px;
    &__title {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.02em;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 30px;
      @include mixin.break2 {
        font-size: 16px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 1px;
        bottom: 0px;
        left: calc(50% - 10px);
        background-color: #404040;
      }
    }
    &__map {
      @include mixin.break2 {
        margin: 0 -20px;
      }
      iframe  {
        width: 100%;
        height: 434px;
        @include mixin.break2 {
          height: 325px;
        }
      }
    }
  }
  &Contact {
    padding-top: 90px;
    &__message {
      padding-top: 27px;
    }
    &Tel {
      min-height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(../img/news/line_tel.svg) no-repeat center/contain;
      @include mixin.break2 {
        width: 320px;
        margin: 0 auto;
        background-size: 100% 100%;
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.1em;
        @include mixin.break2 {
          font-size: 14px;
        }
      }
      &__tel {
        font-size: 36px;
        font-family: setting.$serif;
        letter-spacing: 0.1em;
        @include mixin.break2 {
          font-size: 29px;
        }
      }
      &__time {
        font-size: 14px;
        letter-spacing: 0.1em;
      }
    }
    &__message {
      margin: 27px auto 0;
      max-width: 350px;
      padding: 12px 0 12px;
      font-size: 14px;
      letter-spacing: 0.1em;
      text-align: center;
      background-color: #f5f5f5;
    }
    &__title {
      margin-top: 120px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.02em;
      @include mixin.break2 {
        margin-top: 100px;
      }
      &:before {
        content: "";
        flex: 1;
        height: 1px;
        background-color: setting.$color;
        margin-right: 20px;
      }
      &:after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: setting.$color;
        margin-left: 20px;
      }
    }
    &__inner {
      max-width: 640px;
      margin: 50px auto 0;
    }
    &Block {
      + .newsSingleContactBlock {
        margin-top: 20px;
      }
      &__title {
        font-size: 14px;
        letter-spacing: 0.1em;
        padding-left: 1em;
        font-weight: 500;
        margin-bottom: 6px;
      }
      &__child {
        font-size: 15px;
        letter-spacing: 0.1em;
      }
    }
    &__btn {
      text-align: center;
      margin-top: 35px;
    }
  }
}
.nform-icon-required {
  font-size: 13px;
  letter-spacing: 0.12em;
  background-color: #e83a37;
  color: #fff;
  line-height: 1.69em;
  padding: 0 0.2em 0 0.32em;
  position: relative;
  left: 6px;
}
.nform-text {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #b3b3b3;
  height: 34px;
  padding: 0 0.25em;
  &.-zip {
    width: 100px;
  }
}
.nform-select {
  background-color: #fafafa;
  border: 1px solid #b3b3b3;
  height: 34px;
  padding: 0 0.25em;
  width: 140px;
  &.-full {
    width: 100%;
  }
}
.nform-textarea {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #b3b3b3;
  padding: 0.25em;
}
.nform-btn-send {
  -webkit-appearance: none;
  width: 320px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12em;
  background-color: #404040;
  cursor: pointer;
  @media (hover: hover) {
    transition: opacity 0.3s ease-in-out;
    &:hover { 
      opacity: 0.8;
    }
  }
  
}