// Font Setting
$font: "Zen Kaku Gothic New", sans-serif;
$serif: "Zen Old Mincho", serif;
$monster: "Montserrat", sans-serif;

// Color Setting
$color: #212529;
$blue: #161f3e;
$color-red: #c1272d;

// Size Setting
$wrapper: 1200px;
$breakPoint1: 1366px;
$breakPoint2: 750px;
$pcDesignSize: 1920;
$spDesignSize: 375;

// Weight Setting
$thin: 100;
$eLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$dBold: 600;
$bold: 700;
$heavy: 800;
$black: 900;