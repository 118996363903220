@use 'lib/mixin';
@use 'lib/setting';
/* ----------------------------------------------------------------------
 common class
---------------------------------------------------------------------- */
@include mixin.break2_up {
  .pc-non {
    display: none !important;
  }
}
@include mixin.break2 {
  .sp-non {
    display: none !important;
  }
}

.mb0 { margin-bottom: 0; }

.tCenter { text-align: center; }

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.telLink {
  color: inherit;
  text-decoration: none;
  @include mixin.break2_up {
    pointer-events: none;
  }
}
.wrapper {
  width: setting.$wrapper;
  margin: 0 auto;
  position: relative;
  @include mixin.break2 {
    width: auto;
  }
}
.hoverImg {
  transition: .3s;
  @include mixin.break2_up {
    &:hover {
      opacity: 0.7;
    }
  }
}
.hoverTxt {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.hoverScale {
  display: block;
  width: 100%;
  height: 100%;
  figure {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    vertical-align: bottom;
  }
  @media (hover: hover) {
    img {
      transition: .5s;
      &:hover {
        transform: scale(1.08);
      }
    }
  }
}